import { EMPLOYEE_LOGIN } from '../../constants';

const isEmpty = require('lodash.isempty');

const initialState = {
  isAuthenticated: false,
  employee: {},
  loading: false,
  error: {},
  role: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_LOGIN.SUCCESS:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        role: action.payload.role,
        employee: action.payload,
        loading: false,
        error: {}
      };
    case EMPLOYEE_LOGIN.FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case EMPLOYEE_LOGIN.LOAD:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
