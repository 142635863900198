import {
  Box,
  Breadcrumbs,
  Fade,
  FormControl,
  Grid,
  Input,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import React from 'react';
import DropDown from '../../components/DropDown';
import Widget from '../../components/Widget/Widget';
import { bankList } from '../../const';

export default function Expense1(props) {
  const {
    classes,
    error,
    debitInBank,
    handleChangeBank,
    handleBankAmountInput,
    setError,
    handleOnDeleteBank,
    handleAddBank,
    bankTotalAmount,
    bankCaseTotalAmount,
    bankListData,
    setDebitInCash,
    debitInCash,
    history,
    handleSubmit,
    addExpenseLoading,
    handleBankListData,
    employeeData,
    cash,
    selectedDate,
    setSelectedDate,
    reason,
    setReason,
  } = props;
  return (
    <div>
      {' '}
      <Grid item lg={12} sm={12} xs={12}>
        <Widget
          className={classes.card}
          bodyClass={classes.alignStandaloneElementEmployeeDashboard}
          disableWidgetMenu
          style={{ height: 'calc(100vh - 365px)' }}
        >
          <div
            style={{
              height: 'calc(100vh - 430px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <center>
              <Fade in={error} style={!error ? { display: 'none' } : { display: 'inline-block' }}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error ? error : ''}
                </Typography>
              </Fade>
            </center>
            {bankListData.length > 0 ? (
              <>
                {debitInBank.map((bank, index) => (
                  <Grid item md={12} xs={12} key={index}>
                    <div className={classes.tableWrapper}>
                      <Grid container spacing={3}>
                        <Grid item lg={3} sm={3} xs={12}>
                          <FormControl className={classes.sellComponent}>
                            <DropDown
                              title="Select Bank"
                              ItemsArray={handleBankListData(index)}
                              handleChange={(e) => handleChangeBank(e, index)}
                              selectedItem={bank.bankId}
                              paddingTop={'10px'}
                              paddingBottom={'10px'}
                              paddingLeft={'10px'}
                              fullWidth
                              isDisable={index < debitInBank.length - 1}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={3} sm={3} xs={12}>
                          <FormControl className={classes.sellComponent}>
                            <Input
                              id={`amount${index}`}
                              margin="normal"
                              placeholder={'Amount'}
                              value={bank.amount}
                              type={'number'}
                              fullWidth
                              onChange={(e) => handleBankAmountInput(e, index)}
                              onFocus={() => setError('')}
                              disabled={index < debitInBank.length - 1}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                          <div className={classes.bankBalance}>
                            {bank.money >= 0 && employeeData.type !== 'user' ? (
                              <div className={classes.bankMoney}>
                                <Typography
                                  variant="p"
                                  // style={{ paddingLeft: '5px' }}
                                >
                                  Curr.Amt = {bank.money}
                                </Typography>
                              </div>
                            ) : null}
                            <FormControl className={classes.deleteSellComponent}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleOnDeleteBank(index)}
                                className={classes.button}
                              >
                                Cancel
                              </Button>
                            </FormControl>
                          </div>
                        </Grid>
                        <div className={classes.categoryDivider} />
                      </Grid>
                    </div>
                  </Grid>
                ))}
              </>
            ) : (
              <p className={classes.emptyTable}>{bankList}</p>
            )}
            {bankListData.length !== debitInBank.length && (
              <Grid item md={12} xs={12}>
                <div className={classes.tableWrapper}>
                  <Grid container spacing={3}>
                    <Grid item lg={3} sm={3} xs={12}>
                      <Grid item md={12} xs={12}>
                        <Box display="flex" alignItems="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddBank()}
                            className={classes.button}
                            style={{ width: '100%' }}
                          >
                            Add Bank
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </div>
          <Grid item md={12} xs={12}>
            <div className={classes.tableWrapper}>
              <Grid container spacing={3}>
                <Grid item lg={4} sm={4} xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    // justifyContent='left'
                    className={classes.sell1BottomTotal}
                  >
                    <Breadcrumbs aria-label="breadcrumb">
                      <Typography variant="h6" style={{ paddingLeft: '5px' }}>
                        Bank Total : {bankTotalAmount}
                      </Typography>
                    </Breadcrumbs>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Widget>
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        <Widget
          className={classes.card}
          bodyClass={classes.alignStandaloneElementEmployeeDashboard}
          disableWidgetMenu
          style={{ marginTop: 10 }}
        >
          <div>
            <Grid item md={12} xs={12}>
              <div className={classes.tableWrapper}>
                <Grid container spacing={3}>
                  <Grid item lg={4} sm={4} xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="left" className={classes.sell1BottomTotal}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h6" style={{ paddingLeft: '5px' }}>
                          Reason
                        </Typography>
                      </Breadcrumbs>
                    </Box>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <FormControl className={classes.sellComponent}>
                      <Input
                        id="reason"
                        margin="normal"
                        placeholder={'Reason'}
                        value={reason}
                        type={'text'}
                        fullWidth
                        onChange={(e) => setReason(e.target.value)}
                        onFocus={() => setError('')}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item lg={4} sm={4} xs={12}></Grid> */}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={4} sm={4} xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="left" className={classes.sell1BottomTotal}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h6" style={{ paddingLeft: '5px' }}>
                          Cash
                        </Typography>
                      </Breadcrumbs>
                    </Box>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <FormControl className={classes.sellComponent}>
                      <Input
                        id="cash"
                        margin="normal"
                        placeholder={'Amount'}
                        value={debitInCash}
                        type={'number'}
                        fullWidth
                        onChange={(e) => setDebitInCash(e.target.value)}
                        onFocus={() => setError('')}
                      />
                    </FormControl>
                  </Grid>
                  {cash >= 0 && employeeData.type !== 'user' ? (
                    <Grid item lg={4} sm={4} xs={12}>
                      <div className={classes.bankBalance}>
                        <div className={classes.bankMoney}>
                          <Typography
                            variant="p"
                            // style={{ paddingLeft: '5px' }}
                          >
                            Curr.Amt = {cash}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div className={classes.tableWrapper}>
                <Grid container spacing={3}>
                  <Grid item lg={4} sm={4} xs={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      // justifyContent='left'
                      height={'100%'}
                      className={classes.sell1BottomTotal}
                    >
                      <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h6" style={{ paddingLeft: '5px' }}>
                          Total : {bankCaseTotalAmount}
                        </Typography>
                      </Breadcrumbs>
                    </Box>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <FormControl className={classes.sellComponent}>
                      <TextField
                        id="datetime-local"
                        type="datetime-local"
                        value={selectedDate}
                        sx={{ width: 250 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setSelectedDate(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      <div className={classes.sell2Action}>
                        <div className={classes.sell2CancelButton}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => history.goBack()}
                            className={classes.button}
                            style={{ width: '100%' }}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className={classes.sell2SubmitButton}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleSubmit()}
                            className={classes.button}
                            style={{ width: '100%' }}
                          >
                            {addExpenseLoading ? <CircularProgress size={24} /> : 'Submit'}
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
        </Widget>
      </Grid>
    </div>
  );
}
