import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, InputBase, Typography } from '@material-ui/core';

const BootstrapInput = withStyles((theme) => ({
  input: (props) => ({
    alignItems: 'center',
    paddingTop: props.paddingTop ? props.paddingTop : '14.5px',
    paddingBottom: props.paddingBottom ? props.paddingBottom : '14.5px',
    position: 'relative',
    backgroundColor: props.backgroundColor ? props.backgroundColor : 'white',
    boxShadow:
      '0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
    borderRadius: 5,
    fontSize: 15,
    paddingLeft: props.paddingLeft ? props.paddingLeft : 10,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    '&:focus': {
      borderRadius: 4,
      boxShadow: null,
      backgroundColor: props.backgroundColor ? props.backgroundColor : 'white',
    },
    color: 'rgb(110, 110, 110)',
  }),
}))((props) => <InputBase {...props} />);

const DropDown = (props) => {
  const classes = useStyles(props);

  const {
    title,
    selectedItem,
    handleChange,
    ItemsArray,
    title2,
    isTitleTwoNeeded,
    specificValue,
    titleNotDisable,
    isDisable,
    // inputError,
  } = props;
  // const getValue = (index, id) => {
  //   // if (!title) {
  //   //   return index + 1;
  //   // }
  //   if (isTitleTwoNeeded && id === undefined) {
  //     return index + 3;
  //   }
  //   if (id && id !== undefined) {
  //     return id;
  //   }
  //   return index + 2;
  // };

  //   const secondMenuItem = (title2, specificValue) => {
  //     if (title2 === Strings.ALL_APPLICATION && specificValue === 2) {
  //       return true;
  //     }
  //     if (title2 === Strings.ALL_SERVER) {
  //       return true;
  //     }
  //     return false;
  //   };

  //   const setInputError = (error) => {
  //     return <Typography className={classes.lengthText}>{error}</Typography>;
  //   };
  return (
    <div className={classes.inputView}>
      <Select
        style={{
          width: '100%',
        }}
        labelId='demo-customized-select-label'
        id='demo-customized-select'
        value={selectedItem}
        onChange={handleChange}
        input={<BootstrapInput {...props} />}
        disabled={isDisable}
      >
        {title ? (
          <MenuItem
            value={1}
            disabled={titleNotDisable ? false : true}
            className={classes.menuItem}
          >
            {title}
          </MenuItem>
        ) : null}
        {/* {secondMenuItem(title2, specificValue) ? (
          <MenuItem value={2} className={classes.menuItem}>
            {title2}
          </MenuItem>
        ) : null} */}
        {ItemsArray && ItemsArray.length > 0
          ? ItemsArray.map((item, index) => {
              return (
                <MenuItem
                  value={item && item.id && item.id}
                  key={index}
                  className={classes.menuItem}
                >
                  {item.name ? item.name : item}
                </MenuItem>
              );
            })
          : null}
      </Select>
      {/* {inputError ? setInputError(inputError) : null} */}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItem: (props) => ({
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: 'rgb(110, 110, 110)',
    fontWeight: 400,
    maxWidth: props.fullWidth ? '100%' : 300,
    whiteSpace: 'inherit',
  }),
  lengthText: {
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: '9px',
    color: '#d32f2e',
    position: 'absolute',
    marginTop: 50,
  },
  inputView: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
}));

export default DropDown;
