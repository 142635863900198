import React from 'react';
import {
  // Dashboard as DashboardIcon,
  MonetizationOn as TransactionIcon,
  CompareArrows as CompareArrowsIcon,
} from '@material-ui/icons';

const structure = [
  // {
  //   id: 0,
  //   label: 'Dashboard',
  //   link: '/app/dashboard',
  //   icon: <DashboardIcon />,
  // },
  {
    id: 1,
    label: 'Transactions',
    link: '/app/transactions',
    icon: <TransactionIcon />,
  },
  {
    id: 2,
    label: 'Transfer Money',
    link: '/app/transfer/money',
    icon: <CompareArrowsIcon />,
  },
];

export default structure;
