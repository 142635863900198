import React from 'react';
import {
  // Dashboard as DashboardIcon,
  PersonAdd as PersonAddIcon,
  LocalGroceryStore as ShopIcon,
  AccountBalance as BankIcon,
  Category as CategoryIcon,
  LocalMall as BuyerIcon,
  MonetizationOn as TransactionIcon,
  CompareArrows as CompareArrowsIcon,
} from '@material-ui/icons';

const structure = [
  // {
  //   id: 0,
  //   label: 'Dashboard',
  //   link: '/app/dashboard',
  //   icon: <DashboardIcon />,
  // },
  {
    id: 1,
    label: 'Transactions',
    link: '/app/transactions',
    icon: <TransactionIcon />,
  },
  // {
  //   id: 2,
  //   label: 'Cash',
  //   link: '/app/cash',
  //   icon: <TransactionIcon />,
  // },
  {
    id: 3,
    label: 'Transfer Money',
    link: '/app/transfer/money',
    icon: <CompareArrowsIcon />,
  },
  { id: 4, type: 'divider' },
  {
    id: 5,
    label: 'Category Management',
    link: '/app/category',
    icon: <CategoryIcon />,
  },
  {
    id: 6,
    label: 'Store Management',
    link: '/app/shop',
    icon: <ShopIcon />,
  },
  {
    id: 7,
    label: 'Supplier Management',
    link: '/app/buyer',
    icon: <BuyerIcon />,
  },
  {
    id: 8,
    label: 'User Management',
    link: '/app/user',
    icon: <PersonAddIcon />,
  },
  {
    id: 9,
    label: 'Bank Management',
    link: '/app/bank',
    icon: <BankIcon />,
  },
];

export default structure;
