import axios from 'axios';
import { SHOP_LIST } from '../../constants';

export const fetchShopList = (type) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadShopList());
  let path;
  if (type === 'user') path = 'user/shopList';
  else path = 'admin/shop';
  axios
    .get(path, config)
    .then((res) => dispatch(setShopList(res)))
    .catch((err) => dispatch(errorShopList(err.response)));
};

export const loadShopList = () => {
  return {
    type: SHOP_LIST.LOAD,
  };
};

export const setShopList = (shopList) => {
  return {
    type: SHOP_LIST.SUCCESS,
    payload: shopList.data,
  };
};

export const errorShopList = (error) => {
  return {
    type: SHOP_LIST.FAIL,
    payload: error,
  };
};
