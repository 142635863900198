import axios from 'axios';
import { ADD_BUY } from '../../constants';

export const inviteBuy = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(addBuy());
  axios
    .post(`user/addBuy`, data, config)
    .then((res) => {
      dispatch(setBuy(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorBuy(err.response)));
};

export const addBuy = () => {
  return {
    type: ADD_BUY.LOAD,
  };
};

export const setBuy = (data) => {
  return {
    type: ADD_BUY.SUCCESS,
    payload: data.data,
  };
};

export const errorBuy = (error) => {
  return {
    type: ADD_BUY.FAIL,
    payload: error,
  };
};
