import axios from 'axios';

import { EDIT_BANK } from '../../constants';

export const editBank = (bID, shopData, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadEditBank());
  axios
    .put(`admin/bank/${bID}`, shopData, config)
    .then((res) => {
      dispatch(setEditBank(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorEditBank(err.response)));
};

export const loadEditBank = () => {
  return {
    type: EDIT_BANK.LOAD,
  };
};

export const setEditBank = (data) => {
  return {
    type: EDIT_BANK.SUCCESS,
    payload: data.data,
  };
};

export const errorEditBank = (data) => {
  return {
    type: EDIT_BANK.FAIL,
    payload: data,
  };
};
