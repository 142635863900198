import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  fixIconRight: {
    marginRight: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: 38,
    width: `calc(100vw - 240px)`,
    minHeight: '100vh',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(25),
    width: theme.spacing(60),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonOk: {
    marginTop: theme.spacing(3),
  },
  tableWidget: {
    overflowX: 'auto',
  },
  emptyTable: {
    width: '100%',
    textAlign: 'center',
    fontStyle: 'italic',
    color: 'grey',
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    marginRight: theme.spacing(2),
  },
  defaultRadio: {
    color: '#536DFE',
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: '#536DFE',
    },
  },
  successRadio: {
    color: '#23a075',
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: '#23a075',
    },
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  loginLoaderContainer: {
    width: '100%',
    textAlign: 'center',
  },
  secondaryRadio: {
    color: '#FF5C93',
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: '#FF5C93',
    },
  },
  warningRadio: {
    color: '#FFC260',
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: '#FFC260',
    },
  },
  infoRadio: {
    color: '#9013FE',
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: '#9013FE',
    },
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: 'none',
    '&:active': {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
  ecommerceIcon: {
    color: '#6E6E6E',
  },
  calendarIcon: {
    color: theme.palette.primary.main,
    marginRight: 14,
  },
  margin: {
    marginBottom: 24,
  },
  changeThemeFab: {
    position: 'fixed',
    top: theme.spacing(15),
    right: 0,
    zIndex: 1,
    borderRadius: 0,
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  navPadding: {
    // paddingTop: `${theme.spacing(1)}px !important`,
    // paddingBottom: `6px !important`,
    padding: `${theme.spacing(1)}px !important`,
  },
  date: {
    marginRight: 38,
    color: theme.palette.type === 'dark' ? '#D6D6D6' : '#4A494A',
  },
  sellComponent: {
    width: '100%',
  },
  sell1BottomTotal: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  },
  sell1NextButton: {
    width: 80,
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  sell2CancelButton: {
    width: 80,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sell2SubmitButton: {
    width: 80,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: 10,
    },
  },
  sell2Action: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  deleteSellComponent: {
    width: 80,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
  },
  categoryDivider: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      marginBottom: 15,
    },
  },
  bankBalance: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  bankMoney: {
    marginRight: 10,
    color: 'rgb(66, 165, 245)',
    fontSize: '15px',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 10,
    },
  },
  calendarButton: {
    backgroundColor: 'white',
    color: 'rgb(110, 110, 110)',
    paddingTop: 7,
    paddingBottom: 7,
    justifyContent: 'space-between',
    boxShadow:
      'rgb(232 234 252) 0px 3px 11px 0px, rgb(178 178 178 / 10%) 0px 3px 3px -2px, rgb(154 154 154 / 10%) 0px 1px 8px 0px',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow:
        'rgb(232 234 252) 0px 3px 11px 0px, rgb(178 178 178 / 10%) 0px 3px 3px -2px, rgb(154 154 154 / 10%) 0px 1px 8px 0px',
    },
  },
  shopList: {
    width: 150,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: 10,
      marginTop: 10,
    },
  },
  shopListMain: {
    width: '80%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: 10,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 0,
    },
  },
  calendarTop: {
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  submitMain: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  btnsView: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 10,
  },
  filterMain: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filterButton: {
    width: '30%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 10,
  },
  toolbarMain: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  filterContainer: {
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },

  dropDownContainer: {
    width: '15%',
    marginRight: 10,
  },
}));
