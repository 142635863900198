import React, { useEffect, useState } from 'react';
import { Grid, Box, Breadcrumbs } from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import { Typography } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchBuyerList } from '../../actions/adminActions/buyerList';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import AddBuy1 from './AddBuy1';
import AddBuy2 from './AddBuy2';
import { fetchBankList } from '../../actions/adminActions/bankList';
import { inviteBuy } from '../../actions/adminActions/addBuy';

const AddBuy = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [buyerListData, setBuyerListData] = useState([]);
  const [buyPage, setBuyPage] = React.useState(0);
  const [buys, setBuys] = useState([]);
  const [debitInBank, setDebitInBank] = useState([]);
  const [bankListData, setBankListData] = useState([]);
  const [debitInCash, setDebitInCash] = useState(null);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [bankTotalAmount, setBankTotalAmount] = React.useState(0);
  const [bankCaseTotalAmount, setBankCaseTotalAmount] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState();
  const theme = useTheme();

  const employeeData = props.employeeData.employee;

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.buyerListError));
  }, [props.buyerListError]);

  useEffect(() => {
    const { subShopId } = props.location.state;
    setError('');
    props.fetchBuyerList(employeeData.type, subShopId);
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setSelectedDate(now.toISOString().slice(0, 16));
  }, []);

  useEffect(() => {
    let data = props.buyerListData.data ? props.buyerListData.data : [];
    const newBuyerList = data.map((e) => ({
      name: e.name,
      id: e._id,
    }));
    setBuyerListData(newBuyerList);
  }, [props.buyerListData]);

  useEffect(() => {
    let data = props.bankListData.data ? props.bankListData.data : [];
    const newBankList = data.map((e) =>
      employeeData.type === 'user'
        ? {
            name: e.name,
            id: e._id,
          }
        : {
            money: e.money,
            name: e.name,
            id: e._id,
          }
    );
    setBankListData(newBankList);
  }, [props.bankListData]);

  const handleAddBuyer = () => {
    if (buys.length === 0) {
      setBuys([...buys, { buyerId: '1', amount: '' }]);
      setError('');
    } else {
      let newBuys = [...buys];
      newBuys.forEach((buy, index) => {
        let tempBuys = { ...newBuys[index] };
        let sum = buy.amount
          ? buy.amount
              .toString()
              .split('')
              .filter((item) => item !== '.')
              .map(Number)
              .reduce(function(a, b) {
                return a + b;
              }, 0)
          : 0;
        tempBuys.amount = sum;
        newBuys[index] = tempBuys;
      });
      const filteredBuys = newBuys.filter(
        (buy, index) => buy.buyerId === '1' || !buy.amount
      );
      if (filteredBuys.length === 0)
        setBuys([...buys, { buyerId: '1', amount: '' }]);
      else setError('Please enter buyer or amount');
    }
  };

  const handleAddBank = () => {
    if (debitInBank.length === 0) {
      setDebitInBank([...debitInBank, { bankId: '1', amount: '' }]);
      setError('');
    } else {
      let newBanks = [...debitInBank];
      newBanks.forEach((bank, index) => {
        let tempBanks = { ...newBanks[index] };
        let sum = bank.amount
          ? bank.amount
              .toString()
              .split('')
              .filter((item) => item !== '.')
              .map(Number)
              .reduce(function(a, b) {
                return a + b;
              }, 0)
          : 0;
        tempBanks.amount = sum;
        newBanks[index] = tempBanks;
      });
      const filteredBanks = newBanks.filter(
        (bank, index) => bank.bankId === '1' || !bank.amount
      );
      if (filteredBanks.length === 0) {
        const filteredMoneyBanks = debitInBank.filter(
          (bank, index) =>
            parseFloat(bank.amount) > parseFloat(bank.money) && bank
        );
        if (filteredMoneyBanks.length === 0 || employeeData.type === 'user') {
          setDebitInBank([...debitInBank, { bankId: '1', amount: '' }]);
          setError('');
        } else {
          setError('Amount not sufficient in bank!');
        }
      } else setError('Please enter bank or amount');
    }
  };

  const handleAmountInput = (e, index) => {
    let newBuys = [...buys];
    let tempBuys = { ...newBuys[index] };
    tempBuys.amount = e.target.value;
    newBuys[index] = tempBuys;
    setBuys(newBuys);
  };

  const handleBankAmountInput = (e, index) => {
    let newBanks = [...debitInBank];
    let tempBanks = { ...newBanks[index] };
    tempBanks.amount = e.target.value;
    newBanks[index] = tempBanks;
    setDebitInBank(newBanks);
  };

  useEffect(() => {
    const amount =
      buys.length > 0
        ? buys.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;
    setTotalAmount(amount);
  }, [buys]);

  useEffect(() => {
    const amount =
      debitInBank.length > 0
        ? debitInBank.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;
    setBankTotalAmount(amount);
  }, [debitInBank]);

  useEffect(() => {
    const amount =
      debitInBank.length > 0
        ? debitInBank.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;

    setBankCaseTotalAmount(amount + parseFloat(debitInCash ? debitInCash : 0));
  }, [debitInBank, debitInCash]);

  const handleChangeBuyer = (e, index) => {
    let newBuys = [...buys];
    let tempBuys = { ...newBuys[index] };
    if (tempBuys.buyerId !== e.target.value) {
      tempBuys.buyerId = e.target.value;
      newBuys[index] = tempBuys;
      setBuys(newBuys);
      setError('');
    }
  };

  const handleChangeBank = (e, index) => {
    let newBanks = [...debitInBank];
    let tempBanks = { ...newBanks[index] };
    if (tempBanks.bankId !== e.target.value) {
      tempBanks.bankId = e.target.value;
      const money = bankListData.filter((bank) => bank.id === e.target.value);
      tempBanks.money = money[0].money;
      newBanks[index] = tempBanks;
      setDebitInBank(newBanks);
      setError('');
    }
  };

  const handleOnDeleteBuyer = (BuyIndex) => {
    const filteredBuys = buys.filter((buy, index) => index !== BuyIndex);
    setBuys(filteredBuys);
    setError('');
  };

  const handleOnDeleteBank = (bankIndex) => {
    const filteredBanks = debitInBank.filter(
      (bank, index) => index !== bankIndex
    );
    setDebitInBank(filteredBanks);
    setError('');
  };

  const handleNext = () => {
    if (buys.length === 0) setError('Please add buyer');
    else {
      let newBuys = [...buys];
      newBuys.forEach((buy, index) => {
        let tempBuys = { ...newBuys[index] };
        let sum = buy.amount
          ? buy.amount
              .toString()
              .split('')
              .filter((item) => item !== '.')
              .map(Number)
              .reduce(function(a, b) {
                return a + b;
              }, 0)
          : 0;
        tempBuys.amount = sum;
        newBuys[index] = tempBuys;
      });
      const filteredBuys = newBuys.filter(
        (buy, index) => buy.buyerId === '1' || !buy.amount
      );
      if (filteredBuys.length === 0) {
        setBuyPage(1);
        const { subShopId } = props.location.state;
        props.fetchBankList(subShopId);
      } else setError('Please enter buyer or amount');
    }
  };

  useEffect(() => {
    setError(getErrorMessage(props.bankListError));
  }, [props.bankListError]);

  useEffect(() => {
    setError(getErrorMessage(props.addBuyError));
  }, [props.addBuyError]);

  const handleSubmit = () => {
    let newBanks = [...debitInBank];
    newBanks.forEach((bank, index) => {
      let tempBanks = { ...newBanks[index] };
      let sum = bank.amount
        ? bank.amount
            .toString()
            .split('')
            .filter((item) => item !== '.')
            .map(Number)
            .reduce(function(a, b) {
              return a + b;
            }, 0)
        : 0;
      tempBanks.amount = sum;
      newBanks[index] = tempBanks;
    });
    const filteredBanks = newBanks.filter(
      (bank, index) => bank.bankId === '1' || !bank.amount
    );
    const date = new Date(selectedDate);
    if (employeeData.type === 'user') {
      if (filteredBanks.length === 0) {
        if (totalAmount === bankCaseTotalAmount) {
          const { subShopId } = props.location.state;
          let newDebitInBank = debitInBank.map(({ bankId, amount }) => ({
            bankId,
            amount,
          }));
          const data = {
            subShopId,
            debitInCash: debitInCash ? debitInCash : 0,
            debitInBank: newDebitInBank,
            buys,
            date: date.toISOString(),
          };
          props.inviteBuy(data, history);
        } else {
          setError('Totals are not matched');
        }
      } else setError('Please enter bank or amount');
    } else {
      if (filteredBanks.length === 0) {
        const filteredMoneyBanks = debitInBank.filter(
          (bank, index) =>
            parseFloat(bank.amount) > parseFloat(bank.money) && bank
        );
        if (filteredMoneyBanks.length === 0) {
          const { cash } = props.location.state;
          const dabitCash = debitInCash ? debitInCash : 0;
          if (parseFloat(dabitCash) <= parseFloat(cash)) {
            if (totalAmount === bankCaseTotalAmount) {
              const { subShopId } = props.location.state;
              let newDebitInBank = debitInBank.map(({ bankId, amount }) => ({
                bankId,
                amount,
              }));
              const data = {
                subShopId,
                debitInCash: debitInCash ? debitInCash : 0,
                debitInBank: newDebitInBank,
                buys,
                date: date.toISOString(),
              };
              props.inviteBuy(data, history);
            } else {
              setError('Totals are not matched');
            }
          } else {
            setError('Amount not sufficient in cash!');
          }
        } else {
          setError('Amount not sufficient in bank!');
        }
      } else setError('Please enter bank or amount');
    }
  };

  const handleBuyerListData = (buyerIndex) => {
    if (buyerIndex === 0) return buyerListData;
    else {
      const tempBuyerList = buys.filter(
        (buy, index) => index < buyerIndex && buy.buyerId
      );
      let newBuyerList = buyerListData.filter((buyer) => {
        return (
          tempBuyerList.filter((buy) => {
            return buy.buyerId == buyer.id;
          }).length == 0
        );
      });
      return newBuyerList;
    }
  };

  const handleBankListData = (bankIndex) => {
    if (bankIndex === 0) return bankListData;
    else {
      const tempBankList = debitInBank.filter(
        (bank, index) => index < bankIndex && bank.bankId
      );
      let newBankList = bankListData.filter((bank) => {
        return (
          tempBankList.filter((ban) => {
            return ban.bankId == bank.id;
          }).length == 0
        );
      });
      return newBankList;
    }
  };

  return (
    <>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
        >
          <Box display='flex' alignItems='center'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                Add Buy
              </Typography>
            </Breadcrumbs>
          </Box>
          {buyPage === 1 && (
            <Box display='flex' alignItems='center'>
              <Breadcrumbs aria-label='breadcrumb'>
                <Typography variant='h6'>Total : {totalAmount}</Typography>
              </Breadcrumbs>
            </Box>
          )}
        </Grid>
      </Widget>
      {props.buyerListLoading || props.bankListLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {buyPage === 0 ? (
            <AddBuy1
              classes={classes}
              error={error}
              buys={buys}
              buyerListData={buyerListData}
              handleChangeBuyer={handleChangeBuyer}
              handleAmountInput={handleAmountInput}
              setError={setError}
              handleOnDeleteBuyer={handleOnDeleteBuyer}
              handleAddBuyer={handleAddBuyer}
              totalAmount={totalAmount}
              handleNext={handleNext}
              handleBuyerListData={handleBuyerListData}
            />
          ) : (
            <AddBuy2
              classes={classes}
              error={error}
              debitInBank={debitInBank}
              handleChangeBank={handleChangeBank}
              handleBankAmountInput={handleBankAmountInput}
              setError={setError}
              handleOnDeleteBank={handleOnDeleteBank}
              handleAddBank={handleAddBank}
              bankTotalAmount={bankTotalAmount}
              bankCaseTotalAmount={bankCaseTotalAmount}
              debitInBank={debitInBank}
              bankListData={bankListData}
              setDebitInCash={setDebitInCash}
              debitInCash={debitInCash}
              history={history}
              handleSubmit={handleSubmit}
              addBuyLoading={props.addBuyLoading}
              handleBankListData={handleBankListData}
              employeeData={employeeData}
              cash={props.location.state.cash}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          )}
        </>
      )}
    </>
  );
};

const component = withRouter(AddBuy);

const mapStateToProps = (state) => {
  return {
    buyerListData: state.buyerListData.data,
    buyerListLoading: state.buyerListData.loading,
    buyerListError: state.buyerListData.error,

    bankListData: state.bankListData.data,
    bankListLoading: state.bankListData.loading,
    bankListError: state.bankListData.error,

    addBuyData: state.addBuyData.data,
    addBuyLoading: state.addBuyData.loading,
    addBuyError: state.addBuyData.error,

    employeeData: state.employeeData,
  };
};
export default connect(mapStateToProps, {
  fetchBuyerList,
  fetchBankList,
  inviteBuy,
})(component);
