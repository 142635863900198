import axios from 'axios';
import { USER_LIST } from '../../constants';

export const fetchUserList = () => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadUserList());

  axios
    .get('/admin/user', config)
    .then((res) => dispatch(setUserList(res)))
    .catch((err) => dispatch(errorUserList(err.response)));
};

export const loadUserList = () => {
  return {
    type: USER_LIST.LOAD,
  };
};

export const setUserList = (userList) => {
  return {
    type: USER_LIST.SUCCESS,
    payload: userList.data,
  };
};

export const errorUserList = (error) => {
  return {
    type: USER_LIST.FAIL,
    payload: error,
  };
};
