import axios from 'axios';
import moment from 'moment';
import { TRANSFER_MONEY } from '../../constants';

export const inviteTransfer = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(addTransferMoney());
  axios
    .post(`user/addTransfer`, data, config)
    .then((res) => {
      dispatch(setTransferMoney(res));
      const date = new Date(data.date);
      const startDateNew = new Date(moment(date).startOf('day'));
      const endDateNew = new Date(moment(date).endOf('day'));
      const selectedDates =
        moment(startDateNew.toString()).format('DD/MM/YYYY') +
        ' - ' +
        moment(endDateNew.toString()).format('DD/MM/YYYY');
      history.push({
        pathname: '/app/transactions',
        state: {
          selectedShop: data.subShopId,
          startDate: startDateNew,
          endDate: endDateNew,
          startEndDate: selectedDates,
        },
      });
    })
    .catch((err) => dispatch(errorTransferMoney(err.response)));
};

export const addTransferMoney = () => {
  return {
    type: TRANSFER_MONEY.LOAD,
  };
};

export const setTransferMoney = (data) => {
  return {
    type: TRANSFER_MONEY.SUCCESS,
    payload: data.data,
  };
};

export const errorTransferMoney = (error) => {
  return {
    type: TRANSFER_MONEY.FAIL,
    payload: error,
  };
};
