import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Breadcrumbs,
  Table,
  Fade,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  AppBar,
  Toolbar,
  IconButton,
  lighten,
  TableHead,
  TableSortLabel,
} from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import moment from 'moment';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { Tooltip } from 'recharts';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { fetchBuyerList } from '../../actions/adminActions/buyerList';
import { deleteBuyer } from '../../actions/adminActions/deleteBuyer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { buyerList } from '../../const';

const Buyer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [buyerListData, setShopListData] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selectedA, setSelectedA] = React.useState([]);
  const [pageA, setPageA] = React.useState(0);
  const [rowsPerPageA, setRowsPerPageA] = React.useState(5);
  const theme = useTheme();

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.buyerListError));
  }, [props.buyerListError]);

  useEffect(() => {
    setError('');
  }, []);

  useEffect(() => {
    props.fetchBuyerList();
  }, [props.deleteBuyerData]);

  useEffect(() => {
    let data = props.buyerListData.data ? props.buyerListData.data : [];
    setShopListData(data);
  }, [props.buyerListData]);

  const headCells = [
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'address', numeric: true, disablePadding: false, label: 'Address' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
  ];

  const handleChangePageA = (event, newPage) => {
    setPageA(newPage);
  };

  const handleChangeRowsPerPageA = (event) => {
    setRowsPerPageA(parseInt(event.target.value, 10));
    setPageA(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClickA = (event) => {
    if (event.target.checked) {
      const newSelecteds = buyerListData.map((n) => n.id);
      setSelectedA(newSelecteds);
      return;
    }
    setSelectedA([]);
  };

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function EnhancedTableHead(props) {
    const { classes, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'left' : 'right'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
                style={{
                  whiteSpace: 'nowrap',
                  textTransform: 'uppercase',
                  fontSize: '0.85rem',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}></span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const common = (row, index) => {
    let resignationDate = row.resignDate
      ? moment(row.resignDate).format('DD MMM YYYY')
      : 'N/A';
    let resign = row.resignDate
      ? moment(row.resignDate).format('YYYY-MM-DD')
      : undefined;
    return (
      <TableRow>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.address}</TableCell>
        <TableCell>
          <Grid style={{ display: 'flex' }}>
            <Button
              variant={'contained'}
              color={'primary'}
              style={{ marginRight: 8 }}
              onClick={() =>
                history.push({
                  pathname: '/app/buyer/edit',
                  state: {
                    id: row._id,
                    name: row.name,
                    address: row.address,
                  },
                })
              }
            >
              Edit
            </Button>
            <Button
              variant={'contained'}
              color={'secondary'}
              style={{ marginRight: 8 }}
              onClick={() => onDelete(row._id, index)}
            >
              Delete
            </Button>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const useToolbarStyles = makeStyles((theme) => ({
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));

  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();

    return (
      <Toolbar>
        <Box display={'flex'} className={classes.title}>
          <Typography
            variant='h6'
            color='text'
            colorBrightness={'secondary'}
            id='tableTitle'
            style={{ display: 'flex' }}
            block
          >
            Buyer List
          </Typography>
        </Box>
        <Tooltip title='Filter list'>
          <IconButton aria-label='filter list'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  };
  const onDelete = (id, index) => {
    const r = window.confirm('Do you really want to Delete?');
    if (r === true) {
      if (props.deleteBuyer) {
        props.deleteBuyer(id);
      }
    }
  };
  return (
    <>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
        >
          <Box display='flex' alignItems='center'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                Buyer management
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box display='flex' alignItems='center'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => history.push('/app/buyer/create')}
              className={classes.button}
            >
              Add Buyer
            </Button>
          </Box>
        </Grid>
      </Widget>

      {/*shop list*/}
      {props.buyerListLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Widget noBodyPadding bodyClass={classes.tableWidget}>
            <EnhancedTableToolbar />
            <center>
              <Fade
                in={error}
                style={
                  !error ? { display: 'none' } : { display: 'inline-block' }
                }
              >
                <Typography color='secondary' className={classes.errorMessage}>
                  {error ? error : ''}
                </Typography>
              </Fade>
            </center>
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby='tableTitle'
                aria-label='leave status'
              >
                {props.buyerListLoading ? (
                  <div className={classes.loginLoaderContainer}>
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  </div>
                ) : buyerListData.length > 0 ? (
                  <>
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClickA}
                      onRequestSort={handleRequestSort}
                      rowCount={buyerListData.length}
                    />
                    <TableBody>
                      {stableSort(buyerListData, getSorting(order, orderBy))
                        .slice(
                          pageA * rowsPerPageA,
                          pageA * rowsPerPageA + rowsPerPageA
                        )
                        .map((row, index) => common(row, index))}
                    </TableBody>
                  </>
                ) : (
                  <p className={classes.emptyTable}>{buyerList}</p>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={buyerListData.length}
                rowsPerPage={rowsPerPageA}
                page={pageA}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePageA}
                onChangeRowsPerPage={handleChangeRowsPerPageA}
              />
            </div>
          </Widget>
        </Grid>
      )}
    </>
  );
};

const component = withRouter(Buyer);

const mapStateToProps = (state) => {
  return {
    buyerListData: state.buyerListData.data,
    buyerListLoading: state.buyerListData.loading,
    buyerListError: state.buyerListData.error,

    deleteBuyerData: state.deleteBuyerData.data,
    deleteBuyerDataLoading: state.deleteBuyerData.loading,
    deleteBuyerDataError: state.deleteBuyerData.error,
  };
};
export default connect(mapStateToProps, {
  fetchBuyerList,
  deleteBuyer,
})(component);
