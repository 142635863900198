import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(35),
    width: theme.spacing(70),
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonOk: {
    marginTop: theme.spacing(3),
  },

  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: '50%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  gridMain: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
  },
  logotypeImage: {
    width: 65,
    marginRight: theme.spacing(2),
  },
  logotypeText: {
    color: 'white',
    fontWeight: 700,
    fontSize: 42,
    fontFamily: 'Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  textMain: {
    color: 'white',
    display: 'flex',
    textAlign: 'justify',
    textAlignLast: 'right',
    marginTop: theme.spacing(20),
    marginLeft: theme.spacing(25),
    marginRight: theme.spacing(20),
  },
  text: {
    color: 'white',
    display: 'flex',
    textAlign: 'right',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(25),
    marginRight: theme.spacing(22),
  },
  formContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  greeting: {
    marginTop: theme.spacing(4),
  },
  starting: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(25),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    alignSelf: 'flex-end',
  },
  createAccountButton: {
    height: 46,
    textTransform: 'none',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + '40',
  },
  errorMessage: {
    paddingTop: '10px',
    textAlign: 'center',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
  },
  copyrightContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
