import axios from 'axios';
import { CATEGORY_LIST } from '../../constants';

export const fetchCategoryList = (type, subShopId) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadCategoryList());
  let path;
  if (type === 'user') path = `user/categoryList?subShopId=${subShopId}`;
  else path = 'admin/category';
  axios
    .get(path, config)
    .then((res) => dispatch(setCategoryList(res)))
    .catch((err) => dispatch(errorCategoryList(err.response)));
};

export const loadCategoryList = () => {
  return {
    type: CATEGORY_LIST.LOAD,
  };
};

export const setCategoryList = (categoryList) => {
  return {
    type: CATEGORY_LIST.SUCCESS,
    payload: categoryList.data,
  };
};

export const errorCategoryList = (error) => {
  return {
    type: CATEGORY_LIST.FAIL,
    payload: error,
  };
};
