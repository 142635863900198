export const emailCheck = (value) => {
  var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(value);
};

export const passwordCheck = (value) => {
  var regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return regex.test(value);
};

export const phoneCheck = (value) => {
  var regex = /^(\+[\d]{1,5}|0)[1-9]\d{9}$/;
  return regex.test(value);
};

export const websiteCheck = (value) => {
  var regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return regex.test(value);
};

