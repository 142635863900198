import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Breadcrumbs,
  Fade,
  Toolbar,
  IconButton,
  lighten,
  Checkbox,
  Paper,
  FormControlLabel,
} from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { Tooltip } from 'recharts';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { makeStyles, styled } from '@material-ui/styles';
import { connect } from 'react-redux';
import { fetchBankList } from '../../actions/adminActions/bankList';
import { assignBank } from '../../actions/adminActions/assignBank';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bankList } from '../../const';

const AssignBank = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [bankListData, setBankListData] = useState([]);
  const [assignedBank, setAssignedBank] = React.useState([]);
  const [initialAssignedBank, setInitialAssignedBank] = React.useState([]);

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.bankListError));
  }, [props.bankListError]);

  useEffect(() => {
    setError(getErrorMessage(props.assignBankError));
  }, [props.assignBankError]);

  useEffect(() => {
    setError('');
  }, []);

  useEffect(() => {
    props.fetchBankList(props.location.state.id, 'assignBank');
  }, [props.deleteShopData]);

  useEffect(() => {
    let data = props.bankListData.data ? props.bankListData.data : [];
    setBankListData(data);
    const tempAssignedBankIds = [];
    data.forEach(
      (val) =>
        val.assignedShop.includes(props.location.state.id) &&
        tempAssignedBankIds.push(val._id)
    );
    setAssignedBank(tempAssignedBankIds);
    setInitialAssignedBank(tempAssignedBankIds);
  }, [props.bankListData]);

  const handleOnSave = async () => {
    const { id } = props.location.state;
    const tempUnAssignedBankIds = bankListData.filter(
      (val) => !assignedBank.includes(val._id)
    );
    const unAssignedBankIds = [];
    tempUnAssignedBankIds.forEach((value) => unAssignedBankIds.push(value._id));
    props.assignBank(
      id,
      { assignedBankIds: assignedBank, unAssignedBankIds },
      history
    );
  };

  const handleOnChange = (sid) => {
    const currentIndex = assignedBank.indexOf(sid);
    const newChecked = [...assignedBank];

    if (currentIndex === -1) {
      newChecked.push(sid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAssignedBank(newChecked);
  };

  function shouldButtonDisable() {
    let result = assignedBank.every((element) => {
      return initialAssignedBank.includes(element);
    });
    if (initialAssignedBank.length > 0 && assignedBank.length === 0) {
      return false;
    }
    if (result && initialAssignedBank.length === assignedBank.length) {
      return true;
    }
    return false;
  }

  const useToolbarStyles = makeStyles((theme) => ({
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));

  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Toolbar>
          <Box display={'flex'} className={classes.title}>
            <Typography
              variant='h6'
              color='text'
              colorBrightness={'secondary'}
              id='tableTitle'
              style={{ display: 'flex' }}
              block
            >
              Bank List
            </Typography>
          </Box>
          <Tooltip title='Filter list'>
            <IconButton aria-label='filter list'>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Box display='flex' alignItems='center'>
          <Button
            variant='contained'
            color='success'
            onClick={() => handleOnSave()}
            style={{ marginRight: 8 }}
            className={classes.button}
            disabled={shouldButtonDisable()}
          >
            {props.assignBankLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </div>
    );
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  }));

  return (
    <>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
        >
          <Box display='flex' alignItems='center'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                Shop management
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box display='flex' alignItems='center'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => history.goBack()}
              className={classes.button}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{ marginTop: 15 }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Box display='flex' alignItems='center'>
              <Breadcrumbs aria-label='breadcrumb'>
                <Typography variant='h6' style={{ paddingLeft: '5px' }}>
                  <b>Name</b> : {props.location.state.name}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <Box display='flex' alignItems='center'>
              <Breadcrumbs aria-label='breadcrumb'>
                <Typography variant='h6' style={{ paddingLeft: '5px' }}>
                  <b>Email</b> : {props.location.state.email}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid> */}
        </Grid>
      </Widget>

      {/*shop list*/}
      {props.bankListLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Widget noBodyPadding bodyClass={classes.tableWidget}>
            <EnhancedTableToolbar />
            <center>
              <Fade
                in={error}
                style={
                  !error ? { display: 'none' } : { display: 'inline-block' }
                }
              >
                <Typography color='secondary' className={classes.errorMessage}>
                  {error ? error : ''}
                </Typography>
              </Fade>
            </center>
            <div className={classes.tableWrapper}>
              {props.bankListLoading ? (
                <div className={classes.loginLoaderContainer}>
                  <CircularProgress size={26} className={classes.loginLoader} />
                </div>
              ) : bankListData.length > 0 ? (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {bankListData.map((row, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Item
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div>
                            <Grid item container alignItems={'center'}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color='primary'
                                    checked={
                                      assignedBank.indexOf(row._id) !== -1
                                    }
                                    onChange={() => handleOnChange(row._id)}
                                  />
                                }
                                label={
                                  <div
                                    style={{
                                      maxWidth: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {row.name}
                                  </div>
                                }
                              />
                            </Grid>
                          </div>
                        </Item>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <p className={classes.emptyTable}>{bankList}</p>
              )}
            </div>
          </Widget>
        </Grid>
      )}
    </>
  );
};

const component = withRouter(AssignBank);

const mapStateToProps = (state) => {
  return {
    bankListData: state.bankListData.data,
    bankListLoading: state.bankListData.loading,
    bankListError: state.bankListData.error,

    assignBankData: state.assignBankData.data,
    assignBankLoading: state.assignBankData.loading,
    assignBankError: state.assignBankData.error,
  };
};
export default connect(mapStateToProps, {
  fetchBankList,
  assignBank,
})(component);
