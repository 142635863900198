import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  Fade,
  TextField,
  Container,
  Button,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import '../../Utils/index.css';
import { emailCheck, passwordCheck } from '../../Utils/Validation.js';

// styles
import useStyles from './styles';
import { connect } from 'react-redux';

//components
import { registerShop } from '../../actions/companyActions/shopSignup';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InputAdornment from '@material-ui/core/InputAdornment';
import Email from '@material-ui/icons/Email';
import VpnKey from '@material-ui/icons/VpnKey';
import Shop from '@material-ui/icons/LocalGroceryStore';
import Person from '@material-ui/icons/Person';
import Home from '@material-ui/icons/Home';

function Signup(props) {
  var classes = useStyles();

  // local
  var [error, setError] = useState('');
  var [load, setLoad] = useState(false);
  var [shopNameValue, setShopNameValue] = useState('');
  var [shopEmailValue, setShopEmailValue] = useState('');
  var [shopPasswordValue, setShopPasswordValue] = useState('');
  var [shopConfirmPasswordValue, setShopConfirmPasswordValue] = useState('');
  var [shopOwnerNameValue, setShopOwnerNameValue] = useState('');
  var [shopAddressValue, setShopAddressValue] = useState('');
  var [currentStep, setCurrentStep] = useState(1);

  const shopData = {
    shopName: shopNameValue,
    password: shopPasswordValue,
    email: shopEmailValue,
    address: shopAddressValue,
    name: shopOwnerNameValue,
  };

  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.shopRegisterError));
  }, [props.shopRegisterError]);

  const registerInput = (inputId, value, onchange, placeholder, type, code) => {
    return (
      <TextField
        id={inputId}
        variant='outlined'
        InputProps={{
          startAdornment: code,
        }}
        onFocus={() => setError('')}
        value={value}
        onChange={onchange}
        margin='normal'
        placeholder={placeholder}
        type={type}
        fullWidth
      />
    );
  };

  const disableContinue = () => {
    return shopOwnerNameValue.length === 0 || shopNameValue.length === 0;
  };

  const goToSecondaryStep = () => {
    setCurrentStep(2);
  };

  const disableRegistration = () => {
    return (
      shopEmailValue.length === 0 ||
      shopPasswordValue.length === 0 ||
      shopConfirmPasswordValue.length === 0
    );
  };

  const submitTeam = () => {
    const eCheck = emailCheck(shopEmailValue);
    const pCheck = passwordCheck(shopPasswordValue);

    if (!eCheck) {
      setError('Enter a valid Email Address');
    } else if (!pCheck) {
      setError(
        'Please enter 8 character with at least one Capital and one number in password field'
      );
    } else if (shopPasswordValue !== shopConfirmPasswordValue) {
      setError('Password Mismatch');
    } else {
      setLoad(true);
      props.registerShop(shopData, setLoad);
    }
  };

  const getCurrentPage = () => {
    // eslint-disable-next-line default-case
    switch (currentStep) {
      case 1:
        return (
          <div>
            {registerInput(
              'name',
              shopNameValue,
              (e) => setShopNameValue(e.target.value),
              'Company Name',
              'text',
              <InputAdornment position='start'>
                <Shop />
              </InputAdornment>
            )}
            {registerInput(
              'ownerName',
              shopOwnerNameValue,
              (e) => setShopOwnerNameValue(e.target.value),
              'Owner Name',
              'text',
              <InputAdornment position='start'>
                <Person />
              </InputAdornment>
            )}
            {registerInput(
              'address',
              shopAddressValue,
              (e) => setShopAddressValue(e.target.value),
              'Address',
              'text',
              <InputAdornment position='start'>
                <Home />
              </InputAdornment>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            {registerInput(
              'email',
              shopEmailValue,
              (e) => setShopEmailValue(e.target.value),
              'Email Address',
              'email',
              <InputAdornment position='start'>
                <Email />
              </InputAdornment>
            )}
            {registerInput(
              'password',
              shopPasswordValue,
              (e) => setShopPasswordValue(e.target.value),
              'Password',
              'password',
              <InputAdornment position='start'>
                <VpnKey />
              </InputAdornment>
            )}
            {registerInput(
              'confirmPassword',
              shopConfirmPasswordValue,
              (e) => setShopConfirmPasswordValue(e.target.value),
              'Confirm Password',
              'password',
              <InputAdornment position='start'>
                <VpnKey />
              </InputAdornment>
            )}
          </div>
        );
    }
  };

  const getButtonContainer = () => {
    // eslint-disable-next-line default-case
    switch (currentStep) {
      case 1:
        return (
          <div className={classes.creatingButtonContainer}>
            <Button
              onClick={() => props.redirectLogin()}
              size='large'
              variant='contained'
              color='primary'
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => goToSecondaryStep()}
              disabled={disableContinue()}
              size='large'
              variant='contained'
              color='primary'
            >
              Continue
              <ArrowForwardIcon style={{ marginLeft: 5 }} />
            </Button>
          </div>
        );

      case 2:
        return (
          <div className={classes.creatingButtonContainer}>
            {load ? (
              <CircularProgress size={26} />
            ) : (
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                <Button
                  onClick={() => setCurrentStep(1)}
                  size='large'
                  variant='contained'
                  color='primary'
                  style={{ marginRight: 10 }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => submitTeam()}
                  disabled={disableRegistration()}
                  size='large'
                  variant='contained'
                  color='primary'
                >
                  Create
                </Button>
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <Container component='main' maxWidth='md'>
      <Typography variant='h2' className={classes.subGreeting}>
        Company Registration
      </Typography>
      <center>
        <Fade in={error}>
          <Typography color='secondary' className={classes.errorMessage}>
            {error ? error : ''}
          </Typography>
        </Fade>
      </center>
      <form className={classes.formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {getCurrentPage()}
          </Grid>
        </Grid>
        {getButtonContainer()}
      </form>
    </Container>
  );
}

const component = withRouter(Signup);
const mapStateToProps = (state) => {
  return {
    shopRegisterData: state.shopSignupData.data,
    shopRegisterDataLoading: state.shopSignupData.loading,
    shopRegisterError: state.shopSignupData.error,
  };
};

export default connect(mapStateToProps, {
  registerShop,
})(component);
