import { DASHBOARD_DATA } from '../../constants';
const initialState = {
  data: {},
  loading: false,
  error: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_DATA.LOAD:
      return {
        ...state,
        loading: true,
      };
    case DASHBOARD_DATA.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case DASHBOARD_DATA.FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
