import { ADD_SHOP, STORE_SLEETED_SHOP } from '../../constants';
const initialState = {
  data: [],
  loading: false,
  error: {},
  storeShop: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_SHOP.LOAD:
      return {
        ...state,
        loading: true,
      };
    case ADD_SHOP.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ADD_SHOP.FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case STORE_SLEETED_SHOP.SUCCESS:
      return {
        ...state,
        storeShop: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
