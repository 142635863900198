import axios from 'axios';

import { EDIT_USER } from '../../constants';

export const editUser = (uID, employeeData, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadEditUser());
  axios
    .put(`admin/user/${uID}`, employeeData, config)
    .then((res) => {
      dispatch(setEditUser(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorEditUser(err.response)));
};

export const loadEditUser = () => {
  return {
    type: EDIT_USER.LOAD,
  };
};

export const setEditUser = (data) => {
  return {
    type: EDIT_USER.SUCCESS,
    payload: data.data,
  };
};

export const errorEditUser = (data) => {
  return {
    type: EDIT_USER.FAIL,
    payload: data,
  };
};
