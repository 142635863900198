import axios from 'axios';

import { EDIT_CATEGORY } from '../../constants';

export const editCategory = (cID, categoryData, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadEditCategory());
  axios
    .put(`admin/category/${cID}`, categoryData, config)
    .then((res) => {
      dispatch(setEditCategory(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorEditCategory(err.response)));
};

export const loadEditCategory = () => {
  return {
    type: EDIT_CATEGORY.LOAD,
  };
};

export const setEditCategory = (data) => {
  return {
    type: EDIT_CATEGORY.SUCCESS,
    payload: data.data,
  };
};

export const errorEditCategory = (data) => {
  return {
    type: EDIT_CATEGORY.FAIL,
    payload: data,
  };
};
