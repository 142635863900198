import axios from 'axios';
import { DASHBOARD_DATA } from '../../constants';
import moment from 'moment-timezone'

export const fetchDashboardData = (startDate, endDate, sId) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadDashboardData());
  axios
    .get(
      `user/dashboardData?startDate=${startDate.toISOString()}&timeZone=${moment.tz.guess()}&endDate=${endDate.toISOString()}${
        sId ? `&subShopId=${sId}` : ''
      }`,
      config
    )
    .then((res) => dispatch(setDashboardData(res)))
    .catch((err) => dispatch(errorDashboardData(err.response)));
};

export const loadDashboardData = () => {
  return {
    type: DASHBOARD_DATA.LOAD,
  };
};

export const setDashboardData = (dashboardData) => {
  return {
    type: DASHBOARD_DATA.SUCCESS,
    payload: dashboardData.data,
  };
};

export const errorDashboardData = (error) => {
  return {
    type: DASHBOARD_DATA.FAIL,
    payload: error,
  };
};
