import axios from 'axios';
import { ADD_BANK } from '../../constants';

export const inviteBank = (data, history) => dispatch => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token }
  };
  dispatch(addBank());
  axios
    .post(`admin/addBank`, data, config)
    .then(res => {
      dispatch(setBank(res));
      history.goBack();
    })
    .catch(err => dispatch(errorBank(err.response)));
};

export const addBank = () => {
  return {
    type: ADD_BANK.LOAD
  };
};

export const setBank = data => {
  return {
    type: ADD_BANK.SUCCESS,
    payload: data.data
  };
};

export const errorBank = error => {
  return {
    type: ADD_BANK.FAIL,
    payload: error
  };
};
