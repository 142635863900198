import axios from 'axios';
import { TRANSACTION_DATA } from '../../constants';

export const fetchTransactionData = (startDate, endDate, sId, from, type) => (dispatch) => {
  if (!sId) {
    dispatch(setTransactionData({ data: [] }));
  } else {
    const token = localStorage.jwtToken;
    let config = {
      headers: { Authorization: 'Bearer ' + token },
    };
    let query = `subShopId=${sId}`;
    if (startDate) query = query + `&startDate=${startDate.toISOString()}`;
    if (endDate) query = query + `&endDate=${endDate.toISOString()}`;
    if (from) query = query + `&from=${from}`;
    if (type) query = query + `&type=${type}`;

    dispatch(loadTransactionData());
    axios
      .get(`user/transactionList?${query}`, config)
      .then((res) => dispatch(setTransactionData(res)))
      .catch((err) => dispatch(errorTransactionData(err.response)));
  }
};

export const loadTransactionData = () => {
  return {
    type: TRANSACTION_DATA.LOAD,
  };
};

export const setTransactionData = (transactionData) => {
  return {
    type: TRANSACTION_DATA.SUCCESS,
    payload: transactionData.data,
  };
};

export const errorTransactionData = (error) => {
  return {
    type: TRANSACTION_DATA.FAIL,
    payload: error,
  };
};
