import axios from 'axios';

import { EDIT_SHOP } from '../../constants';

export const editShop = (sID, shopData, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadEditShop());
  axios
    .put(`admin/shop/${sID}`, shopData, config)
    .then((res) => {
      dispatch(setEditShop(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorEditShop(err.response)));
};

export const loadEditShop = () => {
  return {
    type: EDIT_SHOP.LOAD,
  };
};

export const setEditShop = (data) => {
  return {
    type: EDIT_SHOP.SUCCESS,
    payload: data.data,
  };
};

export const errorEditShop = (data) => {
  return {
    type: EDIT_SHOP.FAIL,
    payload: data,
  };
};
