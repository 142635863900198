import axios from 'axios';

import { EDIT_BUYER } from '../../constants';

export const editBuyer = (bID, shopData, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadEditBuyer());
  axios
    .put(`admin/buyer/${bID}`, shopData, config)
    .then((res) => {
      dispatch(setEditBuyer(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorEditBuyer(err.response)));
};

export const loadEditBuyer = () => {
  return {
    type: EDIT_BUYER.LOAD,
  };
};

export const setEditBuyer = (data) => {
  return {
    type: EDIT_BUYER.SUCCESS,
    payload: data.data,
  };
};

export const errorEditBuyer = (data) => {
  return {
    type: EDIT_BUYER.FAIL,
    payload: data,
  };
};
