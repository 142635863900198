import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { ThemeProvider } from '@material-ui/styles';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { CssBaseline } from '@material-ui/core';
import config from '../src/config';
import Theme from '../src/themes';
import store from '../src/store';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../src/Utils/setAuthToken';
import {
  setEmployeeLogin,
  errorEmployeeLogin,
} from '../src/actions/employeeActions/employeeLogin';
const { dispatch } = store;

axios.defaults.baseURL = config.BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    let decoded = '';
    if (localStorage.jwtToken) {
      const token = localStorage.jwtToken;
      decoded = jwt_decode(token);
    }
    if (error.response.status === 401) {
      dispatch(errorEmployeeLogin({}));
      localStorage.removeItem('jwtToken');
      setAuthToken(false);
      dispatch(setEmployeeLogin({}));
      window.location.replace('/');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <LayoutProvider>
    <ThemeProvider theme={Theme.default}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </LayoutProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
