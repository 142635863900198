import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu as MenuIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import { Typography, Avatar } from '../Wrappers/Wrappers';
import { logoutEmployee } from '../../actions/employeeActions/employeeLogin';

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext';
import DropDown from '../DropDown';
import store from '../../store';
import { storeSelectedShop } from '../../actions/adminActions/addShop';

function Header(props) {
  const { dispatch } = store;
  var classes = useStyles();
  var history = useHistory();
  var theme = useTheme();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { shopListData } = props;
  // local
  var [currentUser, setCurrentUser] = useState('');
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  const [isSmall, setSmall] = useState(false);
  const [shopList, setShopList] = useState([]);
  const [selectedShop, setSelectedShop] = useState(0);
  const shopName = JSON.parse(localStorage.getItem('userProfile')).shop.name;

  useEffect(function() {
    const { employeeData } = props;
    if (employeeData.isAuthenticated) {
      let empName = employeeData && employeeData.employee && employeeData.employee.name;
      setCurrentUser(empName);
    }
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  useEffect(() => {
    let data = shopListData?.data ?? [];
    const newShopList = data.map((e) => ({
      name: e.name,
      id: e._id,
      cash: e.cashAmount,
    }));
    const storeShop = newShopList[0]?.id ?? 0;
    if (selectedShop.length === 0) {
      setSelectedShop(storeShop);
      dispatch(storeSelectedShop(storeShop));
    } else {
      setSelectedShop(props.storeShop);
    }
    setShopList(newShopList);
  }, [shopListData, props?.storeShop]);

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
    setSmall(isSmallScreen);
  }

  const handleChangeShop = (e) => {
    setSelectedShop(e.target.value);
    dispatch(storeSelectedShop(e.target.value));
  };

  const logoutEmployeeAndCompany = () => {
    const { employeeData, history } = props;
    if (employeeData.isAuthenticated) {
      props.logoutEmployee(history);
    }
  };
  const navigateToProfile = () => {
    props.history.push('/app/profile');
    setProfileMenu(null);
  };
  const navigateToCompanyProfile = () => {
    props.history.push('/app/company-profile');
    setProfileMenu(null);
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(classes.headerMenuButton, classes.headerMenuButtonCollapse)}
        >
          {(!layoutState.isSidebarOpened && isSmall) || (layoutState.isSidebarOpened && !isSmall) ? (
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          {shopName}
        </Typography>
        <div className={classes.grow} />
        <div className={classes.grow} />
        <div className={classes.dropDownContainer}>
          <DropDown
            ItemsArray={shopList}
            handleChange={handleChangeShop}
            selectedItem={selectedShop}
            paddingTop={'10px'}
            paddingBottom={'10px'}
            paddingLeft={'10px'}
            titleNotDisable
            fullWidth
          />
        </div>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <Avatar color={'warning'}>{currentUser.charAt(0).toUpperCase()}</Avatar>
        </IconButton>
        <Typography block variant="body2" style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
          Hi,&nbsp;
          <Typography variant="body2" weight={'bold'}>
            {currentUser}
          </Typography>
        </Typography>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        ></Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {currentUser}
            </Typography>
            {/* {plan ? (
              <Typography
                className={classes.profileMenuLink}
                component='a'
                color='primary'
                href={`https://${website}`}
              >
                {website}
              </Typography>
            ) : (
              ''
            )} */}
          </div>
          {/* {props.employeeData.employee.role === 'employee' ? (
            <MenuItem>
              <Typography
                color='primary'
                // onClick={() => navigateToProfile()}
                className={classes.profileMenuLink}
              >
                Check Profile
              </Typography>
            </MenuItem>
          ) : props.employeeData.employee.role === 'admin' ? (
            ''
          ) : (
            <MenuItem onClick={() => navigateToCompanyProfile()}>
              <Typography color='primary' className={classes.profileMenuLink}>
                Check Profile
              </Typography>
            </MenuItem>
          )} */}
          <MenuItem onClick={() => logoutEmployeeAndCompany()}>
            <Typography className={classes.profileMenuLink} color="primary">
              Sign Out
            </Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => {
  return {
    employeeData: state.employeeData,
    shopListData: state.shopListData.data,
    storeShop: state.addShopData.storeShop,
  };
};

export default connect(mapStateToProps, { logoutEmployee })(Header);
