import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  CircularProgress,
  FormControl,
  Menu,
  Fade,
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  Box,
  Table,
  TableBody,
  Avatar,
} from '@material-ui/core';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import Widget from '../../components/Widget';
import moment from 'moment';
import { Typography } from '../../components/Wrappers';
import useStyles from './styles';
import { withRouter, useHistory } from 'react-router-dom';
import { useTheme, makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { fetchShopList } from '../../actions/adminActions/shopList';
import { fetchDashboardData } from '../../actions/adminActions/dashboardData';
import { fetchTransactionData } from '../../actions/adminActions/transactionData';
import DropDown from '../../components/DropDown';
import { CalendarToday as CalendarIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { lighten } from '@material-ui/core/styles';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './modalStyle.css';
import { tansactionList } from '../../const';

const headCells = [
  {
    id: 'index',
    numeric: true,
    disablePadding: false,
    label: 'INDEX',
  },
  {
    id: 'username',
    numeric: true,
    disablePadding: false,
    label: 'USER NAME',
  },
  { id: 'type', numeric: true, disablePadding: false, label: 'TYPE' },
  { id: 'date', numeric: true, disablePadding: false, label: 'DATE' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'AMOUNT' },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    transactionData,
    history,
    selectedShop,
    startDate,
    endDate,
    startEndDate,
  } = props;

  return (
    <Toolbar className={cn(classes.root)}>
      <Box display={'flex'} className={classes.title}>
        <Typography
          variant='h6'
          color='text'
          colorBrightness={'secondary'}
          id='tableTitle'
          style={{ display: 'flex' }}
          block
        >
          Transaction's Record
        </Typography>
      </Box>

      {transactionData.length > 10 && (
        <Box>
          <Button
            variant='contained'
            color='primary'
            style={{ width: '120px' }}
            onClick={() =>
              history.push({
                pathname: '/app/transactions',
                state: {
                  selectedShop,
                  startDate,
                  endDate,
                  startEndDate,
                },
              })
            }
            className={classes.button}
          >
            View More
          </Button>
        </Box>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  transactionData: PropTypes.array.isRequired,
};

const AdminDashboard = (props) => {
  const classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  const [shopList, setShopList] = React.useState([]);
  const [selectedShop, setSelectedShop] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startEndDate, setStartEndDate] = React.useState('');
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [graphData, setGraphData] = React.useState([]);
  const [transactionData, setTransactionData] = React.useState([]);
  const [error, setError] = React.useState('');

  const employeeData = props.employeeData.employee;

  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const titleStyles = {
    title: {
      textAlign: 'center',
      width: '100%',
      marginBottom: '10px',
    },
  };

  const Text = withStyles(titleStyles)((props) => {
    const { text, classes } = props;
    const [mainText, subText] = text.split('\\n');
    return (
      <div className={classes.title}>
        <Typography component='h3' variant='h5'>
          {mainText}
        </Typography>
        <Typography variant='subtitle1'>{subText}</Typography>
      </div>
    );
  });

  const legendStyles = () => ({
    root: {
      display: 'flex',
      margin: 'auto',
      flexDirection: 'row',
    },
  });
  const legendLabelStyles = (theme) => ({
    label: {
      marginBottom: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
  });
  const legendItemStyles = () => ({
    item: {
      flexDirection: 'column-reverse',
    },
  });

  const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
  );
  const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
  );
  const legendItemBase = ({ classes, ...restProps }) => (
    <Legend.Item className={classes.item} {...restProps} />
  );
  const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
  const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(
    legendLabelBase
  );
  const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(
    legendItemBase
  );

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleApplyButton = () => {
    setAnchorEl(null);

    const selectedDates =
      moment(startDate).format('DD/MM/YYYY') +
      ' - ' +
      moment(endDate).format('DD/MM/YYYY');
    setStartEndDate(selectedDates);

    const updatedEndDate = moment(endDate).endOf('day');
    const startDateTimeStamp = new Date(startDate);
    const endDateTimeStamp = new Date(updatedEndDate);
    const shopId = selectedShop === 1 ? null : selectedShop;
    props.fetchDashboardData(startDateTimeStamp, endDateTimeStamp, shopId);
    if (shopId !== null)
      props.fetchTransactionData(startDateTimeStamp, endDateTimeStamp, shopId);
  };

  // const handleChangeShop = (e) => {
  //   if (selectedShop !== e.target.value) {
  //     setSelectedShop(e.target.value);
  //     const updatedEndDate = moment(endDate).endOf('day');
  //     const startDateTimeStamp = new Date(startDate);
  //     const endDateTimeStamp = new Date(updatedEndDate);
  //     const shopId = e.target.value === 1 ? null : e.target.value;
  //     props.fetchDashboardData(startDateTimeStamp, endDateTimeStamp, shopId);
  //     if (e.target.value !== 1) props.fetchTransactionData(startDateTimeStamp, endDateTimeStamp, shopId);
  //   }
  // };

  useEffect(() => {
    const date = new Date();
    const startDateNew = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDateNew = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setStartDate(startDateNew);
    setEndDate(endDateNew);
    const selectedDates =
      moment(startDateNew.toString()).format('DD/MM/YYYY') +
      ' - ' +
      moment(endDateNew.toString()).format('DD/MM/YYYY');
    setStartEndDate(selectedDates);
    const updatedEndDate = moment(endDateNew).endOf('day');
    const startDateTimeStamp = new Date(startDateNew);
    const endDateTimeStamp = new Date(updatedEndDate);

    props.fetchShopList(employeeData.type);

    if (employeeData.type !== 'user')
      props.fetchDashboardData(startDateTimeStamp, endDateTimeStamp);
  }, []);

  useEffect(() => {
    setError(getErrorMessage(props.shopListError));
  }, [props.shopListError]);

  useEffect(() => {
    setError(getErrorMessage(props.dashboardDataError));
  }, [props.dashboardDataError]);

  useEffect(() => {
    setError(getErrorMessage(props.transactionDataError));
  }, [props.transactionDataError]);

  useEffect(() => {
    let data = props.shopListData.data ? props.shopListData.data : [];
    const newShopList = data.map((e) => ({
      name: e.name,
      id: e._id,
      cash: e.cashAmount,
    }));
    setShopList(newShopList);
    const id = props.storeShop ?? data[0]?._id;
    if (id.length !== 0) {
      setSelectedShop(data[0]?._id);
      props.fetchDashboardData(startDate, endDate, id);
      props.fetchTransactionData(startDate, endDate, id, '', '');
    }
  }, [props.shopListData, props.storeShop]);

  useEffect(() => {
    let data = props.dashboardData.data
      ? props.dashboardData.data.graphData
      : [];
    setGraphData(data);
  }, [props.dashboardData]);

  useEffect(() => {
    let data = props.transactionData.data ? props.transactionData.data : [];
    setTransactionData(data);
  }, [props.transactionData]);

  const itemWidget = [
    {
      label: 'Total Amount',
      amount: props.dashboardData.data
        ? props.dashboardData.data.sellAmount
        : 0,
      color: 'rgb(66, 165, 245)',
    },
    {
      label: 'Total Purchase',
      amount: props.dashboardData.data ? props.dashboardData.data.buyAmount : 0,
      color: 'rgb(255, 112, 67)',
    },
    {
      label: 'Total Profit',
      amount: props.dashboardData.data
        ? props.dashboardData.data.profitAmount
        : 0,
      color: 'rgb(88, 159, 44)',
    },
  ];

  const handleAddBuy = () => {
    const shopObject = shopList.find((shop) => shop.id === selectedShop);
    history.push({
      pathname: '/app/addbuy',
      state:
        employeeData.type === 'user'
          ? {
              subShopId: selectedShop,
            }
          : {
              subShopId: selectedShop,
              cash: shopObject.cash,
            },
    });
  };

  const handleExpense = () => {
    const shopObject = shopList.find((shop) => shop.id === selectedShop);
    history.push({
      pathname: '/app/expense',
      state:
        employeeData.type === 'user'
          ? {
              subShopId: selectedShop,
            }
          : {
              subShopId: selectedShop,
              cash: shopObject.cash,
            },
    });
  };

  return (
    <>
      {props.shopListLoading && (
        <div
          className={classes.loginLoaderContainer}
          style={{
            height: 'calc(100vh - 160px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={50} className={classes.loginLoader} />
        </div>
      )}
      {error && (
        <div
          className={classes.loginLoaderContainer}
          style={{
            height: 'calc(100vh - 160px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography color='secondary' className={classes.errorMessage}>
            {error ? error : ''}
          </Typography>
        </div>
      )}
      {!props.shopListLoading && !error && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.shopListMain}>
              <FormControl className={classes.shopList}>
                {/* <DropDown
                  ItemsArray={shopList}
                  handleChange={handleChangeShop}
                  selectedItem={selectedShop}
                  paddingTop={'10px'}
                  paddingBottom={'10px'}
                  paddingLeft={'10px'}
                  titleNotDisable
                  fullWidth
                /> */}
              </FormControl>
              <FormControl>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleClick}
                  className={classes.calendarButton}
                  endIcon={<CalendarIcon />}
                >
                  {startEndDate}
                </Button>
              </FormControl>
              {anchorEl ? (
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  elevation={0}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  TransitionComponent={Fade}
                  PaperProps={{
                    style: {
                      marginTop: 5,
                      border: '1px solid #d3d4d5',
                    },
                  }}
                >
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <DateRange
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                      rangeColors={'#536DFE'}
                      color={'#536DFE'}
                      showMonthArrow={false}
                    />
                  </div>
                  <div className={classes.btnsView}>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={handleClose}
                      style={{ width: 80, marginRight: 5, color: 'white' }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleApplyButton}
                      style={{ width: 80 }}
                    >
                      Apply
                    </Button>
                  </div>
                </Menu>
              ) : null}
            </div>
          </Grid>
          {itemWidget.map((item, index) => (
            <Grid item lg={4} sm={4} xs={12}>
              <Widget
                title={item.label}
                className={classes.card}
                bodyClass={classes.alignStandaloneElementEmployeeDashboard}
                color={item.color}
                disableWidgetMenu
              >
                <Grid item md={12} xs={12}>
                  {props.dashboardDataLoading ? (
                    <div className={classes.loginLoaderContainer}>
                      <CircularProgress
                        size={20}
                        className={classes.loginLoader}
                      />
                    </div>
                  ) : (
                    <div
                      className={classes.tableWrapper}
                      style={{ color: item.color }}
                    >
                      {item.amount}
                    </div>
                  )}
                </Grid>
              </Widget>
            </Grid>
          ))}

          {selectedShop !== 1 && (
            <Grid item lg={12} sm={12} xs={12}>
              <Widget
                className={classes.card}
                bodyClass={classes.alignStandaloneElementEmployeeDashboard}
                disableWidgetMenu
              >
                <Grid item md={12} xs={12}>
                  <div className={classes.sellButtonWrapper}>
                    <div className={classes.addSellButton}>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ width: '100%' }}
                        onClick={() =>
                          history.push({
                            pathname: '/app/addsell',
                            state: {
                              subShopId: selectedShop,
                            },
                          })
                        }
                        className={classes.button}
                      >
                        Add Sell
                      </Button>
                    </div>
                    <div className={classes.addBuyButton}>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ width: '100%' }}
                        onClick={() => handleExpense()}
                        className={classes.button}
                      >
                        Expense
                      </Button>
                    </div>
                    <div className={classes.addBuyButton}>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ width: '100%' }}
                        onClick={() => handleAddBuy()}
                        className={classes.button}
                      >
                        Add Buy
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Widget>
            </Grid>
          )}
          <Grid item xs={12}>
            <Widget bodyClass={classes.mainChartBody}>
              <ResponsiveContainer width='100%' minWidth={500} height={480}>
                {props.dashboardDataLoading ? (
                  <div className={classes.loginLoaderContainer}>
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  </div>
                ) : (
                  <LineChart
                    data={graphData}
                    width={730}
                    height={250}
                    margin={{
                      top: 5,
                      right: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid stroke='#eee' strokeDasharray='3 3' />
                    <XAxis dataKey='createdAt' />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign='top' />
                    <Line
                      type='monotone'
                      dataKey='sellAmount'
                      stroke='#42a5f5'
                    />
                    <Line
                      type='monotone'
                      dataKey='buyAmount'
                      stroke='#ff7043'
                    />
                  </LineChart>
                )}
              </ResponsiveContainer>
            </Widget>
          </Grid>
          {selectedShop !== 1 && (
            <Grid item xs={12}>
              <Widget noBodyPadding bodyClass={classes.tableWidget}>
                <EnhancedTableToolbar
                  transactionData={transactionData}
                  history={history}
                  selectedShop={selectedShop}
                  startDate={startDate}
                  endDate={endDate}
                  startEndDate={startEndDate}
                />
                <div className={classes.tableWrapper}>
                  <Table
                    className={classes.table}
                    aria-labelledby='tableTitle'
                    aria-label='recent orders'
                  >
                    {props.transactionDataLoading ? (
                      <div className={classes.loginLoaderContainer}>
                        <CircularProgress size={26} />
                      </div>
                    ) : transactionData.length > 0 ? (
                      <>
                        <EnhancedTableHead classes={classes} />
                        <TableBody>
                          {transactionData.map((row, index) => {
                            return (
                              <>
                                {index < 10 && (
                                  <TableRow>
                                    <TableCell
                                      component='th'
                                      // id={labelId}
                                      scope='row'
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell>
                                      <Box
                                        display={'flex'}
                                        flexWrap={'nowrap'}
                                        alignItems={'center'}
                                      >
                                        <Avatar
                                          // alt={row.userId}
                                          // color={
                                          //   row.type === 'BUY'
                                          //     ? 'rgb(255, 112, 67)'
                                          //     : 'rgb(66, 165, 245)'
                                          // }
                                          style={{
                                            marginRight: 10,
                                            backgroundColor: [
                                              'BUY',
                                              'EXPENSE',
                                            ].includes(row.type)
                                              ? 'rgb(255, 112, 67)'
                                              : row.type === 'SELL'
                                              ? 'rgb(66, 165, 245)'
                                              : 'rgb(97, 255, 94)',
                                          }}
                                          notThemeColor
                                        >
                                          {row.userId && row.userId.name
                                            ? row.userId.name[0].toUpperCase()
                                            : row.type[0].toUpperCase()}
                                        </Avatar>
                                        <Typography
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          {row.userId.name}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>
                                      {moment(row.createdAt).format(
                                        'ddd Do MMM YYYY hh:mm A'
                                      )}
                                    </TableCell>

                                    <TableCell>{row.totalAmount}</TableCell>
                                  </TableRow>
                                )}
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    ) : (
                      <p className={classes.emptyTable}>{tansactionList}</p>
                    )}
                  </Table>
                </div>
              </Widget>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shopListData: state.shopListData.data,
    shopListLoading: state.shopListData.loading,
    shopListError: state.shopListData.error,

    dashboardData: state.dashboardData.data,
    dashboardDataLoading: state.dashboardData.loading,
    dashboardDataError: state.dashboardData.error,

    transactionData: state.transactionData.data,
    transactionDataLoading: state.transactionData.loading,
    transactionDataError: state.transactionData.error,

    employeeData: state.employeeData,
    storeShop: state.addShopData.storeShop,
  };
};

const component = withRouter(AdminDashboard);
export default connect(mapStateToProps, {
  fetchShopList,
  fetchDashboardData,
  fetchTransactionData,
})(component);
