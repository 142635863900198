import axios from 'axios';
import { DELETE_BANK } from '../../constants';

export const deleteBank = (bID) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadDeleteBank());
  axios
    .delete(`admin/bank/${bID}`, config)
    .then((res) => dispatch(setDeleteBank(res)))
    .catch((err) => dispatch(errorDeleteBank(err.response)));
};

export const loadDeleteBank = () => {
  return {
    type: DELETE_BANK.LOAD,
  };
};

export const setDeleteBank = (data) => {
  return {
    type: DELETE_BANK.SUCCESS,
    payload: data,
  };
};

export const errorDeleteBank = (error) => {
  return {
    type: DELETE_BANK.FAIL,
    payload: error,
  };
};
