import axios from 'axios';
import { BLOCK_USER } from '../../constants';

export const blockUser = (uID, blockUserData) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadBlockUser());
  axios
    .put(`admin/user/${uID}`, blockUserData, config)
    .then((res) => dispatch(setBlockUser(res)))
    .catch((err) => dispatch(errorBlockUser(err.response)));
};

export const loadBlockUser = () => {
  return {
    type: BLOCK_USER.LOAD,
  };
};

export const setBlockUser = (blockUserData) => {
  return {
    type: BLOCK_USER.SUCCESS,
    payload: blockUserData,
  };
};

export const errorBlockUser = (error) => {
  return {
    type: BLOCK_USER.FAIL,
    payload: error,
  };
};
