import axios from 'axios';
import { ADD_TRANSACTION } from '../../constants';

export const addTransaction = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadTransaction());
  axios
    .post(`user/addTransaction`, data, config)
    .then((res) => {
      dispatch(setTransaction(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorTransaction(err.response)));
};

export const loadTransaction = () => {
  return {
    type: ADD_TRANSACTION.LOAD,
  };
};

export const setTransaction = (data) => {
  return {
    type: ADD_TRANSACTION.SUCCESS,
    payload: data.data,
  };
};

export const errorTransaction = (error) => {
  return {
    type: ADD_TRANSACTION.FAIL,
    payload: error,
  };
};
