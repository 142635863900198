import React, { useEffect } from 'react';
import moment from 'moment';
import { Box, Fade, Grid, CircularProgress, TextField as Input, Select, MenuItem, FormControl } from '@material-ui/core';
import { connect } from 'react-redux';
import DropDown from '../../components/DropDown';
import { useHistory } from 'react-router-dom';
// styles
import useStyles from './style';

//components
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import { ToastContainer } from 'react-toastify';
import { Close as CloseIcon } from '@material-ui/icons';
import { addTransaction } from '../../actions/adminActions/addTransaction';
import { fetchBankList } from '../../actions/adminActions/bankList';
import { fetchBuyerList } from '../../actions/adminActions/buyerList';

const AddCashTransaction = (props) => {
  const classes = useStyles();

  const [from, setFrom] = React.useState('');
  const [typeItems] = React.useState(['SELL', 'BUY', 'EXPENSE']);
  const [type, setType] = React.useState(1);
  const [amount, setAmount] = React.useState(null);
  const [date, setDate] = React.useState();
  const [note, setNote] = React.useState('');
  const [error, setError] = React.useState('');
  const [bankListData, setBankListData] = React.useState([]);
  const [buyerListData, setBuyerListData] = React.useState([]);
  const [buyer, setBuyer] = React.useState('');
  const [bank, setBank] = React.useState('');
  const [bankAmount, setBankAmount] = React.useState('');

  const employeeData = props.employeeData.employee;
  
  const handleChangeType = (e) => {
    if (type !== e.target.value) {
      setType(e.target.value);
    }
  };

  const handleChangeForm = (e) => {
    if (from !== e.target.value) {
      setFrom(e.target.value);
    }
  };

  const handleChangeBank = (e) => {
    if (bank !== e.target.value) {
      setBank(e.target.value);
      const filterData = bankListData.filter((item) => item._id == e.target.value);
      setBankAmount(filterData[0]?.money);
    }
  };

  const handleChangeBuyer = (e) => {
    if (buyer !== e.target.value) {
      setBuyer(e.target.value);
    }
  };

  useEffect(() => {
    let data = props.bankListData.data ? props.bankListData.data : [];
    setBankListData(data);
  }, [props.bankListData]);

  useEffect(() => {
    let data = props.buyerListData.data ? props.buyerListData.data : [];
    setBuyerListData(data);
  }, [props.buyerListData]);

  function shouldButtonDisable() {
    if (!from || !type || !amount || !date) {
      return true;
    }
    return false;
  }

  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    const { from } = props.location.state;
    props.fetchBankList(props.storeShop);
    props.fetchBuyerList(employeeData.type, props.storeShop);
    setFrom(from);
    setError('');
  }, []);

  useEffect(() => {
    setError(getErrorMessage(props.addTransactionError));
  }, [props.addTransactionError]);

  const history = useHistory();

  const createNewTransaction = () => {
    let data = {
      from,
      type,
      amount,
      transactionDate: moment.utc(date).startOf('d'),
      note,
      subShopId: props.storeShop ?? 1,
      ...(from === 'BANK' ? { bankId: bank } : {}),
      ...(type === 'BUY' ? { buyerId: buyer } : {}),
    };

    props.addTransaction(data, history);
  };

  const renderInput = (inputId, inputName, placeholder, type, setValue, value, disabled) => {
    return (
      <Grid container direction={'column'}>
        <Grid item container alignItems={'center'}>
          <Grid item xs={6}>
            <Typography variant={'body1'}>{inputName}</Typography>
          </Grid>
          <Grid xs={6} item>
            <Input
              id={inputId}
              margin="normal"
              placeholder={placeholder}
              value={value}
              type={type}
              disabled={disabled}
              fullWidth
              onChange={(e) => setValue(e.target.value)}
              onFocus={() => setError('')}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={<CloseButton className={classes.notificationCloseButton} />}
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item md={6} xs={12}>
          <Widget title="Add Transaction" bodyClass={classes.horizontalFormTop} disableWidgetMenu inheritHeight>
            <Grid container direction={'column'}>
              <center>
                <Fade in={error} style={!error ? { display: 'none' } : { display: 'inline-block' }}>
                  <Typography color="secondary" className={classes.errorMessage}>
                    {error ? error : ''}
                  </Typography>
                </Fade>
              </center>
              <Grid item container alignItems={'center'}>
                {/* {renderInput('from', 'From', 'from', 'text', setFrom, from, true)} */}
                <Grid container direction={'column'}>
                  <Grid item container alignItems={'center'}>
                    <Grid item xs={6}>
                      <Typography variant={'body1'}>From</Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <div className={classes.codeContainer}>
                        <FormControl className={classes.sellComponent} sx={{ marginBottom: 15 }}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={from}
                            label="Age"
                            onChange={handleChangeForm}
                          >
                            <MenuItem value={'CASH'}>CASH</MenuItem>
                            <MenuItem value={'BANK'}>BANK</MenuItem>
                            <MenuItem value={'CREDIT'}>CREDIT</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction={'column'}>
                  <Grid item container alignItems={'center'}>
                    <Grid item xs={6}>
                      <Typography variant={'body1'}>Type</Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <div className={classes.codeContainer}>
                        <FormControl className={classes.sellComponent}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Age"
                            onChange={handleChangeType}
                          >
                            <MenuItem value={'SELL'}>SELL</MenuItem>
                            <MenuItem value={'BUY'}>BUY</MenuItem>
                            <MenuItem value={'EXPENSE'}>EXPENSE</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {renderInput('amount', 'Amount', 'amount', 'number', setAmount, amount)}
                {renderInput('transactionDate', 'Transaction Date', 'transactionDate', 'date', setDate, date)}
                {renderInput('note', 'Note', 'note', 'text', setNote, note)}
                {from === 'BANK' && (
                  <Grid item container alignItems={'center'} m={20}>
                    <Grid item xs={6}>
                      <Typography variant={'body1'}>Bank</Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <div className={classes.codeContainer}>
                        <FormControl className={classes.sellComponent}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={bank}
                            label="Age"
                            onChange={handleChangeBank}
                          >
                            {bankListData.map((e, i) => {
                              return (
                                <MenuItem key={i} value={e?._id}>
                                  {e?.name ?? ''}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Typography variant={'body1'} className={classes.bankAmount}>
                          {bankAmount ? 'Current Amount - ' + bankAmount : ''}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {type === 'BUY' && (
                  <Grid item container alignItems={'center'} m={20}>
                    <Grid item xs={6}>
                      <Typography variant={'body1'}>Buyer</Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <div className={classes.codeContainer}>
                        <FormControl className={classes.sellComponent}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={buyer}
                            label="Age"
                            onChange={handleChangeBuyer}
                          >
                            {buyerListData.map((e, i) => {
                              return (
                                <MenuItem key={i} value={e?._id}>
                                  {e?.name ?? ''}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                )}

                <Box display={'flex'} alignItems={'center'} style={{ paddingTop: '10px' }}>
                  <Button
                    variant={'contained'}
                    color={'success'}
                    style={{ marginRight: 8 }}
                    disabled={shouldButtonDisable()}
                    onClick={() => createNewTransaction()}
                  >
                    {props.addCategoryLoading ? <CircularProgress size={24} /> : 'Save'}
                  </Button>
                  <Button variant={'contained'} onClick={() => history.goBack()}>
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

const mapStateToProps = (state) => {
  return {
    addTransactionData: state.addTransactionData.data,
    addTransactionLoading: state.addTransactionData.loading,
    addTransactionError: state.addTransactionData.error,
    bankListData: state.bankListData.data,
    buyerListData: state.buyerListData.data,
    storeShop: state.addShopData.storeShop,
    employeeData: state.employeeData,
  };
};

export default connect(mapStateToProps, { fetchBankList, addTransaction, fetchBuyerList })(AddCashTransaction);
