import React, { useEffect } from 'react';
import {
  Box,
  Fade,
  Grid,
  CircularProgress,
  TextField as Input,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// styles
import useStyles from './style';

//components
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import { ToastContainer } from 'react-toastify';
import { Close as CloseIcon } from '@material-ui/icons';
import { inviteBank } from '../../actions/adminActions/addBank';

const CreateBank = (props) => {
  const classes = useStyles();

  const [newBank, setNewBank] = React.useState({
    bankName: '',
    address: '',
    amount: null,
  });

  const [error, setError] = React.useState('');

  function shouldButtonDisable() {
    const { bankName, amount } = newBank;
    if (!bankName || (bankName && bankName.trim().length === 0)) {
      return true;
    }
    let sum = amount
      ? amount
          .toString()
          .split('')
          .filter((item) => item !== '.')
          .map(Number)
          .reduce(function(a, b) {
            return a + b;
          }, 0)
      : 0;
    if (!sum) {
      return true;
    }
    return false;
  }

  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.addBankError));
  }, [props.addBankError]);

  useEffect(() => {
    setError('');
  }, []);

  const history = useHistory();

  const editNewBank = (e) => {
    setNewBank({
      ...newBank,
      [e.target.id]: e.currentTarget.value,
    });
  };

  const createNewBank = () => {
    const data = {
      name: newBank.bankName,
      money: newBank.amount,
    };
    if (
      newBank.address &&
      newBank.address &&
      newBank.address.trim().length !== 0
    ) {
      data.address = newBank.address;
    }
    props.inviteBank(data, history);
  };

  const renderInput = (inputId, inputName, placeholder, type) => {
    return (
      <Grid container direction={'column'}>
        <Grid item container alignItems={'center'}>
          <Grid item xs={6}>
            <Typography variant={'body1'}>{inputName}</Typography>
          </Grid>
          <Grid xs={6} item>
            <Input
              id={inputId}
              margin='normal'
              placeholder={placeholder}
              value={newBank && newBank.name}
              type={type}
              fullWidth
              onChange={(e) => editNewBank(e)}
              onFocus={() => setError('')}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item md={6} xs={12}>
          <Widget
            title='Add Bank'
            bodyClass={classes.horizontalFormTop}
            disableWidgetMenu
            inheritHeight
          >
            <Grid container direction={'column'}>
              <center>
                <Fade
                  in={error}
                  style={
                    !error ? { display: 'none' } : { display: 'inline-block' }
                  }
                >
                  <Typography
                    color='secondary'
                    className={classes.errorMessage}
                  >
                    {error ? error : ''}
                  </Typography>
                </Fade>
              </center>
              <Grid item container alignItems={'center'}>
                {renderInput('bankName', 'Name', 'bank name', 'text')}
                {renderInput('address', 'Address', 'address', 'text')}
                {renderInput('amount', 'Amount', 'amount', 'number')}
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{ paddingTop: '10px' }}
                >
                  <Button
                    variant={'contained'}
                    color={'success'}
                    style={{ marginRight: 8 }}
                    disabled={shouldButtonDisable()}
                    onClick={() => createNewBank()}
                  >
                    {props.addBankLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

const mapStateToProps = (state) => {
  return {
    addBankData: state.addBankData.data,
    addBankLoading: state.addBankData.loading,
    addBankError: state.addBankData.error,
  };
};

export default connect(mapStateToProps, { inviteBank })(CreateBank);
