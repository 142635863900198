import axios from 'axios';
import { ADD_CATEGORY } from '../../constants';

export const inviteCategory = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(addCategory());
  axios
    .post(`admin/addCategory`, data, config)
    .then((res) => {
      dispatch(setCategory(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorCategory(err.response)));
};

export const addCategory = () => {
  return {
    type: ADD_CATEGORY.LOAD,
  };
};

export const setCategory = (data) => {
  return {
    type: ADD_CATEGORY.SUCCESS,
    payload: data.data,
  };
};

export const errorCategory = (error) => {
  return {
    type: ADD_CATEGORY.FAIL,
    payload: error,
  };
};
