import axios from 'axios';
import { DELETE_CATEGORY } from '../../constants';

export const deleteCategory = (cID) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadDeleteCategory());
  axios
    .delete(`admin/category/${cID}`, config)
    .then((res) => dispatch(setDeleteCategory(res)))
    .catch((err) => dispatch(errorDeleteCategory(err.response)));
};

export const loadDeleteCategory = () => {
  return {
    type: DELETE_CATEGORY.LOAD,
  };
};

export const setDeleteCategory = (data) => {
  return {
    type: DELETE_CATEGORY.SUCCESS,
    payload: data,
  };
};

export const errorDeleteCategory = (error) => {
  return {
    type: DELETE_CATEGORY.FAIL,
    payload: error,
  };
};
