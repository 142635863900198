import axios from 'axios';
import { DELETE_TRANSACTION } from '../../constants';

export const deleteTransaction = (tID,sID) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadDeleteTransaction());
  axios
      .delete(`user/transaction/${tID}?subShopId=${sID}`, config)
    .then((res) => dispatch(setDeleteTransaction(res)))
    .catch((err) => dispatch(errorDeleteBank(err.response)));
};

export const loadDeleteTransaction = () => {
  return {
    type: DELETE_TRANSACTION.LOAD,
  };
};

export const setDeleteTransaction = (data) => {
  return {
    type: DELETE_TRANSACTION.SUCCESS,
    payload: data,
  };
};

export const errorDeleteBank = (error) => {
  return {
    type: DELETE_TRANSACTION.FAIL,
    payload: error,
  };
};
