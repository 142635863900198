import React, { useEffect } from 'react';
import {
  Box,
  Fade,
  Grid,
  CircularProgress,
  TextField as Input,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// styles
import useStyles from './style';

//components
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import { ToastContainer } from 'react-toastify';
import { Close as CloseIcon } from '@material-ui/icons';
import { editBank } from '../../actions/adminActions/editBank';

const EditBank = (props) => {
  const classes = useStyles();
  const [bankName, setBankName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [amount, setAmount] = React.useState(null);

  useEffect(() => {
    const { name, address, amount } = props.location.state;
    setBankName(name);
    setAddress(address);
    setAmount(amount);
  }, []);
  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };
  useEffect(() => {
    setError(getErrorMessage(props.editBankDataError));
  }, [props.editBankDataError]);
  const history = useHistory();

  const editBank = () => {
    const { id } = props.location.state;
    const bankData = {};
    if (bankName && bankName !== props.location.state.name) {
      bankData.name = bankName;
    }
    if (
      address &&
      address !== props.location.state.address &&
      address.trim().length !== 0
    ) {
      bankData.address = address;
    }
    if (amount && amount !== props.location.state.amount) {
      bankData.money = amount;
    }
    props.editBank(id, bankData, history);
  };

  function shouldButtonDisable() {
    if (!bankName || (bankName && bankName.trim().length === 0)) {
      return true;
    }
    let sum = amount
      ? amount
          .toString()
          .split('')
          .filter((item) => item !== '.')
          .map(Number)
          .reduce(function(a, b) {
            return a + b;
          }, 0)
      : 0;
    if (!sum) {
      return true;
    }
    return false;
  }

  const renderInput = (
    inputId,
    inputName,
    value,
    setValue,
    placeholder,
    type
  ) => {
    return (
      <Grid item container alignItems={'center'}>
        <Grid item xs={6}>
          <Typography variant={'body1'}>{inputName}</Typography>
        </Grid>
        <Grid xs={6} item>
          <Input
            id={inputId}
            margin='normal'
            placeholder={placeholder}
            value={value}
            type={type}
            fullWidth
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setError('')}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item md={6} xs={12}>
          <Widget
            title='Edit Bank'
            bodyClass={classes.horizontalFormTop}
            disableWidgetMenu
            inheritHeight
          >
            <Grid container direction={'column'}>
              <center>
                <Fade
                  in={error}
                  style={
                    !error ? { display: 'none' } : { display: 'inline-block' }
                  }
                >
                  <Typography
                    color='secondary'
                    className={classes.errorMessage}
                  >
                    {error ? error : ''}
                  </Typography>
                </Fade>
              </center>
              <Grid container direction={'column'}>
                <Grid item container alignItems={'center'}>
                  {renderInput(
                    'bankName',
                    'Name',
                    bankName,
                    setBankName,
                    'bank name',
                    'text'
                  )}
                  {renderInput(
                    'address',
                    'Address',
                    address,
                    setAddress,
                    'address',
                    'text'
                  )}
                  {renderInput(
                    'amount',
                    'Amount',
                    amount,
                    setAmount,
                    'amount',
                    'number'
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{ paddingTop: '10px' }}
                >
                  <Button
                    variant={'contained'}
                    color={'success'}
                    style={{ marginRight: 8 }}
                    onClick={() => editBank()}
                    disabled={shouldButtonDisable()}
                  >
                    {props.editBankDataLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Edit'
                    )}
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

const mapStateToProps = (state) => {
  return {
    editBankData: state.editBankData.data,
    editBankDataLoading: state.editBankData.loading,
    editBankDataError: state.editBankData.error,
  };
};

export default connect(mapStateToProps, { editBank })(EditBank);
