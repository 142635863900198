import axios from 'axios';
import { BANK_LIST } from '../../constants';

export const fetchBankList = (subShopId, assign) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadBankList());
  let path;
  if(assign && subShopId){
    path = `/user/unAssignedBank?subShopId=${subShopId}`
  }
  else if (subShopId) path = `user/shopBankList?subShopId=${subShopId}`;
  else path = 'admin/bank';
  axios
    .get(path, config)
    .then((res) => dispatch(setBankList(res)))
    .catch((err) => dispatch(errorBankList(err.response)));
};

export const loadBankList = () => {
  return {
    type: BANK_LIST.LOAD,
  };
};

export const setBankList = (bankList) => {
  return {
    type: BANK_LIST.SUCCESS,
    payload: bankList.data,
  };
};

export const errorBankList = (error) => {
  return {
    type: BANK_LIST.FAIL,
    payload: error,
  };
};
