import axios from 'axios';
import { DELETE_USER } from '../../constants';

export const deleteUser = (uID) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadDeleteUser());
  axios
    .delete(`admin/user/${uID}`, config)
    .then((res) => dispatch(setDeleteUser(res)))
    .catch((err) => dispatch(errorDeleteUser(err.response)));
};

export const loadDeleteUser = () => {
  return {
    type: DELETE_USER.LOAD,
  };
};

export const setDeleteUser = (data) => {
  return {
    type: DELETE_USER.SUCCESS,
    payload: data,
  };
};

export const errorDeleteUser = (error) => {
  return {
    type: DELETE_USER.FAIL,
    payload: error,
  };
};
