import axios from 'axios';
import { BUYER_LIST } from '../../constants';

export const fetchBuyerList = (type, subShopId) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadBuyerList());
  let path;
  if (type === 'user') path = `user/buyerList?subShopId=${subShopId}`;
  else path = 'admin/buyer';
  axios
    .get(path, config)
    .then((res) => dispatch(setBuyerList(res)))
    .catch((err) => dispatch(errorBuyerList(err.response)));
};

export const loadBuyerList = () => {
  return {
    type: BUYER_LIST.LOAD,
  };
};

export const setBuyerList = (buyerList) => {
  return {
    type: BUYER_LIST.SUCCESS,
    payload: buyerList.data,
  };
};

export const errorBuyerList = (error) => {
  return {
    type: BUYER_LIST.FAIL,
    payload: error,
  };
};
