import axios from 'axios';
import { SHOP_REGISTER } from '../../constants';
import setAuthToken from '../../Utils/setAuthToken';
import jwt from 'jsonwebtoken';
import {
  setEmployeeLogin,
} from '../employeeActions/employeeLogin';

export const registerShop = (shopData, setLoad) => (dispatch) => {
  dispatch(loadRegister());
  axios
    .post('/signup', shopData)
    .then((res) => {
      let { token } = res.data.data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      const decoded = jwt.decode(token);

      if (res.data.user) {
        dispatch(setEmployeeLogin(decoded));
      } else if (res.data.data.user) {
        let { user } = res.data.data;
        localStorage.setItem('userProfile', JSON.stringify(user));
        dispatch(setEmployeeLogin(decoded));
      }
      setLoad(false);
    })
    .catch((err) => {
      setLoad(false);
      dispatch(errorRegister(err.response));
    });
};

export const loadRegister = () => {
  return {
    type: SHOP_REGISTER.LOAD,
  };
};

export const errorRegister = (error) => {
  return {
    type: SHOP_REGISTER.FAIL,
    payload: error,
  };
};
