import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// components
import Layout from './Layout';
import PrivateRoute from '../components/Routes/PrivateRoute';
import PublicRoute from '../components/Routes/PublicRoute';
import setAuthToken from '../Utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import {
  setEmployeeLogin,
  logoutEmployee,
} from '../actions/employeeActions/employeeLogin';
// pages
import Login from '../pages/login';

import { Provider } from 'react-redux';
import store from '../../src/store/index';
import Signup from '../pages/signup';
import { Helmet } from 'react-helmet';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setEmployeeLogin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutEmployee());
    window.location.href = './';
  }
}

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Helmet titleTemplate='Shope | %s' defaultTitle='Bharat Venture Group'>
          <meta
            name='TeamHR'
            content='Monitor the presence of your employees with this simple web application. It’s easy! Either on your computer or on-the-go with your smartphone, you can now track your working time anywhere, at any time thanks to the cloud. '
          />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, shrink-to-fit=no'
          />
          <meta name='theme-color' content='#000000' />
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
          />
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path='/app'
              render={() => <Redirect to='/app/transactions' />}
            />
            <PrivateRoute path='/app' component={Layout} />
            <PublicRoute path='/' component={Login} />
            <Route exact path='/signup' component={Signup} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}
