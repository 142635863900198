import axios from 'axios';
import { ADD_BUYER } from '../../constants';

export const inviteBuyer = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(addBuyer());
  axios
    .post(`admin/addBuyer`, data, config)
    .then((res) => {
      dispatch(setBuyer(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorBuyer(err.response)));
};

export const addBuyer = () => {
  return {
    type: ADD_BUYER.LOAD,
  };
};

export const setBuyer = (data) => {
  return {
    type: ADD_BUYER.SUCCESS,
    payload: data.data,
  };
};

export const errorBuyer = (error) => {
  return {
    type: ADD_BUYER.FAIL,
    payload: error,
  };
};
