import React, { useEffect } from 'react';
import {
  Box,
  Fade,
  Grid,
  CircularProgress,
  TextField as Input,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// styles
import useStyles from './style';

//components
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import { ToastContainer } from 'react-toastify';
import { Close as CloseIcon } from '@material-ui/icons';
import { inviteBuyer } from '../../actions/adminActions/addBuyer';

const CreateBuyer = (props) => {
  const classes = useStyles();

  const [newBuyer, setNewBuyer] = React.useState({
    buyerName: '',
    address: '',
  });

  const [error, setError] = React.useState('');

  function shouldButtonDisable() {
    const { buyerName } = newBuyer;
    if (!buyerName || (buyerName && buyerName.trim().length === 0)) {
      return true;
    }
    return false;
  }

  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.addBuyerError));
  }, [props.addBuyerError]);

  useEffect(() => {
    setError('');
  }, []);

  const history = useHistory();

  const editNewBuyer = (e) => {
    setNewBuyer({
      ...newBuyer,
      [e.target.id]: e.currentTarget.value,
    });
  };

  const createNewBuyer = () => {
    const data = {
      name: newBuyer.buyerName,
    };
    if (
      newBuyer.address &&
      newBuyer.address &&
      newBuyer.address.trim().length !== 0
    ) {
      data.address = newBuyer.address;
    }
    props.inviteBuyer(data, history);
  };

  const renderInput = (inputId, inputName, placeholder, type) => {
    return (
      <Grid container direction={'column'}>
        <Grid item container alignItems={'center'}>
          <Grid item xs={6}>
            <Typography variant={'body1'}>{inputName}</Typography>
          </Grid>
          <Grid xs={6} item>
            <Input
              id={inputId}
              margin='normal'
              placeholder={placeholder}
              value={newBuyer && newBuyer.name}
              type={type}
              fullWidth
              onChange={(e) => editNewBuyer(e)}
              onFocus={() => setError('')}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item md={6} xs={12}>
          <Widget
            title='Add Buyer'
            bodyClass={classes.horizontalFormTop}
            disableWidgetMenu
            inheritHeight
          >
            <Grid container direction={'column'}>
              <center>
                <Fade
                  in={error}
                  style={
                    !error ? { display: 'none' } : { display: 'inline-block' }
                  }
                >
                  <Typography
                    color='secondary'
                    className={classes.errorMessage}
                  >
                    {error ? error : ''}
                  </Typography>
                </Fade>
              </center>
              <Grid item container alignItems={'center'}>
                {renderInput('buyerName', 'Name', 'buyer name', 'text')}
                {renderInput('address', 'Address', 'address', 'text')}
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{ paddingTop: '10px' }}
                >
                  <Button
                    variant={'contained'}
                    color={'success'}
                    style={{ marginRight: 8 }}
                    disabled={shouldButtonDisable()}
                    onClick={() => createNewBuyer()}
                  >
                    {props.addBuyerLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

const mapStateToProps = (state) => {
  return {
    addBuyerData: state.addBuyerData.data,
    addBuyerLoading: state.addBuyerData.loading,
    addBuyerError: state.addBuyerData.error,
  };
};

export default connect(mapStateToProps, { inviteBuyer })(CreateBuyer);
