import {
  Box,
  Breadcrumbs,
  Fade,
  FormControl,
  Grid,
  Input,
} from '@material-ui/core';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import React from 'react';
import DropDown from '../../components/DropDown';
import Widget from '../../components/Widget/Widget';

export default function AddSell1(props) {
  const {
    classes,
    error,
    sells,
    categoryListData,
    handleChangeCategory,
    handleAmountInput,
    setError,
    handleOnDeleteCategory,
    handleAddCategory,
    totalAmount,
    handleNext,
    handleCategoryListData,
  } = props;
  return (
    <div>
      <Grid item lg={12} sm={12} xs={12}>
        <Widget
          className={classes.card}
          bodyClass={classes.alignStandaloneElementEmployeeDashboard}
          disableWidgetMenu
          style={{ height: 'calc(100vh - 250px)' }}
        >
          <div
            style={{
              height: 'calc(100vh - 285px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <center>
              <Fade
                in={error}
                style={
                  !error ? { display: 'none' } : { display: 'inline-block' }
                }
              >
                <Typography color='secondary' className={classes.errorMessage}>
                  {error ? error : ''}
                </Typography>
              </Fade>
            </center>
            {sells.map((sell, index) => (
              <Grid item md={12} xs={12} key={index}>
                <div className={classes.tableWrapper}>
                  <Grid container spacing={3}>
                    <Grid item lg={3} sm={3} xs={12}>
                      <FormControl className={classes.sellComponent}>
                        <DropDown
                          title='Select Category'
                          ItemsArray={handleCategoryListData(index)}
                          handleChange={(e) => handleChangeCategory(e, index)}
                          selectedItem={sell.categoryId}
                          paddingTop={'10px'}
                          paddingBottom={'10px'}
                          paddingLeft={'10px'}
                          fullWidth
                          isDisable={index < sells.length - 1}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={3} xs={12}>
                      <FormControl className={classes.sellComponent}>
                        <Input
                          id={`amount${index}`}
                          margin='normal'
                          placeholder={'Amount'}
                          value={sell.amount}
                          type={'number'}
                          fullWidth
                          onChange={(e) => handleAmountInput(e, index)}
                          onFocus={() => setError('')}
                          disabled={index < sells.length - 1}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={6}
                      xs={12}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <FormControl className={classes.deleteSellComponent}>
                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={() => handleOnDeleteCategory(index)}
                          className={classes.button}
                        >
                          Cancel
                        </Button>
                      </FormControl>
                    </Grid>
                    <div className={classes.categoryDivider} />
                  </Grid>
                </div>
              </Grid>
            ))}
            {categoryListData.length !== sells.length && (
              <Grid item md={12} xs={12}>
                <div className={classes.tableWrapper}>
                  <Grid container spacing={3}>
                    <Grid item lg={3} sm={3} xs={12}>
                      <Grid item md={12} xs={12}>
                        <Box display='flex' alignItems='center'>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => handleAddCategory()}
                            className={classes.button}
                            style={{ width: '100%' }}
                          >
                            Add Category
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </div>
        </Widget>
      </Grid>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
        style={{ marginTop: 10 }}
      >
        <Grid container spacing={3}>
          <Grid item lg={3} sm={3} xs={12}></Grid>
          <Grid item lg={6} sm={6} xs={12} style={{ display: 'flex' }}>
            <Box
              display='flex'
              alignItems='center'
              className={classes.sell1BottomTotal}
            >
              <Breadcrumbs aria-label='breadcrumb'>
                <Typography variant='h6' style={{ paddingLeft: '5px' }}>
                  Total : {totalAmount}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
          <Grid item lg={3} sm={3} xs={12}>
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
              <div className={classes.sell1NextButton}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => handleNext()}
                  className={classes.button}
                  style={{ width: '100%' }}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Widget>
    </div>
  );
}
