import axios from 'axios';

import { ASSIGN_SHOP } from '../../constants';

export const assignShop = (uID, shopData, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadAssignShop());
  axios
    .post(`admin/user/${uID}/assignedShop`, shopData, config)
    .then((res) => {
      dispatch(setAssignShop(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorAssignShop(err.response)));
};

export const loadAssignShop = () => {
  return {
    type: ASSIGN_SHOP.LOAD,
  };
};

export const setAssignShop = (data) => {
  return {
    type: ASSIGN_SHOP.SUCCESS,
    payload: data.data,
  };
};

export const errorAssignShop = (data) => {
  return {
    type: ASSIGN_SHOP.FAIL,
    payload: data,
  };
};
