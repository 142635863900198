import React, { useEffect } from 'react';
import {
  Box,
  Fade,
  Grid,
  CircularProgress,
  TextField as Input,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// styles
import useStyles from './style';

//components
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import { ToastContainer } from 'react-toastify';
import { Close as CloseIcon } from '@material-ui/icons';
import { editBuyer } from '../../actions/adminActions/editBuyer';

const EditBuyer = (props) => {
  const classes = useStyles();
  const [buyerName, setBuyerName] = React.useState('');
  const [address, setAddress] = React.useState('');

  useEffect(() => {
    const { name, address } = props.location.state;
    setBuyerName(name);
    setAddress(address);
  }, []);
  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };
  useEffect(() => {
    setError(getErrorMessage(props.editBuyerDataError));
  }, [props.editBuyerDataError]);
  const history = useHistory();

  const editBuyer = () => {
    const { id } = props.location.state;
    const buyerData = {};
    if (buyerName && buyerName !== props.location.state.name) {
      buyerData.name = buyerName;
    }
    if (
      address &&
      address !== props.location.state.address &&
      address.trim().length !== 0
    ) {
      buyerData.address = address;
    }
    props.editBuyer(id, buyerData, history);
  };

  function shouldButtonDisable() {
    if (!buyerName || (buyerName && buyerName.trim().length === 0)) {
      return true;
    }
    return false;
  }

  const renderInput = (
    inputId,
    inputName,
    value,
    setValue,
    placeholder,
    type
  ) => {
    return (
      <Grid item container alignItems={'center'}>
        <Grid item xs={6}>
          <Typography variant={'body1'}>{inputName}</Typography>
        </Grid>
        <Grid xs={6} item>
          <Input
            id={inputId}
            margin='normal'
            placeholder={placeholder}
            value={value}
            type={type}
            fullWidth
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setError('')}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item md={6} xs={12}>
          <Widget
            title='Edit Buyer'
            bodyClass={classes.horizontalFormTop}
            disableWidgetMenu
            inheritHeight
          >
            <Grid container direction={'column'}>
              <center>
                <Fade
                  in={error}
                  style={
                    !error ? { display: 'none' } : { display: 'inline-block' }
                  }
                >
                  <Typography
                    color='secondary'
                    className={classes.errorMessage}
                  >
                    {error ? error : ''}
                  </Typography>
                </Fade>
              </center>
              <Grid container direction={'column'}>
                <Grid item container alignItems={'center'}>
                  {renderInput(
                    'buyerName',
                    'Name',
                    buyerName,
                    setBuyerName,
                    'buyer name',
                    'text'
                  )}
                  {renderInput(
                    'address',
                    'Address',
                    address,
                    setAddress,
                    'address',
                    'text'
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{ paddingTop: '10px' }}
                >
                  <Button
                    variant={'contained'}
                    color={'success'}
                    style={{ marginRight: 8 }}
                    onClick={() => editBuyer()}
                    disabled={shouldButtonDisable()}
                  >
                    {props.editBuyerDataLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Edit'
                    )}
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

const mapStateToProps = (state) => {
  return {
    editBuyerData: state.editBuyerData.data,
    editBuyerDataLoading: state.editBuyerData.loading,
    editBuyerDataError: state.editBuyerData.error,
  };
};

export default connect(mapStateToProps, { editBuyer })(EditBuyer);
