import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Breadcrumbs,
  Fade,
  Toolbar,
  IconButton,
  lighten,
  Checkbox,
  Paper,
  FormControlLabel,
} from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { Tooltip } from 'recharts';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { makeStyles, styled } from '@material-ui/styles';
import { connect } from 'react-redux';
import { fetchShopList } from '../../actions/adminActions/shopList';
import { assignShop } from '../../actions/adminActions/assignShop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { shopList } from '../../const';

const AssignShop = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [shopListData, setShopListData] = useState([]);
  const [assignedShop, setAssignedShop] = React.useState([]);

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.shopListError));
  }, [props.shopListError]);

  useEffect(() => {
    setError(getErrorMessage(props.assignShopError));
  }, [props.assignShopError]);

  useEffect(() => {
    setError('');
  }, []);

  useEffect(() => {
    props.fetchShopList();
  }, [props.deleteShopData]);

  useEffect(() => {
    const { assignedShop } = props.location.state;
    let data = props.shopListData.data ? props.shopListData.data : [];
    setShopListData(data);
    setAssignedShop(assignedShop);
  }, [props.shopListData]);

  const handleOnSave = async () => {
    const { id } = props.location.state;
    props.assignShop(id, { shopArray: assignedShop }, history);
  };

  const handleOnChange = (sid) => {
    const currentIndex = assignedShop.indexOf(sid);
    const newChecked = [...assignedShop];

    if (currentIndex === -1) {
      newChecked.push(sid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAssignedShop(newChecked);
  };

  function shouldButtonDisable() {
    let result = assignedShop.every((element) => {
      return props.location.state.assignedShop.includes(element);
    });
    if (
      props.location.state.assignedShop.length > 0 &&
      assignedShop.length === 0
    ) {
      return false;
    }
    if (
      result &&
      props.location.state.assignedShop.length === assignedShop.length
    ) {
      return true;
    }
    return false;
  }

  const useToolbarStyles = makeStyles((theme) => ({
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));

  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Toolbar>
          <Box display={'flex'} className={classes.title}>
            <Typography
              variant='h6'
              color='text'
              colorBrightness={'secondary'}
              id='tableTitle'
              style={{ display: 'flex' }}
              block
            >
              Shop List
            </Typography>
          </Box>
          <Tooltip title='Filter list'>
            <IconButton aria-label='filter list'>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Box display='flex' alignItems='center'>
          <Button
            variant='contained'
            color='success'
            onClick={() => handleOnSave()}
            style={{ marginRight: 8 }}
            className={classes.button}
            disabled={shouldButtonDisable()}
          >
            {props.assignShopLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </div>
    );
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  }));

  return (
    <>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
        >
          <Box display='flex' alignItems='center'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                User management
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box display='flex' alignItems='center'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => history.goBack()}
              className={classes.button}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{ marginTop: 15 }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <Box display='flex' alignItems='center'>
              <Breadcrumbs aria-label='breadcrumb'>
                <Typography variant='h6' style={{ paddingLeft: '5px' }}>
                  <b>Name</b> : {props.location.state.name}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box display='flex' alignItems='center'>
              <Breadcrumbs aria-label='breadcrumb'>
                <Typography variant='h6' style={{ paddingLeft: '5px' }}>
                  <b>Email</b> : {props.location.state.email}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
        </Grid>
      </Widget>

      {/*shop list*/}
      {props.shopListLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Widget noBodyPadding bodyClass={classes.tableWidget}>
            <EnhancedTableToolbar />
            <center>
              <Fade
                in={error}
                style={
                  !error ? { display: 'none' } : { display: 'inline-block' }
                }
              >
                <Typography color='secondary' className={classes.errorMessage}>
                  {error ? error : ''}
                </Typography>
              </Fade>
            </center>
            <div className={classes.tableWrapper}>
              {props.shopListLoading ? (
                <div className={classes.loginLoaderContainer}>
                  <CircularProgress size={26} className={classes.loginLoader} />
                </div>
              ) : shopListData.length > 0 ? (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {shopListData.map((row, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Item
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div>
                            <Grid item container alignItems={'center'}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color='primary'
                                    checked={
                                      assignedShop.indexOf(row._id) !== -1
                                    }
                                    onChange={() => handleOnChange(row._id)}
                                  />
                                }
                                label={
                                  <div
                                    style={{
                                      maxWidth: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {row.name}
                                  </div>
                                }
                              />
                            </Grid>
                          </div>
                        </Item>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <p className={classes.emptyTable}>{shopList}</p>
              )}
            </div>
          </Widget>
        </Grid>
      )}
    </>
  );
};

const component = withRouter(AssignShop);

const mapStateToProps = (state) => {
  return {
    shopListData: state.shopListData.data,
    shopListLoading: state.shopListData.loading,
    shopListError: state.shopListData.error,

    assignShopData: state.assignShopData.data,
    assignShopLoading: state.assignShopData.loading,
    assignShopError: state.assignShopData.error,
  };
};
export default connect(mapStateToProps, {
  fetchShopList,
  assignShop,
})(component);
