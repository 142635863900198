import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import classnames from 'classnames';
// styles
import useStyles from './styles';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';

// pages
import adminDashboard from '../../pages/adminDashboard';
import User from '../../pages/user/User';
// context
import { useLayoutState } from '../../context/LayoutContext';

//Sidebar structure
import structure from '../Sidebar/SidebarStructure';
import adminStructure from '../Sidebar/adminSidebarStructure';
import { connect } from 'react-redux';
import Shop from '../../pages/shop/Shop';
import AddSell from '../../pages/sell/AddSell';
import AddBuy from '../../pages/buy/AddBuy';
import CreateShop from '../../pages/shop/CreateShop';
import EditShop from '../../pages/shop/EditShop';
import Category from '../../pages/category/Category';
import CreateCategory from '../../pages/category/CreateCategory';
import EditCategory from '../../pages/category/EditCategory';
import Bank from '../../pages/bank/Bank';
import CreateBank from '../../pages/bank/CreateBank';
import EditBank from '../../pages/bank/EditBank';
import CreateUser from '../../pages/user/CreateUser';
import EditUser from '../../pages/user/EditUser';
import AssignShop from '../../pages/user/AssignShop';
import AssignBank from '../../pages/shop/AssignBank';
import Buyer from '../../pages/buyer/Buyer';
import CreateBuyer from '../../pages/buyer/CreateBuyer';
import EditBuyer from '../../pages/buyer/EditBuyer';
import Transaction from '../../pages/transaction/Transaction';
import Cash from '../../pages/cash/Cash';
import TransferMoney from '../../pages/transferMoney/TransferMoney';
import Expense from '../../pages/expense/Expense';
import AddTransaction from '../../pages/transaction/AddTransaction';
import AddCashTransaction from '../../pages/transaction/AddCashTransaction';

function Layout(props) {
  const classes = useStyles();
  const employeeData = props.employeeData.employee;
  // global
  var layoutState = useLayoutState();
  return (
    <div className={classes.root}>
      <Header history={props.history} />
      {employeeData.type === 'user' ? (
        <Sidebar structure={structure} />
      ) : (
        <Sidebar structure={adminStructure} />
      )}
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        {employeeData.type === 'user' ? (
          <Switch>
            <Route path='/app/dashboard' component={adminDashboard} />
            <Route path='/app/transactions' component={Transaction} />
            <Route path='/app/transaction/addCashTransaction' component={AddCashTransaction} />
            <Route path='/app/transfer/money' component={TransferMoney} />
            <Route path='/app/expense' component={Expense} />
          </Switch>
        ) : (
          //adimn Layout
          <Switch>
            <Route path='/app/dashboard' component={adminDashboard} />
            <Route path='/app/transactions' component={Transaction} />
            <Route path='/app/cash' component={Cash} />
            <Route path='/app/transaction/create' component={AddTransaction} />
            <Route path='/app/transaction/addCashTransaction' component={AddCashTransaction} />
            <Route path='/app/transfer/money' component={TransferMoney} />
            <Route path='/app/expense' component={Expense} />
            <Route path='/app/shop' exact>
              <Shop />
            </Route>
            <Route path='/app/shop/create' exact>
              <CreateShop />
            </Route>
            <Route path='/app/shop/edit' component={EditShop} exact />

            <Route path='/app/category' exact>
              <Category />
            </Route>
            <Route path='/app/category/create' exact>
              <CreateCategory />
            </Route>
            <Route path='/app/category/edit' component={EditCategory} exact />

            <Route path='/app/buyer' exact>
              <Buyer />
            </Route>
            <Route path='/app/buyer/create' exact>
              <CreateBuyer />
            </Route>
            <Route path='/app/buyer/edit' component={EditBuyer} exact />

            <Route path='/app/bank' exact>
              <Bank />
            </Route>
            <Route path='/app/bank/create' exact>
              <CreateBank />
            </Route>
            <Route path='/app/bank/edit' component={EditBank} exact />
            <Route path='/app/user/create' exact>
              <CreateUser />
            </Route>
            <Route path='/app/user/edit' component={EditUser} exact />
            <Route path='/app/user' exact>
              <User />
            </Route>
            <Route path='/app/user/assign/shop' exact>
              <AssignShop />
            </Route>
            <Route path='/app/shop/assign/bank' exact>
              <AssignBank />
            </Route>
          </Switch>
        )}
      </div>
    </div>
  );
}

const LayoutRoute = withRouter(Layout);

const mapStateToProps = (state) => {
  return {
    employeeData: state.employeeData,
  };
};

export default connect(mapStateToProps)(LayoutRoute);
