import { BANK_LIST } from '../../constants';
const initialState = {
  data: [],
  loading: false,
  error: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case BANK_LIST.LOAD:
      return {
        ...state,
        loading: true
      };
    case BANK_LIST.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case BANK_LIST.FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
