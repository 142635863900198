import React, { useEffect } from 'react';
import {
  Box,
  Fade,
  Grid,
  CircularProgress,
  TextField as Input,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// styles
import useStyles from './style';

//components
import Widget from '../../components/Widget/Widget';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import { ToastContainer } from 'react-toastify';
import { Close as CloseIcon } from '@material-ui/icons';
import { editShop } from '../../actions/adminActions/editShop';

const EditShop = (props) => {
  const classes = useStyles();
  const [shopName, setShopName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [cash, setCash] = React.useState(null);

  useEffect(() => {
    const { name, address, cash } = props.location.state;
    setShopName(name);
    setAddress(address);
    setCash(cash);
  }, []);
  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };
  useEffect(() => {
    setError(getErrorMessage(props.editShopDataError));
  }, [props.editShopDataError]);
  const history = useHistory();

  const editShop = () => {
    const { id } = props.location.state;
    const shopData = {};
    if (shopName && shopName !== props.location.state.name) {
      shopData.name = shopName;
    }
    if (
      address &&
      address !== props.location.state.address &&
      address.trim().length !== 0
    ) {
      shopData.address = address;
    }
    if (cash && cash !== props.location.state.cash) {
      shopData.cashAmount = cash;
    }
    props.editShop(id, shopData, history);
  };

  function shouldButtonDisable() {
    if (!shopName || (shopName && shopName.trim().length === 0)) {
      return true;
    }
    let sum = cash
      ? cash
          .toString()
          .split('')
          .filter((item) => item !== '.')
          .map(Number)
          .reduce(function(a, b) {
            return a + b;
          }, 0)
      : 0;
    if (!sum) {
      return true;
    }
    return false;
  }

  const renderInput = (
    inputId,
    inputName,
    value,
    setValue,
    placeholder,
    type
  ) => {
    return (
      <Grid item container alignItems={'center'}>
        <Grid item xs={6}>
          <Typography variant={'body1'}>{inputName}</Typography>
        </Grid>
        <Grid xs={6} item>
          <Input
            id={inputId}
            margin='normal'
            placeholder={placeholder}
            value={value}
            type={type}
            fullWidth
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setError('')}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item md={6} xs={12}>
          <Widget
            title='Edit Shop'
            bodyClass={classes.horizontalFormTop}
            disableWidgetMenu
            inheritHeight
          >
            <Grid container direction={'column'}>
              <center>
                <Fade
                  in={error}
                  style={
                    !error ? { display: 'none' } : { display: 'inline-block' }
                  }
                >
                  <Typography
                    color='secondary'
                    className={classes.errorMessage}
                  >
                    {error ? error : ''}
                  </Typography>
                </Fade>
              </center>
              <Grid container direction={'column'}>
                <Grid item container alignItems={'center'}>
                  {renderInput(
                    'shopName',
                    'Name',
                    shopName,
                    setShopName,
                    'shop name',
                    'text'
                  )}
                  {renderInput(
                    'address',
                    'Address',
                    address,
                    setAddress,
                    'address',
                    'text'
                  )}
                  {renderInput('cash', 'Cash', cash, setCash, 'cash', 'number')}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{ paddingTop: '10px' }}
                >
                  <Button
                    variant={'contained'}
                    color={'success'}
                    style={{ marginRight: 8 }}
                    onClick={() => editShop()}
                    disabled={shouldButtonDisable()}
                  >
                    {props.editShopDataLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Edit'
                    )}
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

const mapStateToProps = (state) => {
  return {
    editShopData: state.editShopData.data,
    editShopDataLoading: state.editShopData.loading,
    editShopDataError: state.editShopData.error,
  };
};

export default connect(mapStateToProps, { editShop })(EditShop);
