import { ADD_SELL } from '../../constants';
const initialState = {
  data: [],
  loading: false,
  error: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_SELL.LOAD:
      return {
        ...state,
        loading: true,
      };
    case ADD_SELL.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ADD_SELL.FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
