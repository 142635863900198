import axios from 'axios';
import { ADD_SHOP, STORE_SLEETED_SHOP } from '../../constants';

export const inviteShop = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(addShop());
  axios
    .post(`admin/addSubShop`, data, config)
    .then((res) => {
      dispatch(setShop(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorShop(err.response)));
};

export const addShop = () => {
  return {
    type: ADD_SHOP.LOAD,
  };
};

export const setShop = (data) => {
  return {
    type: ADD_SHOP.SUCCESS,
    payload: data.data,
  };
};

export const storeSelectedShop = (data) => {
  return {
    type: STORE_SLEETED_SHOP.SUCCESS,
    payload: data,
  };
};

export const errorShop = (error) => {
  return {
    type: ADD_SHOP.FAIL,
    payload: error,
  };
};
