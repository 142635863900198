import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PublicRoute = ({ component, employeeData, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        employeeData.isAuthenticated ? (
          <Redirect to={'/app/transactions'} />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    employeeData: state.employeeData,
  };
};

export default connect(mapStateToProps)(PublicRoute);
