import React, { useEffect, useState } from 'react';
import { Grid, Box, Breadcrumbs } from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import { Typography } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import Expense1 from './Expense1';
import { fetchBankList } from '../../actions/adminActions/bankList';
import { inviteExpense } from '../../actions/adminActions/addExpense';

const Expense = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [debitInBank, setDebitInBank] = useState([]);
  const [bankListData, setBankListData] = useState([]);
  const [debitInCash, setDebitInCash] = useState(null);
  const [bankTotalAmount, setBankTotalAmount] = React.useState(0);
  const [bankCaseTotalAmount, setBankCaseTotalAmount] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState();
  const [reason, setReason] = React.useState('');
  const theme = useTheme();

  const employeeData = props.employeeData.employee;

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    const { subShopId } = props.location.state;
    setError('');
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setSelectedDate(now.toISOString().slice(0, 16));
    props.fetchBankList(subShopId);
  }, []);

  useEffect(() => {
    let data = props.bankListData.data ? props.bankListData.data : [];
    const newBankList = data.map((e) =>
      employeeData.type === 'user'
        ? {
            name: e.name,
            id: e._id,
          }
        : {
            money: e.money,
            name: e.name,
            id: e._id,
          }
    );
    setBankListData(newBankList);
  }, [props.bankListData]);

  const handleAddBank = () => {
    if (debitInBank.length === 0) {
      setDebitInBank([...debitInBank, { bankId: '1', amount: '' }]);
      setError('');
    } else {
      let newBanks = [...debitInBank];
      newBanks.forEach((bank, index) => {
        let tempBanks = { ...newBanks[index] };
        let sum = bank.amount
          ? bank.amount
              .toString()
              .split('')
              .filter((item) => item !== '.')
              .map(Number)
              .reduce(function(a, b) {
                return a + b;
              }, 0)
          : 0;
        tempBanks.amount = sum;
        newBanks[index] = tempBanks;
      });
      const filteredBanks = newBanks.filter(
        (bank, index) => bank.bankId === '1' || !bank.amount
      );
      if (filteredBanks.length === 0) {
        const filteredMoneyBanks = debitInBank.filter(
          (bank, index) =>
            parseFloat(bank.amount) > parseFloat(bank.money) && bank
        );
        if (filteredMoneyBanks.length === 0 || employeeData.type === 'user') {
          setDebitInBank([...debitInBank, { bankId: '1', amount: '' }]);
          setError('');
        } else {
          setError('Amount not sufficient in bank!');
        }
      } else setError('Please enter bank or amount');
    }
  };
  const handleBankAmountInput = (e, index) => {
    let newBanks = [...debitInBank];
    let tempBanks = { ...newBanks[index] };
    tempBanks.amount = e.target.value;
    newBanks[index] = tempBanks;
    setDebitInBank(newBanks);
  };

  useEffect(() => {
    const amount =
      debitInBank.length > 0
        ? debitInBank.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;
    setBankTotalAmount(amount);
  }, [debitInBank]);

  useEffect(() => {
    const amount =
      debitInBank.length > 0
        ? debitInBank.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;

    setBankCaseTotalAmount(amount + parseFloat(debitInCash ? debitInCash : 0));
  }, [debitInBank, debitInCash]);

  const handleChangeBank = (e, index) => {
    let newBanks = [...debitInBank];
    let tempBanks = { ...newBanks[index] };
    if (tempBanks.bankId !== e.target.value) {
      tempBanks.bankId = e.target.value;
      const money = bankListData.filter((bank) => bank.id === e.target.value);
      tempBanks.money = money[0].money;
      newBanks[index] = tempBanks;
      setDebitInBank(newBanks);
      setError('');
    }
  };

  const handleOnDeleteBank = (bankIndex) => {
    const filteredBanks = debitInBank.filter(
      (bank, index) => index !== bankIndex
    );
    setDebitInBank(filteredBanks);
    setError('');
  };

  useEffect(() => {
    setError(getErrorMessage(props.bankListError));
  }, [props.bankListError]);

  useEffect(() => {
    setError(getErrorMessage(props.addExpenseError));
  }, [props.addExpenseError]);

  const handleSubmit = () => {
    let newBanks = [...debitInBank];
    newBanks.forEach((bank, index) => {
      let tempBanks = { ...newBanks[index] };
      let sum = bank.amount
        ? bank.amount
            .toString()
            .split('')
            .filter((item) => item !== '.')
            .map(Number)
            .reduce(function(a, b) {
              return a + b;
            }, 0)
        : 0;
      tempBanks.amount = sum;
      newBanks[index] = tempBanks;
    });
    const filteredBanks = newBanks.filter(
      (bank, index) => bank.bankId === '1' || !bank.amount
    );
    const date = new Date(selectedDate);
    if (employeeData.type === 'user') {
      if (filteredBanks.length === 0) {
        const { subShopId } = props.location.state;
        let newDebitInBank = debitInBank.map(({ bankId, amount }) => ({
          bankId,
          amount,
        }));
        const data = {
          subShopId,
          debitInCash: debitInCash ? debitInCash : 0,
          debitInBank: newDebitInBank,
          reason,
          date: date.toISOString(),
        };
        props.inviteExpense(data, history);
      } else setError('Please enter bank or amount');
    } else {
      if (filteredBanks.length === 0) {
        const filteredMoneyBanks = debitInBank.filter(
          (bank, index) =>
            parseFloat(bank.amount) > parseFloat(bank.money) && bank
        );
        if (filteredMoneyBanks.length === 0) {
          const { cash } = props.location.state;
          const dabitCash = debitInCash ? debitInCash : 0;
          if (parseFloat(dabitCash) <= parseFloat(cash)) {
            const { subShopId } = props.location.state;
            let newDebitInBank = debitInBank.map(({ bankId, amount }) => ({
              bankId,
              amount,
            }));
            const data = {
              subShopId,
              debitInCash: debitInCash ? debitInCash : 0,
              reason,
              debitInBank: newDebitInBank,
              date: date.toISOString(),
            };
            props.inviteExpense(data, history);
          } else {
            setError('Amount not sufficient in cash!');
          }
        } else {
          setError('Amount not sufficient in bank!');
        }
      } else setError('Please enter bank or amount');
    }
  };

  const handleBankListData = (bankIndex) => {
    if (bankIndex === 0) return bankListData;
    else {
      const tempBankList = debitInBank.filter(
        (bank, index) => index < bankIndex && bank.bankId
      );
      let newBankList = bankListData.filter((bank) => {
        return (
          tempBankList.filter((ban) => {
            return ban.bankId == bank.id;
          }).length == 0
        );
      });
      return newBankList;
    }
  };

  return (
    <>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
        >
          <Box display='flex' alignItems='center'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                Expense
              </Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
      </Widget>
      {props.bankListLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Expense1
          classes={classes}
          error={error}
          debitInBank={debitInBank}
          handleChangeBank={handleChangeBank}
          handleBankAmountInput={handleBankAmountInput}
          setError={setError}
          handleOnDeleteBank={handleOnDeleteBank}
          handleAddBank={handleAddBank}
          bankTotalAmount={bankTotalAmount}
          bankCaseTotalAmount={bankCaseTotalAmount}
          debitInBank={debitInBank}
          bankListData={bankListData}
          setDebitInCash={setDebitInCash}
          debitInCash={debitInCash}
          history={history}
          handleSubmit={handleSubmit}
          addExpenseLoading={props.addExpenseLoading}
          handleBankListData={handleBankListData}
          employeeData={employeeData}
          cash={props.location.state.cash}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          reason={reason}
          setReason={setReason}
        />
      )}
    </>
  );
};

const component = withRouter(Expense);

const mapStateToProps = (state) => {
  return {
    bankListData: state.bankListData.data,
    bankListLoading: state.bankListData.loading,
    bankListError: state.bankListData.error,

    addExpenseData: state.addExpenseData.data,
    addExpenseLoading: state.addExpenseData.loading,
    addExpenseError: state.addExpenseData.error,

    employeeData: state.employeeData,
  };
};
export default connect(mapStateToProps, {
  fetchBankList,
  inviteExpense,
})(component);
