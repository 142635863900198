import axios from 'axios';
import { DELETE_SHOP } from '../../constants';

export const deleteShop = (sID) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadDeleteShop());
  axios
    .delete(`admin/shop/${sID}`, config)
    .then((res) => dispatch(setDeleteShop(res)))
    .catch((err) => dispatch(errorDeleteShop(err.response)));
};

export const loadDeleteShop = () => {
  return {
    type: DELETE_SHOP.LOAD,
  };
};

export const setDeleteShop = (data) => {
  return {
    type: DELETE_SHOP.SUCCESS,
    payload: data,
  };
};

export const errorDeleteShop = (error) => {
  return {
    type: DELETE_SHOP.FAIL,
    payload: error,
  };
};
