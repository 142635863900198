import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Breadcrumbs,
  Table,
  Fade,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableSortLabel,
  Divider,
  Menu,
  Avatar,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import moment from 'moment';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { tansactionList } from '../../const';
import DropDown from '../../components/DropDown';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './modalStyle.css';
import { CalendarToday as CalendarIcon } from '@material-ui/icons';
import { fetchShopList } from '../../actions/adminActions/shopList';
import { fetchTransactionData } from '../../actions/adminActions/transactionData';
import { deleteTransaction } from '../../actions/adminActions/deleteTransaction';

const allForm = [
  { id: 'ALL', name: 'ALL FROM' },
  { id: 'CASH', name: 'CASH' },
  { id: 'BANK', name: 'BANK' },
  { id: 'CREDIT', name: 'CREDIT' },
  { id: 'CASH_TO_BANK', name: 'CASH TO BANK' },
];
const allType = [
  { id: 'ALL', name: 'ALL TYPE' },
  { id: 'SELL', name: 'SELL' },
  { id: 'BUY', name: 'BUY' },
  { id: 'EXPENSE', name: 'EXPENSE' },
  { id: 'TRANSFER', name: 'TRANSFER' },
];

const Transaction = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('index');
  const [selectedA, setSelectedA] = React.useState([]);
  const [pageA, setPageA] = React.useState(0);
  const [rowsPerPageA, setRowsPerPageA] = React.useState(50);
  const [shopList, setShopList] = React.useState([]);
  const [selectedShop, setSelectedShop] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startEndDate, setStartEndDate] = React.useState('SELECT DATES');
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  const [transactionData, setTransactionData] = React.useState([]);
  const [from, setFrom] = React.useState('ALL');
  const [type, setType] = React.useState('ALL');

  const theme = useTheme();

  const employeeData = props.employeeData.employee;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleApplyButton = () => {
    setAnchorEl(null);

    const selectedDates = moment(startDate).format('DD/MM/YYYY') + ' - ' + moment(endDate).format('DD/MM/YYYY');
    setStartEndDate(selectedDates);
  };

  const handleChangeShop = (e) => {
    if (selectedShop !== e.target.value) {
      setSelectedShop(e.target.value);
    }
  };

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    if (selectedShop !== props?.storeShop) {
      setSelectedShop(props?.storeShop);
    }
  }, [props.storeShop]);

  useEffect(() => {
    setTimeout(() => {
      setError('');
    }, 1000);
  }, [error]);

  useEffect(() => {
    setError('');
    if (props.location.state) {
      const { selectedShop, startDate, endDate, startEndDate } = props.location.state;
      setSelectedShop(selectedShop);
      setStartEndDate(startEndDate);
      setStartDate(startDate);
      setEndDate(endDate);
      const startDateTimeStamp = new Date(startDate);
      const endDateTimeStamp = new Date(moment(endDate).endOf('day'));
      props.fetchTransactionData(startDateTimeStamp, endDateTimeStamp, selectedShop, '', '');
      // } else {
      //   const date = new Date();
      //   const startDateNew = new Date(date.getFullYear(), date.getMonth(), 1);
      //   const endDateNew = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      //   setStartDate(startDateNew);
      //   setEndDate(endDateNew);
      //   const selectedDates =
      //     moment(startDateNew.toString()).format('DD/MM/YYYY') +
      //     ' - ' +
      //     moment(endDateNew.toString()).format('DD/MM/YYYY');
      //   setStartEndDate(selectedDates);

      // props.fetchTransactionData();
    }
    props.fetchShopList(employeeData.type);
  }, []);

  useEffect(() => {
    let data = props.shopListData.data ? props.shopListData.data : [];
    const newShopList = data.map((e) => ({
      name: e.name,
      id: e._id,
    }));
    setShopList(newShopList);
  }, [props.shopListData]);

  useEffect(() => {
    let data = props.transactionData.data ? props.transactionData.data : [];
    setTransactionData(data);
  }, [props.transactionData]);

  useEffect(() => {
    setError(getErrorMessage(props.shopListError));
  }, [props.shopListError]);

  useEffect(() => {
    setError(getErrorMessage(props.transactionDataError));
  }, [props.transactionDataError]);

  useEffect(() => {
    setError(getErrorMessage(props.deleteTransactionDataError));
  }, [props.deleteTransactionDataError]);

  const headCells = [
    { id: 'createdAt', numeric: true, disablePadding: false, label: 'DATE' },
    { id: 'type', numeric: true, disablePadding: false, label: 'TYPE' },
    {
      id: 'totalAmount',
      numeric: true,
      disablePadding: false,
      label: 'AMOUNT',
    },
    {
      id: 'from',
      numeric: true,
      disablePadding: false,
      label: 'METHOD',
    },
  ];

  useEffect(() => {
    const startDateNew = moment().startOf('month');
    const endDateNew = moment().endOf('month');
    props.fetchTransactionData(startDateNew, endDateNew, props.storeShop, '', '');
  }, [props.storeShop]);

  const handleSubmit = () => {
    const selectedShop = props.storeShop;
    const updatedEndDate = moment(endDate).endOf('day');
    const startDateTimeStamp = startEndDate === 'SELECT DATES' ? null : new Date(startDate);
    const endDateTimeStamp = startEndDate === 'SELECT DATES' ? null : new Date(updatedEndDate);
    const selectForm = from !== 'ALL' ? from : '';
    const selectType = type !== 'ALL' ? type : '';
    props.fetchTransactionData(startDateTimeStamp, endDateTimeStamp, selectedShop, selectForm, selectType);
  };

  const lastWeek = (check) => {
    const endDate = moment();
    const startDate =
      check == 1 ? moment().subtract(7, 'days') : check == 2 ? moment().subtract(15, 'days') : moment().subtract(1, 'months');
    console.log(startDate, endDate);
    props.fetchTransactionData(startDate, endDate, props.storeShop, '', '');
  };

  const handleChangePageA = (event, newPage) => {
    setPageA(newPage);
  };

  const handleChangeRowsPerPageA = (event) => {
    setRowsPerPageA(parseInt(event.target.value, 10));
    setPageA(0);
  };

  const handleRequestSort = (event, property) => {
    const propertyName = property === 'amount' ? 'money' : property;
    const isDesc = orderBy === propertyName && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(propertyName);
  };

  const handleSelectAllClickA = (event) => {
    if (event.target.checked) {
      const newSelecteds = transactionData.map((n) => n.id);
      setSelectedA(newSelecteds);
      return;
    }
    setSelectedA([]);
  };

  function desc(a, b, orderBy) {
    if (orderBy === 'index') {
      if (transactionData.findIndex((value) => value._id === b._id) < transactionData.findIndex((value) => value._id === a._id)) {
        return -1;
      }
      if (transactionData.findIndex((value) => value._id === b._id) > transactionData.findIndex((value) => value._id === a._id)) {
        return 1;
      }
    } else if (orderBy === 'name') {
      if (b.userId[orderBy] < a.userId[orderBy]) {
        return -1;
      }
      if (b.userId[orderBy] > a.userId[orderBy]) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const onDelete = (id, sId) => {
    const r = window.confirm('Do you really want to Delete?');
    if (r === true) {
      if (props.deleteTransaction) {
        props.deleteTransaction(id, sId);
        setTimeout(() => {
          handleSubmit();
        }, 2000);
      }
    }
  };

  function EnhancedTableHead(props) {
    const { classes, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'left' : 'right'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
                style={{
                  whiteSpace: 'nowrap',
                  textTransform: 'uppercase',
                  fontSize: '0.85rem',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? <span className={classes.visuallyHidden}></span> : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const common = (row, index) => {
    let resignationDate = row.resignDate ? moment(row.resignDate).format('DD MMM YYYY') : 'N/A';
    let resign = row.resignDate ? moment(row.resignDate).format('YYYY-MM-DD') : undefined;
    let method = row.from || row.transferType;
    let amount = row.amount || row.totalAmount;
    amount = (['EXPENSE', 'BUY'].includes(row.type)) ? amount : Math.abs(amount);
    return (
      <TableRow>
        <TableCell>{moment(row.transactionDate || row.transferDate).format('DD/MM/YYYY')}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell>{method}</TableCell>
        <Button
          variant={'contained'}
          color={'secondary'}
          style={{ marginRight: 8, marginTop: 10 }}
          onClick={() => {
            onDelete(row._id, props.storeShop);
          }}
        >
          Delete
        </Button>
      </TableRow>
    );
  };

  const handleChangeForm = (e) => {
    if (from !== e.target.value) {
      setFrom(e.target.value);
    }
  };

  const handleChangeType = (e) => {
    if (from !== e.target.value) {
      setType(e.target.value);
    }
  };

  return (
    <>
      {props.shopListLoading ? (
        <div
          className={classes.loginLoaderContainer}
          style={{
            height: 'calc(100vh - 160px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 16,
          }}
        >
          <CircularProgress size={50} />
        </div>
      ) : (
        <Widget disableWidgetMenu inheritHeight className={classes.margin} bodyClass={classes.navPadding}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            wrap={'nowrap'}
            style={{ overflowX: 'auto', paddingTop: 5, paddingBottom: 5 }}
          >
            <div className={classes.toolbarMain}>
              <Box display="flex" alignItems="center">
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography variant="h5" style={{ paddingLeft: '5px' }}>
                    Transaction's Record
                  </Typography>
                </Breadcrumbs>
              </Box>
              <div className={classes.shopListMain}>
                <div className={classes.calendarTop}>
                  <Button
                    // variant='contained'
                    // color='primary'
                    onClick={handleClick}
                    className={classes.calendarButton}
                    endIcon={<CalendarIcon />}
                    style={{
                      backgroundColor: 'white',
                      paddingTop: 7,
                      paddingBottom: 7,
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {startEndDate}
                  </Button>
                </div>
                <div className={classes.dropDownContainer}>
                  <DropDown
                    ItemsArray={allForm}
                    handleChange={handleChangeForm}
                    selectedItem={from}
                    paddingTop={'10px'}
                    paddingBottom={'10px'}
                    paddingLeft={'10px'}
                    titleNotDisable
                    fullWidth
                  />
                </div>
                <div className={classes.dropDownContainer}>
                  <DropDown
                    ItemsArray={allType}
                    handleChange={handleChangeType}
                    selectedItem={type}
                    paddingTop={'10px'}
                    paddingBottom={'10px'}
                    paddingLeft={'10px'}
                    titleNotDisable
                    fullWidth
                  />
                </div>
                {anchorEl ? (
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    TransitionComponent={Fade}
                    PaperProps={{
                      style: {
                        marginTop: 5,
                        border: '1px solid #d3d4d5',
                      },
                    }}
                  >
                    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                      <DateRange
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        rangeColors={'#536DFE'}
                        color={'#536DFE'}
                        showMonthArrow={false}
                      />
                    </div>
                    <div className={classes.btnsView}>
                      <Button variant="contained" color="secondary" onClick={handleClose} style={{ width: 80, marginRight: 5 }}>
                        Cancel
                      </Button>

                      <Button variant="contained" color="primary" onClick={handleApplyButton} style={{ width: 80 }}>
                        Apply
                      </Button>
                    </div>
                  </Menu>
                ) : null}
                <div className={classes.submitMain}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleSubmit()}
                    className={classes.button}
                    style={{ paddingTop: 7, paddingBottom: 7, width: '100%' }}
                    disabled={selectedShop === 1}
                  >
                    Submit
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    history.push({
                      pathname: '/app/transaction/addCashTransaction',
                      state: {
                        from: 'CASH',
                      },
                    })
                  }
                  className={classes.button}
                  style={{ marginLeft: 5 }}
                >
                  Add Transaction
                </Button>
              </div>
            </div>
          </Grid>
          <br />

          <div className={classes.filterMain}>
            <div className={classes.filterButton}>
              <Button
                variant="contained"
                color="success"
                onClick={() => lastWeek(1)}
                className={classes.button}
                style={{
                  paddingTop: 7,
                  paddingBottom: 7,
                  marginRight: 10,
                  width: '100%',
                }}
              >
                Last 7 days
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => lastWeek(2)}
                className={classes.button}
                style={{
                  paddingTop: 7,
                  paddingBottom: 7,
                  marginRight: 10,
                  width: '100%',
                }}
              >
                Last 15 days
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => lastWeek(3)}
                className={classes.button}
                style={{
                  paddingTop: 7,
                  paddingBottom: 7,
                  marginRight: 10,
                  width: '100%',
                }}
              >
                Last month
              </Button>
            </div>
          </div>
          <Divider />
          {/*bank list*/}
          {props.transactionDataLoading ? (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={{ marginTop: 10 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid item xs={12}>
              <Widget noBoxShadow noBodyPadding bodyClass={classes.tableWidget}>
                <center>
                  <Fade in={error} style={!error ? { display: 'none' } : { display: 'inline-block' }}>
                    <Typography color="secondary" className={classes.errorMessage}>
                      {error ? error : ''}
                    </Typography>
                  </Fade>
                </center>
                <div className={classes.tableWrapper}>
                  <Table className={classes.table} aria-labelledby="tableTitle" aria-label="leave status">
                    {props.transactionDataLoading ? (
                      <div className={classes.loginLoaderContainer}>
                        <CircularProgress size={26} className={classes.loginLoader} />
                      </div>
                    ) : transactionData.length > 0 ? (
                      <>
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClickA}
                          onRequestSort={handleRequestSort}
                          rowCount={transactionData.length}
                        />
                        <TableBody>
                          {stableSort(transactionData, getSorting(order, orderBy))
                            .slice(pageA * rowsPerPageA, pageA * rowsPerPageA + rowsPerPageA)
                            .map((row, index) => common(row, index))}
                        </TableBody>
                      </>
                    ) : (
                      <p className={classes.emptyTable}>{tansactionList}</p>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={transactionData.length}
                    rowsPerPage={rowsPerPageA}
                    page={pageA}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePageA}
                    onChangeRowsPerPage={handleChangeRowsPerPageA}
                  />
                </div>
              </Widget>
            </Grid>
          )}
        </Widget>
      )}
    </>
  );
};

const component = withRouter(Transaction);

const mapStateToProps = (state) => {
  return {
    shopListData: state.shopListData.data,
    shopListLoading: state.shopListData.loading,
    shopListError: state.shopListData.error,

    transactionData: state.transactionData.data,
    transactionDataLoading: state.transactionData.loading,
    transactionDataError: state.transactionData.error,

    deleteTransactionData: state.deleteTransactionData.data,
    deleteTransactionDataLoading: state.deleteTransactionData.loading,
    deleteTransactionDataError: state.deleteTransactionData.error,

    employeeData: state.employeeData,

    storeShop: state.addShopData.storeShop,
  };
};
export default connect(mapStateToProps, {
  fetchShopList,
  fetchTransactionData,
  deleteTransaction,
})(component);
