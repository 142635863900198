import axios from 'axios';
import { ADD_SELL } from '../../constants';

export const inviteSell = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(addSell());
  axios
    .post(`user/addSell`, data, config)
    .then((res) => {
      dispatch(setSell(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorSell(err.response)));
};

export const addSell = () => {
  return {
    type: ADD_SELL.LOAD,
  };
};

export const setSell = (data) => {
  return {
    type: ADD_SELL.SUCCESS,
    payload: data.data,
  };
};

export const errorSell = (error) => {
  return {
    type: ADD_SELL.FAIL,
    payload: error,
  };
};
