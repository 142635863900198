import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Breadcrumbs,
  Fade,
  Divider,
  Menu,
  FormControl,
  Input,
  TextField,
} from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import moment from 'moment';
import { Typography, Button } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import DropDown from '../../components/DropDown';
import { fetchShopList } from '../../actions/adminActions/shopList';
import { selectShop } from '../../const';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { fetchBankList } from '../../actions/adminActions/bankList';
import { inviteTransfer } from '../../actions/adminActions/transferMoney';

const useToolbarStyles = makeStyles((theme) => ({
  transfer_container: {
    width: 500,
    marginTop: 10,
  },
  radio_container: {
    display: 'flex',
    justifyContent: 'center',
  },
  sellComponent: {
    width: '95%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  margin_top: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
    },
  },
  option_component_container: {},
  outer_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  transfer_component_container: {
    margin: '25px 0px',
  },
  transfer_button: {
    width: 120,
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const TransferMoneyContainer = (props) => {
  const classes = useToolbarStyles();

  return (
    <div className={classes.outer_container}>
      <div className={classes.transfer_container}>
        <div className={classes.radio_container}>
          {/* <div className='radio'>
            <input
              type='radio'
              value='option1'
              checked={props.selectedOption === 'option1'}
              onChange={(e) => props.handleOptionChange(e)}
              onFocus={() => props.setError('')}
            />
            <label style={{ marginLeft: '5px' }}>Bank To Cash</label>
          </div> TODO: remove bank to cash */}
          {/* <div className='radio' style={{ marginLeft: '10px' }}>
            <input
              type='radio'
              value='option2'
              checked={props.selectedOption === 'option2'}
              onChange={(e) => props.handleOptionChange(e)}
              onFocus={() => props.setError('')}
            />
            <label style={{ marginLeft: '5px' }}>Cash To Bank</label>
          </div> */}
        </div>
        <div className={classes.transfer_component_container}>
          <div className={classes.option_component_container}>
            {/* {props.selectedOption === 'option1' ? (
              <Grid item container alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.sellComponent}>
                    <DropDown
                      title='Select Bank'
                      ItemsArray={props.bankListData}
                      handleChange={(e) => (
                        props.setSelectedBank(e.target.value),
                        props.setError('')
                      )}
                      selectedItem={props.selectedBank}
                      paddingTop={'10px'}
                      paddingBottom={'10px'}
                      paddingLeft={'10px'}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ justifyContent: 'end', display: 'flex' }}
                >
                  <FormControl
                    className={classNames(
                      classes.sellComponent,
                      classes.margin_top
                    )}
                  >
                    <Input
                      id='money'
                      margin='normal'
                      placeholder={'Amount'}
                      value={props.amount}
                      type={'number'}
                      fullWidth
                      onChange={(e) => props.setAmount(e.target.value)}
                      onFocus={() => props.setError('')}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : ( */}
              <Grid item container alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.sellComponent}>
                    <Input
                      id='money'
                      margin='normal'
                      placeholder={'Amount'}
                      value={props.amount}
                      type={'number'}
                      fullWidth
                      onChange={(e) => props.setAmount(e.target.value)}
                      onFocus={() => props.setError('')}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ justifyContent: 'end', display: 'flex' }}
                >
                  <FormControl
                    className={classNames(
                      classes.sellComponent,
                      classes.margin_top
                    )}
                  >
                    <DropDown
                      title='Select Bank'
                      ItemsArray={props.bankListData}
                      handleChange={(e) => (
                        props.setSelectedBank(e.target.value),
                        props.setError('')
                      )}
                      selectedItem={props.selectedBank}
                      paddingTop={'10px'}
                      paddingBottom={'10px'}
                      paddingLeft={'10px'}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            {/* )} */}
          </div>
          <Grid item container alignItems={'center'}>
            <Grid item xs={12}>
              <FormControl style={{ width: '100%', marginTop: 15 }}>
                <Input
                  id='description'
                  margin='normal'
                  placeholder={'Description'}
                  value={props.description}
                  type={'text'}
                  fullWidth
                  onChange={(e) => props.setDescription(e.target.value)}
                  onFocus={() => props.setError('')}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item xs={12}>
              <FormControl style={{ width: '100%', marginTop: 15 }}>
                <TextField
                  id='datetime-local'
                  type='datetime-local'
                  value={props.selectedDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    props.setSelectedDate(e.target.value);
                  }}
                  onFocus={() => props.setError('')}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item xs={12}>
              <FormControl
                style={{
                  width: '100%',
                  marginTop: 15,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className={classes.transfer_button}>
                  <Button
                    variant='contained'
                    color='success'
                    onClick={() => props.handleTransferMoney()}
                    className={classes.button}
                    style={{ width: '100%' }}
                    disabled={
                      props.selectedBank === 1 ||
                      !props.amount ||
                      !props.description ||
                      !props.selectedDate
                    }
                  >
                    {props.transferMoneyLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Transfer'
                    )}
                  </Button>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const TransferMoney = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [shopList, setShopList] = React.useState([]);
  const [selectedShop, setSelectedShop] = React.useState(1);
  const [selectedBank, setSelectedBank] = React.useState(1);
  const [selectedOption, setselectedOption] = React.useState('option1');
  const [amount, setAmount] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [bankListData, setBankListData] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState();
  const theme = useTheme();

  const employeeData = props.employeeData.employee;

  const handleChangeShop = (e) => {
    if (selectedShop !== e.target.value) {
      setSelectedShop(e.target.value);
      setError('');
      setselectedOption('option1');
      setSelectedBank(1);
      setAmount('');
      setDescription('');
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      setSelectedDate(now.toISOString().slice(0, 16));
    }
  };

  const handleOptionChange = (changeEvent) => {
    setselectedOption(changeEvent.target.value);
    setSelectedBank(1);
    setAmount('');
    setDescription('');
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setSelectedDate(now.toISOString().slice(0, 16));
  };

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError('');
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setSelectedDate(now.toISOString().slice(0, 16));
    props.fetchShopList(employeeData.type);
  }, []);

  useEffect(() => {
    let data = props.shopListData.data ? props.shopListData.data : [];
    const newShopList = data.map((e) => ({
      name: e.name,
      id: e._id,
    }));
    setShopList(newShopList);
    if (newShopList.length > 0) {
      setSelectedShop(newShopList[0].id);
      setSelectedBank(1);
      setAmount('');
      setDescription('');
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      setSelectedDate(now.toISOString().slice(0, 16));
    }
  }, [props.shopListData]);

  useEffect(() => {
    if (selectedShop !== 1) {
      props.fetchBankList(selectedShop);
      setselectedOption('option1');
    }
  }, [selectedShop]);

  useEffect(() => {
    setError(getErrorMessage(props.bankListError));
  }, [props.bankListError]);

  useEffect(() => {
    setError(getErrorMessage(props.transferMoneyError));
  }, [props.transferMoneyError]);

  useEffect(() => {
    let data = props.bankListData.data ? props.bankListData.data : [];
    const newBankList = data.map((e) => ({
      // money: e.money,
      name: e.name,
      id: e._id,
    }));
    setBankListData(newBankList);
  }, [props.bankListData]);

  useEffect(() => {
    setError(getErrorMessage(props.shopListError));
  }, [props.shopListError]);

  const handleTransferMoney = () => {
    const date = new Date(selectedDate);
    const data = {
      subShopId: selectedShop,
      totalAmount: amount,
      transferType: 'CASH_TO_BANK',
      bankId: selectedBank,
      date: date.toISOString(),
      description,
    };
    props.inviteTransfer(data, history);
  };

  return (
    <>
      {props.shopListLoading ? (
        <div
          className={classes.loginLoaderContainer}
          style={{
            height: 'calc(100vh - 160px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 16,
          }}
        >
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <Widget
            disableWidgetMenu
            inheritHeight
            className={classes.margin}
            bodyClass={classes.navPadding}
          >
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              wrap={'nowrap'}
              style={{ overflowX: 'auto', paddingTop: 5, paddingBottom: 5 }}
            >
              <div className={classes.toolbarMain}>
                <Box display='flex' alignItems='center'>
                  <Breadcrumbs aria-label='breadcrumb'>
                    <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                      Transfer Money
                    </Typography>
                  </Breadcrumbs>
                </Box>
                <div className={classes.shopListMain}>
                  <div className={classes.shopList}>
                    <DropDown
                      ItemsArray={shopList}
                      handleChange={handleChangeShop}
                      selectedItem={selectedShop}
                      paddingTop={'10px'}
                      paddingBottom={'10px'}
                      paddingLeft={'10px'}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Widget>

          <Grid item xs={12}>
            <Widget noBoxShadow noBodyPadding bodyClass={classes.tableWidget}>
              <center>
                <Fade
                  in={error}
                  style={
                    !error ? { display: 'none' } : { display: 'inline-block' }
                  }
                >
                  <Typography
                    color='secondary'
                    className={classes.errorMessage}
                  >
                    {error ? error : ''}
                  </Typography>
                </Fade>
              </center>
              <div style={{ padding: 8 }}>
                {selectedShop !== 1 ? (
                  <>
                    {props.bankListLoading ? (
                      <p className={classes.emptyTable}>
                        <CircularProgress size={26} />
                      </p>
                    ) : (
                      <TransferMoneyContainer
                        description={description}
                        setDescription={setDescription}
                        selectedOption={selectedOption}
                        handleOptionChange={handleOptionChange}
                        setError={setError}
                        bankListData={bankListData}
                        setSelectedBank={setSelectedBank}
                        selectedBank={selectedBank}
                        amount={amount}
                        setAmount={setAmount}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        transferMoneyLoading={props.transferMoneyLoading}
                        handleTransferMoney={handleTransferMoney}
                      />
                    )}
                  </>
                ) : (
                  <p className={classes.emptyTable}>{selectShop}</p>
                )}
              </div>
            </Widget>
          </Grid>
        </>
      )}
    </>
  );
};

const component = withRouter(TransferMoney);

const mapStateToProps = (state) => {
  return {
    bankListData: state.bankListData.data,
    bankListLoading: state.bankListData.loading,
    bankListError: state.bankListData.error,

    transferMoneyData: state.transferMoneyData.data,
    transferMoneyLoading: state.transferMoneyData.loading,
    transferMoneyError: state.transferMoneyData.error,

    shopListData: state.shopListData.data,
    shopListLoading: state.shopListData.loading,
    shopListError: state.shopListData.error,
    employeeData: state.employeeData,
  };
};
export default connect(mapStateToProps, {
  fetchShopList,
  fetchBankList,
  inviteTransfer,
})(component);
