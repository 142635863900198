import axios from 'axios';

import { ASSIGN_BANK } from '../../constants';

export const assignBank = (sID, shopData, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadAssignBank());
  axios
    .post(`admin/assignedBankToShop?subShopId=${sID}`, shopData, config)
    .then((res) => {
      dispatch(setAssignBank(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorAssignBank(err.response)));
};

export const loadAssignBank = () => {
  return {
    type: ASSIGN_BANK.LOAD,
  };
};

export const setAssignBank = (data) => {
  return {
    type: ASSIGN_BANK.SUCCESS,
    payload: data.data,
  };
};

export const errorAssignBank = (data) => {
  return {
    type: ASSIGN_BANK.FAIL,
    payload: data,
  };
};
