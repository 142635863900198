import axios from 'axios';
import setAuthToken from '../../Utils/setAuthToken';
import jwt from 'jsonwebtoken';
import { EMPLOYEE_LOGIN } from '../../constants';

export const loginEmployee = (employeeData) => (dispatch) => {
  dispatch(loadEmployeeLogin());
  axios
    .post('login', employeeData)
    .then((res) => {
      let { token } = res.data.data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      const decoded = jwt.decode(token);
      if (res.data.data.user.type === 'user') {
        let { user } = res.data.data;
        localStorage.setItem('userProfile', JSON.stringify(user));
        dispatch(setEmployeeLogin(decoded));
      } else if (res.data.data.user.type === 'admin') {
        let { user } = res.data.data;
        localStorage.setItem('userProfile', JSON.stringify(user));
        dispatch(setEmployeeLogin(decoded));
      }
    })
    .catch((err) => {
      dispatch(errorEmployeeLogin(err.response));
    });
};

export const loadEmployeeLogin = () => {
  return {
    type: EMPLOYEE_LOGIN.LOAD,
  };
};

export const setEmployeeLogin = (data) => {
  return {
    type: EMPLOYEE_LOGIN.SUCCESS,
    payload: data,
  };
};

export const errorEmployeeLogin = (error) => {
  return {
    type: EMPLOYEE_LOGIN.FAIL,
    payload: error,
  };
};

export const logoutEmployee = (history) => (dispatch) => {
  dispatch(errorEmployeeLogin({}));
  localStorage.clear();
  setAuthToken(false);
  dispatch(setEmployeeLogin({}));
  history.push('/');
};