import React, { useState } from 'react';
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField as Input,
  InputAdornment,
  Box,
} from '@material-ui/core';
import { MoreVert as MoreIcon, Search as SearchIcon } from '@material-ui/icons';
import classnames from 'classnames';

//components
import { Typography } from '../../components/Wrappers';

// styles
import useStyles from './styles';

export default function Widget({
  children,
  title,
  subtitle,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  menuOptions,
  onMenuItemClick,
  itemId,
  header,
  inheritHeight,
  searchField,
  className,
  style,
  color,
  noBoxShadow,
  ...props
}) {
  var classes = useStyles(props);
  // local
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  var localMenuOptions = menuOptions ? menuOptions : ['View', 'Edit', 'Delete'];

  return (
    <div
      className={classnames(
        {
          [classes.inheritHeight]: inheritHeight,
          [classes.widgetWrapper]: !inheritHeight,
        },
        className
      )}
      style={style}
    >
      <Paper
        className={classnames(classes.paper, {
          [props.className]: props.className,
        })}
        classes={{ root: classes.widgetRoot }}
        style={{ boxShadow: noBoxShadow ? 'none' : 'auto' }}
      >
        {!title ? (
          <>
            {header ? (
              <div className={classes.widgetHeader}>{header}</div>
            ) : null}
          </>
        ) : (
          <div className={classes.widgetHeader}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box display={'flex'} style={{ width: 'calc(100% - 20px)' }}>
                <Typography
                  variant='h6'
                  color='text'
                  colorBrightness={'secondary'}
                  style={{ color: color ? color : 'rgb(110, 110, 110)' }}
                  noWrap
                >
                  {title}
                </Typography>
                <Box alignSelf='flex-end' ml={1}>
                  <Typography
                    color='text'
                    colorBrightness={'hint'}
                    variant={'caption'}
                  >
                    {subtitle}
                  </Typography>
                </Box>
              </Box>
              {searchField && (
                <Input
                  id='search-field'
                  className={classes.textField}
                  label='Search'
                  margin='dense'
                  variant='outlined'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {!disableWidgetMenu && (
                <IconButton
                  color='primary'
                  className={classes.moreButton}
                  aria-owns='widget-menu'
                  aria-haspopup='true'
                  onClick={() => setMoreMenuOpen(true)}
                  buttonRef={setMoreButtonRef}
                >
                  <MoreIcon />
                </IconButton>
              )}
            </Box>
          </div>
        )}
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [classes.paddingTop]: !title && !noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id='widget-menu'
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        {localMenuOptions &&
          !disableWidgetMenu &&
          localMenuOptions.map((option) => {
            return (
              <MenuItem
                key={option}
                onClick={() => {
                  onMenuItemClick && onMenuItemClick(option, itemId);
                  setMoreMenuOpen(false);
                }}
              >
                <Typography>{option}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
