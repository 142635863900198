const EMPLOYEE_LOGIN = {
  LOAD: 'LOAD_EMPLOYEE_LOGIN',
  SUCCESS: 'SUCCESS_EMPLOYEE_LOGIN',
  FAIL: 'FAIL_EMPLOYEE_LOGIN',
};
const SHOP_REGISTER = {
  LOAD: 'LOAD_SHOP_REGISTER',
  SUCCESS: 'SUCCESS_SHOP_REGISTER',
  FAIL: 'FAIL_SHOP_REGISTER',
};
const ADD_USER = {
  LOAD: 'LOAD_ADD_USER_DATA',
  SUCCESS: 'SUCCESS_ADD_USER_DATA',
  FAIL: 'FAIL_ADD_USER_DATA',
};
const ADD_SHOP = {
  LOAD: 'LOAD_ADD_SHOP_DATA',
  SUCCESS: 'SUCCESS_ADD_SHOP_DATA',
  FAIL: 'FAIL_ADD_SHOP_DATA',
};
const STORE_SLEETED_SHOP = {
  SUCCESS: 'SUCCESS_STORE_SLEETED_SHOP',
};
const ADD_BUYER = {
  LOAD: 'LOAD_ADD_BUYER_DATA',
  SUCCESS: 'SUCCESS_ADD_BUYER_DATA',
  FAIL: 'FAIL_ADD_BUYER_DATA',
};
const ADD_CATEGORY = {
  LOAD: 'LOAD_ADD_CATEGORY_DATA',
  SUCCESS: 'SUCCESS_ADD_CATEGORY_DATA',
  FAIL: 'FAIL_ADD_CATEGORY_DATA',
};
const ADD_SELL = {
  LOAD: 'LOAD_ADD_SELL_DATA',
  SUCCESS: 'SUCCESS_ADD_SELL_DATA',
  FAIL: 'FAIL_ADD_SELL_DATA',
};
const ADD_BUY = {
  LOAD: 'LOAD_ADD_BUY_DATA',
  SUCCESS: 'SUCCESS_ADD_BUY_DATA',
  FAIL: 'FAIL_ADD_BUY_DATA',
};
const ADD_TRANSACTION = {
  LOAD: 'LOAD_ADD_TRANSACTION_DATA',
  SUCCESS: 'SUCCESS_ADD_TRANSACTION_DATA',
  FAIL: 'FAIL_ADD_TRANSACTION_DATA',
};
const TRANSFER_MONEY = {
  LOAD: 'LOAD_TRANSFER_MONEY_DATA',
  SUCCESS: 'SUCCESS_TRANSFER_MONEY_DATA',
  FAIL: 'FAIL_TRANSFER_MONEY_DATA',
};
const ADD_EXPENSE = {
  LOAD: 'LOAD_ADD_EXPENSE_DATA',
  SUCCESS: 'SUCCESS_ADD_EXPENSE_DATA',
  FAIL: 'FAIL_ADD_EXPENSE_DATA',
};
const ADD_BANK = {
  LOAD: 'LOAD_ADD_BANK_DATA',
  SUCCESS: 'SUCCESS_ADD_BANK_DATA',
  FAIL: 'FAIL_ADD_BANK_DATA',
};
const USER_LIST = {
  LOAD: 'LOAD_USER_DATA',
  SUCCESS: 'SUCCESS_USER_DATA',
  FAIL: 'FAIL_USER_DATA',
};
const SHOP_LIST = {
  LOAD: 'LOAD_SHOP_DATA',
  SUCCESS: 'SUCCESS_SHOP_DATA',
  FAIL: 'FAIL_SHOP_DATA',
};
const BUYER_LIST = {
  LOAD: 'LOAD_BUYER_DATA',
  SUCCESS: 'SUCCESS_BUYER_DATA',
  FAIL: 'FAIL_BUYER_DATA',
};
const CATEGORY_LIST = {
  LOAD: 'LOAD_CATEGORY_DATA',
  SUCCESS: 'SUCCESS_CATEGORY_DATA',
  FAIL: 'FAIL_CATEGORY_DATA',
};
const DASHBOARD_DATA = {
  LOAD: 'LOAD_DASHBOARD_DATA',
  SUCCESS: 'SUCCESS_DASHBOARD_DATA',
  FAIL: 'FAIL_DASHBOARD_DATA',
};
const TRANSACTION_DATA = {
  LOAD: 'LOAD_TRANSACTION_DATA',
  SUCCESS: 'SUCCESS_TRANSACTION_DATA',
  FAIL: 'FAIL_TRANSACTION_DATA',
};
const BANK_LIST = {
  LOAD: 'LOAD_BANK_DATA',
  SUCCESS: 'SUCCESS_BANK_DATA',
  FAIL: 'FAIL_BANK_DATA',
};
const BLOCK_USER = {
  LOAD: 'LOAD_BLOCK_USER',
  SUCCESS: 'SUCCESS_BLOCK_USER',
  FAIL: 'FAIL_BLOCK_USER',
};
const EDIT_USER = {
  LOAD: 'LOAD_EDIT_USER',
  SUCCESS: 'SUCCESS_EDIT_USER',
  FAIL: 'FAIL_EDIT_USER',
};
const EDIT_SHOP = {
  LOAD: 'LOAD_EDIT_SHOP',
  SUCCESS: 'SUCCESS_EDIT_SHOP',
  FAIL: 'FAIL_EDIT_SHOP',
};
const EDIT_BUYER = {
  LOAD: 'LOAD_EDIT_BUYER',
  SUCCESS: 'SUCCESS_EDIT_BUYER',
  FAIL: 'FAIL_EDIT_BUYER',
};
const EDIT_CATEGORY = {
  LOAD: 'LOAD_EDIT_CATEGORY',
  SUCCESS: 'SUCCESS_EDIT_CATEGORY',
  FAIL: 'FAIL_EDIT_CATEGORY',
};
const ASSIGN_SHOP = {
  LOAD: 'LOAD_ASSIGN_SHOP',
  SUCCESS: 'SUCCESS_ASSIGN_SHOP',
  FAIL: 'FAIL_ASSIGN_SHOP',
};
const ASSIGN_BANK = {
  LOAD: 'LOAD_ASSIGN_BANK',
  SUCCESS: 'SUCCESS_ASSIGN_BANK',
  FAIL: 'FAIL_ASSIGN_BANK',
};
const EDIT_BANK = {
  LOAD: 'LOAD_EDIT_BANK',
  SUCCESS: 'SUCCESS_EDIT_BANK',
  FAIL: 'FAIL_EDIT_BANK',
};
const DELETE_USER = {
  LOAD: 'LOAD_DELETE_USER',
  SUCCESS: 'SUCCESS_DELETE_USER',
  FAIL: 'FAIL_DELETE_USER',
};
const DELETE_SHOP = {
  LOAD: 'LOAD_DELETE_SHOP',
  SUCCESS: 'SUCCESS_DELETE_SHOP',
  FAIL: 'FAIL_DELETE_SHOP',
};
const DELETE_BUYER = {
  LOAD: 'LOAD_DELETE_BUYER',
  SUCCESS: 'SUCCESS_DELETE_BUYER',
  FAIL: 'FAIL_DELETE_BUYER',
};
const DELETE_CATEGORY = {
  LOAD: 'LOAD_DELETE_CATEGORY',
  SUCCESS: 'SUCCESS_DELETE_CATEGORY',
  FAIL: 'FAIL_DELETE_CATEGORY',
};
const DELETE_BANK = {
  LOAD: 'LOAD_DELETE_BANK',
  SUCCESS: 'SUCCESS_DELETE_BANK',
  FAIL: 'FAIL_DELETE_BANK',
};

const DELETE_TRANSACTION = {
  LOAD: 'LOAD_DELETE_TRANSACTION',
  SUCCESS: 'SUCCESS_DELETE_TRANSACTION',
  FAIL: 'FAIL_DELETE_TRANSACTION',
};

export {
  EMPLOYEE_LOGIN,
  SHOP_REGISTER,
  ADD_USER,
  ADD_SHOP,
  ADD_BANK,
  ADD_TRANSACTION,
  USER_LIST,
  BLOCK_USER,
  EDIT_USER,
  EDIT_SHOP,
  DELETE_USER,
  DELETE_SHOP,
  SHOP_LIST,
  BANK_LIST,
  DELETE_BANK,
  EDIT_BANK,
  ASSIGN_SHOP,
  CATEGORY_LIST,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  BUYER_LIST,
  ADD_BUYER,
  DELETE_BUYER,
  EDIT_BUYER,
  DASHBOARD_DATA,
  ADD_SELL,
  ADD_BUY,
  ADD_EXPENSE,
  TRANSACTION_DATA,
  ASSIGN_BANK,
  TRANSFER_MONEY,
  STORE_SLEETED_SHOP,
  DELETE_TRANSACTION,
};
