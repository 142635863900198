import React, { useEffect, useState } from 'react';
import { Grid, Box, Breadcrumbs } from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import { Typography } from '../../components/Wrappers/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchCategoryList } from '../../actions/adminActions/categoryList';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import AddSell1 from './AddSell1';
import AddSell2 from './AddSell2';
import { fetchBankList } from '../../actions/adminActions/bankList';
import { inviteSell } from '../../actions/adminActions/addSell';

const AddSell = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [categoryListData, setCategoryListData] = useState([]);
  const [sellPage, setSellPage] = React.useState(0);
  const [sells, setSells] = useState([]);
  const [creditInBank, setCreditInBank] = useState([]);
  const [bankListData, setBankListData] = useState([]);
  const [creditInCash, setCreditInCash] = useState(null);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [bankTotalAmount, setBankTotalAmount] = React.useState(0);
  const [bankCaseTotalAmount, setBankCaseTotalAmount] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState();
  const theme = useTheme();

  const employeeData = props.employeeData.employee;

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.categoryListError));
  }, [props.categoryListError]);

  useEffect(() => {
    const { subShopId } = props.location.state;
    setError('');
    props.fetchCategoryList(employeeData.type, subShopId);
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setSelectedDate(now.toISOString().slice(0, 16));
  }, []);

  useEffect(() => {
    let data = props.categoryListData.data ? props.categoryListData.data : [];
    const newCategoryList = data.map((e) => ({
      name: e.name,
      id: e._id,
    }));
    setCategoryListData(newCategoryList);
  }, [props.categoryListData]);

  useEffect(() => {
    let data = props.bankListData.data ? props.bankListData.data : [];
    const newBankList = data.map((e) => ({
      // money: e.money,
      name: e.name,
      id: e._id,
    }));
    setBankListData(newBankList);
  }, [props.bankListData]);

  const handleAddCategory = () => {
    if (sells.length === 0) {
      setSells([...sells, { categoryId: '1', amount: '' }]);
      setError('');
    } else {
      let newSells = [...sells];
      newSells.forEach((sell, index) => {
        let tempSells = { ...newSells[index] };
        let sum = sell.amount
          ? sell.amount
              .toString()
              .split('')
              .filter((item) => item !== '.')
              .map(Number)
              .reduce(function(a, b) {
                return a + b;
              }, 0)
          : 0;
        tempSells.amount = sum;
        newSells[index] = tempSells;
      });
      const filteredSells = newSells.filter(
        (sell, index) => sell.categoryId === '1' || !sell.amount
      );
      if (filteredSells.length === 0)
        setSells([...sells, { categoryId: '1', amount: '' }]);
      else setError('Please enter category or amount');
    }
  };

  const handleAddBank = () => {
    if (creditInBank.length === 0) {
      setCreditInBank([...creditInBank, { bankId: '1', amount: '' }]);
      setError('');
    } else {
      let newBanks = [...creditInBank];
      newBanks.forEach((bank, index) => {
        let tempBanks = { ...newBanks[index] };
        let sum = bank.amount
          ? bank.amount
              .toString()
              .split('')
              .filter((item) => item !== '.')
              .map(Number)
              .reduce(function(a, b) {
                return a + b;
              }, 0)
          : 0;
        tempBanks.amount = sum;
        newBanks[index] = tempBanks;
      });
      const filteredBanks = newBanks.filter(
        (bank, index) => bank.bankId === '1' || !bank.amount
      );
      if (filteredBanks.length === 0) {
        setCreditInBank([...creditInBank, { bankId: '1', amount: '' }]);
        setError('');
      } else setError('Please enter bank or amount');
    }
  };

  const handleAmountInput = (e, index) => {
    let newSells = [...sells];
    let tempSells = { ...newSells[index] };
    tempSells.amount = e.target.value;
    newSells[index] = tempSells;
    setSells(newSells);
  };

  const handleBankAmountInput = (e, index) => {
    let newBanks = [...creditInBank];
    let tempBanks = { ...newBanks[index] };
    tempBanks.amount = e.target.value;
    newBanks[index] = tempBanks;
    setCreditInBank(newBanks);
  };

  useEffect(() => {
    const amount =
      sells.length > 0
        ? sells.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;
    setTotalAmount(amount);
  }, [sells]);

  useEffect(() => {
    const amount =
      creditInBank.length > 0
        ? creditInBank.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;
    setBankTotalAmount(amount);
  }, [creditInBank]);

  useEffect(() => {
    const amount =
      creditInBank.length > 0
        ? creditInBank.reduce(function(total, num) {
            return total + parseFloat(num.amount ? num.amount : 0);
          }, 0)
        : 0;

    setBankCaseTotalAmount(
      amount + parseFloat(creditInCash ? creditInCash : 0)
    );
  }, [creditInBank, creditInCash]);

  const handleChangeCategory = (e, index) => {
    let newSells = [...sells];
    let tempSells = { ...newSells[index] };
    if (tempSells.categoryId !== e.target.value) {
      tempSells.categoryId = e.target.value;
      newSells[index] = tempSells;
      setSells(newSells);
      setError('');
    }
  };

  const handleChangeBank = (e, index) => {
    let newBanks = [...creditInBank];
    let tempBanks = { ...newBanks[index] };
    if (tempBanks.bankId !== e.target.value) {
      tempBanks.bankId = e.target.value;
      const money = bankListData.filter((bank) => bank.id === e.target.value);
      tempBanks.money = money[0].money;
      newBanks[index] = tempBanks;
      setCreditInBank(newBanks);
      setError('');
    }
  };

  const handleOnDeleteCategory = (sellIndex) => {
    const filteredSells = sells.filter((sell, index) => index !== sellIndex);
    setSells(filteredSells);
    setError('');
  };

  const handleOnDeleteBank = (bankIndex) => {
    const filteredBanks = creditInBank.filter(
      (bank, index) => index !== bankIndex
    );
    setCreditInBank(filteredBanks);
    setError('');
  };

  const handleNext = () => {
    if (sells.length === 0) setError('Please add category');
    else {
      let newSells = [...sells];
      newSells.forEach((sell, index) => {
        let tempSells = { ...newSells[index] };
        let sum = sell.amount
          ? sell.amount
              .toString()
              .split('')
              .filter((item) => item !== '.')
              .map(Number)
              .reduce(function(a, b) {
                return a + b;
              }, 0)
          : 0;
        tempSells.amount = sum;
        newSells[index] = tempSells;
      });
      const filteredSells = newSells.filter(
        (sell, index) => sell.categoryId === '1' || !sell.amount
      );
      if (filteredSells.length === 0) {
        setSellPage(1);
        const { subShopId } = props.location.state;
        props.fetchBankList(subShopId);
      } else setError('Please enter category or amount');
    }
  };

  useEffect(() => {
    setError(getErrorMessage(props.bankListError));
  }, [props.bankListError]);

  useEffect(() => {
    setError(getErrorMessage(props.addSellError));
  }, [props.addSellError]);

  const handleSubmit = () => {
    let newBanks = [...creditInBank];
    newBanks.forEach((bank, index) => {
      let tempBanks = { ...newBanks[index] };
      let sum = bank.amount
        ? bank.amount
            .toString()
            .split('')
            .filter((item) => item !== '.')
            .map(Number)
            .reduce(function(a, b) {
              return a + b;
            }, 0)
        : 0;
      tempBanks.amount = sum;
      newBanks[index] = tempBanks;
    });
    const filteredBanks = newBanks.filter(
      (bank, index) => bank.bankId === '1' || !bank.amount
    );
    if (filteredBanks.length === 0) {
      if (totalAmount === bankCaseTotalAmount) {
        const { subShopId } = props.location.state;
        const date = new Date(selectedDate);
        const data = {
          subShopId,
          creditInCash: creditInCash ? creditInCash : 0,
          creditInBank,
          sells,
          date: date.toISOString(),
        };
        props.inviteSell(data, history);
      } else {
        setError('Totals are not matched');
      }
    } else setError('Please enter bank or amount');
  };

  const handleCategoryListData = (categoryIndex) => {
    if (categoryIndex === 0) return categoryListData;
    else {
      const tempCategoryList = sells.filter(
        (sell, index) => index < categoryIndex && sell.categoryId
      );
      let newCategoryList = categoryListData.filter((category) => {
        return (
          tempCategoryList.filter((cate) => {
            return cate.categoryId == category.id;
          }).length == 0
        );
      });
      return newCategoryList;
    }
  };

  const handleBankListData = (bankIndex) => {
    if (bankIndex === 0) return bankListData;
    else {
      const tempBankList = creditInBank.filter(
        (bank, index) => index < bankIndex && bank.bankId
      );
      let newBankList = bankListData.filter((bank) => {
        return (
          tempBankList.filter((ban) => {
            return ban.bankId == bank.id;
          }).length == 0
        );
      });
      return newBankList;
    }
  };

  return (
    <>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
        >
          <Box display='flex' alignItems='center'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                Add Sell
              </Typography>
            </Breadcrumbs>
          </Box>
          {sellPage === 1 && (
            <Box display='flex' alignItems='center'>
              <Breadcrumbs aria-label='breadcrumb'>
                <Typography variant='h6'>Total : {totalAmount}</Typography>
              </Breadcrumbs>
            </Box>
          )}
        </Grid>
      </Widget>
      {props.categoryListLoading || props.bankListLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {sellPage === 0 ? (
            <AddSell1
              classes={classes}
              error={error}
              sells={sells}
              categoryListData={categoryListData}
              handleChangeCategory={handleChangeCategory}
              handleAmountInput={handleAmountInput}
              setError={setError}
              handleOnDeleteCategory={handleOnDeleteCategory}
              handleAddCategory={handleAddCategory}
              totalAmount={totalAmount}
              handleNext={handleNext}
              handleCategoryListData={handleCategoryListData}
            />
          ) : (
            <AddSell2
              classes={classes}
              error={error}
              creditInBank={creditInBank}
              handleChangeBank={handleChangeBank}
              handleBankAmountInput={handleBankAmountInput}
              setError={setError}
              handleOnDeleteBank={handleOnDeleteBank}
              handleAddBank={handleAddBank}
              bankTotalAmount={bankTotalAmount}
              bankCaseTotalAmount={bankCaseTotalAmount}
              creditInBank={creditInBank}
              bankListData={bankListData}
              setCreditInCash={setCreditInCash}
              creditInCash={creditInCash}
              history={history}
              handleSubmit={handleSubmit}
              addSellLoading={props.addSellLoading}
              handleBankListData={handleBankListData}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          )}
        </>
      )}
    </>
  );
};

const component = withRouter(AddSell);

const mapStateToProps = (state) => {
  return {
    categoryListData: state.categoryListData.data,
    categoryListLoading: state.categoryListData.loading,
    categoryListError: state.categoryListData.error,

    bankListData: state.bankListData.data,
    bankListLoading: state.bankListData.loading,
    bankListError: state.bankListData.error,

    addSellData: state.addSellData.data,
    addSellLoading: state.addSellData.loading,
    addSellError: state.addSellData.error,

    employeeData: state.employeeData,
  };
};
export default connect(mapStateToProps, {
  fetchCategoryList,
  fetchBankList,
  inviteSell,
})(component);
