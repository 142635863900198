import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component, employeeData, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      employeeData.isAuthenticated ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={'/login'} />
      )
    }
  />
);

const mapStateToProps = (state) => {
  return {
    employeeData: state.employeeData,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
