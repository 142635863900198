import { combineReducers } from 'redux';

import employeeLogin from './employeeReducers/employeeLogin';

import shopSignup from './companyReducers/shopSignup';
import addUser from './adminReducers/addUser';
import addBank from './adminReducers/addBank';
import addShop from './adminReducers/addShop';
import addBuyer from './adminReducers/addBuyer';
import addCategory from './adminReducers/addCategory';
import addSell from './adminReducers/addSell';
import addBuy from './adminReducers/addBuy';
import addTransaction from './adminReducers/addTransaction';
import transferMoney from './adminReducers/transferMoney';
import addExpense from './adminReducers/addExpense';
import listUser from './adminReducers/userList';
import listShop from './adminReducers/shopList';
import listBuyer from './adminReducers/buyerList';
import listCategory from './adminReducers/categoryList';
import dataDashboard from './adminReducers/dashboardData';
import dataTransaction from './adminReducers/transactionData';
import listBank from './adminReducers/bankList';

import editUser from './adminReducers/editUser';
import editShop from './adminReducers/editShop';
import editBuyer from './adminReducers/editBuyer';
import editCategory from './adminReducers/editCategory';
import assignShop from './adminReducers/assignShop';
import assignBank from './adminReducers/assignBank';
import editbank from './adminReducers/editBank';
import blockUser from './adminReducers/blockUser';
import deleteUser from './adminReducers/deleteUser';
import deleteShop from './adminReducers/deleteShop';
import deleteBuyer from './adminReducers/deleteBuyer';
import deleteCategory from './adminReducers/deleteCategory';
import deleteBank from './adminReducers/deleteBank';
import deleteTransaction from './adminReducers/deleteTransaction';

export default combineReducers({
  employeeData: employeeLogin,
  shopSignupData: shopSignup,
  addUserData: addUser,
  addBankData: addBank,
  addShopData: addShop,
  addBuyerData: addBuyer,
  addCategoryData: addCategory,
  addSellData: addSell,
  addBuyData: addBuy,
  transferMoneyData: transferMoney,
  addExpenseData: addExpense,
  userListData: listUser,
  bankListData: listBank,
  shopListData: listShop,
  buyerListData: listBuyer,
  categoryListData: listCategory,
  dashboardData: dataDashboard,
  transactionData: dataTransaction,
  addTransactionData: addTransaction,
  editUserData: editUser,
  editShopData: editShop,
  editBuyerData: editBuyer,
  editCategoryData: editCategory,
  editBankData: editbank,
  assignShopData: assignShop,
  assignBankData: assignBank,
  blockUserData: blockUser,
  deleteUserData: deleteUser,
  deleteShopData: deleteShop,
  deleteBuyerData: deleteBuyer,
  deleteCategoryData: deleteCategory,
  deleteBankData: deleteBank,
  deleteTransactionData: deleteTransaction,
});
