import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  Fade,
  TextField as Input,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Email from '@material-ui/icons/Email';
import VpnKey from '@material-ui/icons/VpnKey';

// styles
import useStyles from './styles';
import { connect } from 'react-redux';
import '../../Utils/index.css';
import { emailCheck } from '../../Utils/Validation.js';
// logo
import logo from './logo.jpeg';

//components
import { Button, Typography } from '../../components/Wrappers';
import { loginEmployee } from '../../actions/employeeActions/employeeLogin';
import { registerShop } from '../../actions/companyActions/shopSignup';
import Signup from '../signup';

const getGreeting = () => {
  const d = new Date();
  if (d.getHours() >= 4 && d.getHours() <= 12) {
    return 'Good Morning';
  } else if (d.getHours() >= 13 && d.getHours() <= 16) {
    return 'Good Day';
  } else if (d.getHours() >= 17 && d.getHours() <= 23) {
    return 'Good Evening';
  } else {
    return 'Good Night';
  }
};
function Login(props) {
  var classes = useStyles();

  // local
  var [error, setError] = useState('');
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');
  const [isForgot, setForgotValue] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);

  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.companyRegisterError));
  }, [props.companyRegisterError]);

  useEffect(() => {
    setError(getErrorMessage(props.employeeDataError));
  }, [props.employeeDataError]);

  const tryLogin = () => {
    if (!emailCheck(loginValue)) {
      setError('Enter a valid Email Address');
    } else {
      props.loginEmployee({
        email: loginValue,
        password: passwordValue,
      });
    }
  };

  const registerInput = (inputId, value, onchange, placeholder, type, code) => {
    return (
      <center>
        <Grid item xs={12}>
          <Input
            id={inputId}
            variant='outlined'
            InputProps={{
              startAdornment: code,
            }}
            onFocus={() => setError('')}
            value={value}
            onChange={onchange}
            margin='normal'
            placeholder={placeholder}
            type={type}
            fullWidth
          />
        </Grid>
      </center>
    );
  };
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <Grid
          className={classes.gridMain}
          style={{ width: '100%', height: '100%' }}
        >
          <img src={logo} alt='logo' className={classes.logotypeImage} />
        </Grid>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <div className='auth-wrapper'>
            <div className='auth-inner'>
              {signUpVisible ? (
                <Signup redirectLogin={() => setSignUpVisible(false)} />
              ) : (
                <React.Fragment>
                  {/* <Grid className={classes.starting}>
                    <Typography
                      variant='h7'
                      style={{
                        fontWeight: 800,
                        paddingTop: '8px',
                        paddingRight: '8px',
                      }}
                    >
                      Don't have a company account?
                    </Typography>
                    <Link
                      style={{
                        fontWeight: 800,
                        paddingTop: '8px',
                        paddingRight: '8px',
                        cursor: 'pointer',
                      }}
                      color='primary'
                      onClick={() => setSignUpVisible(true)}
                    >
                      Create Company
                    </Link>
                  </Grid> */}
                  <center>
                    <Typography
                      variant='h2'
                      style={{ paddingBottom: '20px' }}
                      className={classes.greeting}
                    >
                      {getGreeting()}, User
                    </Typography>
                    <Fade
                      in={error}
                      style={
                        !error
                          ? { display: 'none' }
                          : { display: 'inline-block' }
                      }
                    >
                      <Typography
                        color='secondary'
                        className={classes.errorMessage}
                      >
                        {error ? error : ''}
                      </Typography>
                    </Fade>
                  </center>
                  {registerInput(
                    'email',
                    loginValue,
                    (e) => setLoginValue(e.target.value),
                    'Email Address',
                    'email',
                    <InputAdornment position='start'>
                      <Email />
                    </InputAdornment>
                  )}
                  {registerInput(
                    'password',
                    passwordValue,
                    (e) => setPasswordValue(e.target.value),
                    'Password',
                    'password',
                    <InputAdornment position='start'>
                      <VpnKey />
                    </InputAdornment>
                  )}
                  <div className={classes.formButtons}>
                    {/* <Button
                      color="primary"
                      size="large"
                      className={classes.forgetButton}
                      onClick={() => setForgotValue(true)}>
                      Forgot Password?
                    </Button> */}
                    {props.employeeDataLoading ? (
                      <CircularProgress size={26} />
                    ) : (
                      <Button
                        disabled={
                          loginValue.length === 0 || passwordValue.length === 0
                        }
                        onClick={() => tryLogin()}
                        variant='contained'
                        color='primary'
                        size='large'
                      >
                        Login
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

const component = withRouter(Login);
const mapStateToProps = (state) => {
  return {
    employeeData: state.employeeData,
    employeeDataLoading: state.employeeData.loading,
    employeeDataError: state.employeeData.error,
  };
};

export default connect(mapStateToProps, {
  loginEmployee,
  registerShop,
})(component);
