import axios from 'axios';
import { ADD_EXPENSE } from '../../constants';

export const inviteExpense = (data, history) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(addExpense());
  axios
    .post(`user/addExpense`, data, config)
    .then((res) => {
      dispatch(setExpense(res));
      history.goBack();
    })
    .catch((err) => dispatch(errorExpense(err.response)));
};

export const addExpense = () => {
  return {
    type: ADD_EXPENSE.LOAD,
  };
};

export const setExpense = (data) => {
  return {
    type: ADD_EXPENSE.SUCCESS,
    payload: data.data,
  };
};

export const errorExpense = (error) => {
  return {
    type: ADD_EXPENSE.FAIL,
    payload: error,
  };
};
