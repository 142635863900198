import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  alignStandaloneElementEmployeeDashboard: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  legendItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignStandaloneElement: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  // tableWidget: {
  //   overflowX: "auto"
  // },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  loginLoaderContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  serverOverviewElement: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: '100%',
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + '80 !important',
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: '14px !important',
    marginLeft: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  fixIconRight: {
    '& .MuiSelect-icon': {
      right: 4,
    },
  },
  checkboxColor: {
    color: 'rgb(185, 185, 185)',
  },
  shopListMain: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  shopList: {
    width: 200,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  calendarButton: {
    backgroundColor: 'white',
    color: 'rgb(110, 110, 110)',
    paddingTop: 7,
    paddingBottom: 7,
    justifyContent: 'space-between',
    boxShadow:
      'rgb(232 234 252) 0px 3px 11px 0px, rgb(178 178 178 / 10%) 0px 3px 3px -2px, rgb(154 154 154 / 10%) 0px 1px 8px 0px',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow:
        'rgb(232 234 252) 0px 3px 11px 0px, rgb(178 178 178 / 10%) 0px 3px 3px -2px, rgb(154 154 154 / 10%) 0px 1px 8px 0px',
    },
  },
  btnsView: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 10,
  },
  addSellButton: {
    width: 120,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  addBuyButton: {
    width: 120,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20,
    },
  },
  sellButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  emptyTable: {
    width: '100%',
    textAlign: 'center',
    fontStyle: 'italic',
    color: 'grey',
  },
}));
