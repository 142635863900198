import axios from 'axios';
import { ADD_USER } from '../../constants';

export const inviteUser = (data, history) => dispatch => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token }
  };
  dispatch(addUser());
  axios
    .post(`admin/addUser`, data, config)
    .then(res => {
      dispatch(setUser(res));
      history.goBack();
    })
    .catch(err => dispatch(errorUser(err.response)));
};

export const addUser = () => {
  return {
    type: ADD_USER.LOAD
  };
};

export const setUser = data => {
  return {
    type: ADD_USER.SUCCESS,
    payload: data.data
  };
};

export const errorUser = error => {
  return {
    type: ADD_USER.FAIL,
    payload: error
  };
};
