import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Breadcrumbs,
  Table,
  Fade,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  IconButton,
  lighten,
  TableHead,
  TableSortLabel,
} from '@material-ui/core';
import Widget from '../../components/Widget';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import { Typography, Button } from '../../components/Wrappers';
import useStyles from '../../components/Layout/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { Tooltip } from 'recharts';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { fetchUserList } from '../../actions/adminActions/userList';
import { blockUser } from '../../actions/adminActions/blockUser';
import { deleteUser } from '../../actions/adminActions/deleteUser';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { userActiveList, userInActiveList } from '../../const';

const User = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [activeUserListData, setActiveUserListData] = useState([]);
  const [inactiveUserListData, setInactiveUserListData] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selectedA, setSelectedA] = React.useState([]);
  const [selectedI, setSelectedI] = React.useState([]);
  const [pageA, setPageA] = React.useState(0);
  const [pageI, setPageI] = React.useState(0);
  const [rowsPerPageA, setRowsPerPageA] = React.useState(5);
  const [rowsPerPageI, setRowsPerPageI] = React.useState(5);
  const theme = useTheme();

  const [error, setError] = React.useState('');
  const getErrorMessage = (error) => {
    return error && error.data && error.data.message;
  };

  useEffect(() => {
    setError(getErrorMessage(props.userListError));
  }, [props.userListError]);

  useEffect(() => {
    setError('');
  }, []);

  useEffect(() => {
    props.fetchUserList();
  }, [props.blockUserData, props.deleteUserData]);

  useEffect(() => {
    let data = props.userListData.data
      ? props.userListData.data.filter((e) => e.block === false)
      : [];
    setActiveUserListData(data);

    let data2 = props.userListData.data
      ? props.userListData.data.filter((e) => e.block === true)
      : [];
    setInactiveUserListData(data2);
  }, [props.userListData]);

  const headCells = [
    { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  const handleChangePageA = (event, newPage) => {
    setPageA(newPage);
  };

  const handleChangeRowsPerPageA = (event) => {
    setRowsPerPageA(parseInt(event.target.value, 10));
    setPageA(0);
  };

  const handleChangePageI = (event, newPage) => {
    setPageI(newPage);
  };

  const handleChangeRowsPerPageI = (event) => {
    setRowsPerPageI(parseInt(event.target.value, 10));
    setPageI(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClickA = (event) => {
    if (event.target.checked) {
      const newSelecteds = activeUserListData.map((n) => n.id);
      setSelectedA(newSelecteds);
      return;
    }
    setSelectedA([]);
  };

  const handleSelectAllClickI = (event) => {
    if (event.target.checked) {
      const newSelecteds = inactiveUserListData.map((n) => n.id);
      setSelectedI(newSelecteds);
      return;
    }
    setSelectedI([]);
  };
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc'
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function EnhancedTableHead(props) {
    const { classes, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'left' : 'right'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
                style={{
                  whiteSpace: 'nowrap',
                  textTransform: 'uppercase',
                  fontSize: '0.85rem',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}></span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const common = (row, index) => {
    let resignationDate = row.resignDate
      ? moment(row.resignDate).format('DD MMM YYYY')
      : 'N/A';
    let resign = row.resignDate
      ? moment(row.resignDate).format('YYYY-MM-DD')
      : undefined;
    return (
      <TableRow>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          <Grid style={{ display: 'flex' }}>
            <Button
              variant={'contained'}
              color={'success'}
              style={{ marginRight: 8 }}
              onClick={() =>
                history.push({
                  pathname: '/app/user/assign/shop',
                  state: {
                    id: row._id,
                    name: row.name,
                    email: row.email,
                    assignedShop: row.assignedShop,
                  },
                })
              }
            >
              Assign Shop
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              style={{ marginRight: 8 }}
              onClick={() =>
                history.push({
                  pathname: '/app/user/edit',
                  state: {
                    id: row._id,
                    name: row.name,
                    email: row.email,
                    block: row.block,
                  },
                })
              }
            >
              Edit
            </Button>
            {row.block === false ? (
              <Button
                variant={'contained'}
                color={'warning'}
                style={{ marginRight: 8 }}
                onClick={() => setBlock(row._id, true)}
              >
                Block
              </Button>
            ) : (
              <Button
                variant={'contained'}
                color={'warning'}
                style={{ marginRight: 8 }}
                onClick={() => setBlock(row._id, false)}
              >
                UnBlock
              </Button>
            )}
            <Button
              variant={'contained'}
              color={'secondary'}
              style={{ marginRight: 8 }}
              onClick={() => onDelete(row._id, index)}
            >
              Delete
            </Button>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const useToolbarStyles = makeStyles((theme) => ({
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));

  const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();

    return (
      <Toolbar>
        <Box display={'flex'} className={classes.title}>
          <Typography
            variant='h6'
            color='text'
            colorBrightness={'secondary'}
            id='tableTitle'
            style={{ display: 'flex' }}
            block
          >
            User List
          </Typography>
        </Box>
        <Tooltip title='Filter list'>
          <IconButton aria-label='filter list'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  };
  const setBlock = (id, block) => {
    const blockUser = { block: block };
    props.blockUser(id, blockUser);
  };
  const onDelete = (id, index) => {
    const r = window.confirm('Do you really want to Delete?');
    if (r === true) {
      if (props.deleteUser) {
        props.deleteUser(id);
      }
    }
  };
  return (
    <>
      <Widget
        disableWidgetMenu
        inheritHeight
        className={classes.margin}
        bodyClass={classes.navPadding}
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          wrap={'nowrap'}
          style={{ overflowX: 'auto' }}
        >
          <Box display='flex' alignItems='center'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Typography variant='h5' style={{ paddingLeft: '5px' }}>
                User management
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box display='flex' alignItems='center'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => history.push('/app/user/create')}
              className={classes.button}
            >
              Add User
            </Button>
          </Box>
        </Grid>
      </Widget>

      {/*employee list*/}
      {props.userListLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Widget noBodyPadding bodyClass={classes.tableWidget}>
            <EnhancedTableToolbar />
            <center>
              <Fade
                in={error}
                style={
                  !error ? { display: 'none' } : { display: 'inline-block' }
                }
              >
                <Typography color='secondary' className={classes.errorMessage}>
                  {error ? error : ''}
                </Typography>
              </Fade>
            </center>
            <AppBar position='static' color='default' disablePadding>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='primary'
                variant='fullWidth'
                aria-label='full width tabs example'
              >
                <Tab label='Active' {...a11yProps(0)} />
                <Tab label='Inactive' {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div className={classes.tableWrapper}>
                  <Table
                    className={classes.table}
                    aria-labelledby='tableTitle'
                    aria-label='leave status'
                  >
                    {props.userListLoading ? (
                      <div className={classes.loginLoaderContainer}>
                        <CircularProgress
                          size={26}
                          className={classes.loginLoader}
                        />
                      </div>
                    ) : activeUserListData.length > 0 ? (
                      <>
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClickA}
                          onRequestSort={handleRequestSort}
                          rowCount={activeUserListData.length}
                        />
                        <TableBody>
                          {stableSort(
                            activeUserListData,
                            getSorting(order, orderBy)
                          )
                            .slice(
                              pageA * rowsPerPageA,
                              pageA * rowsPerPageA + rowsPerPageA
                            )
                            .map((row, index) => common(row, index))}
                        </TableBody>
                      </>
                    ) : (
                      <p className={classes.emptyTable}>{userActiveList}</p>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={activeUserListData.length}
                    rowsPerPage={rowsPerPageA}
                    page={pageA}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePageA}
                    onChangeRowsPerPage={handleChangeRowsPerPageA}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <div className={classes.tableWrapper}>
                  <Table
                    className={classes.table}
                    aria-labelledby='tableTitle'
                    aria-label='leave status'
                  >
                    {props.userListLoading ? (
                      <div className={classes.loginLoaderContainer}>
                        <CircularProgress
                          size={26}
                          className={classes.loginLoader}
                        />
                      </div>
                    ) : inactiveUserListData.length > 0 ? (
                      <>
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClickI}
                          onRequestSort={handleRequestSort}
                          rowCount={inactiveUserListData.length}
                        />
                        <TableBody>
                          {stableSort(
                            inactiveUserListData,
                            getSorting(order, orderBy)
                          )
                            .slice(
                              pageI * rowsPerPageI,
                              pageI * rowsPerPageI + rowsPerPageI
                            )
                            .map((row, index) => common(row, index))}
                        </TableBody>
                      </>
                    ) : (
                      <p className={classes.emptyTable}>{userInActiveList}</p>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={inactiveUserListData.length}
                    rowsPerPage={rowsPerPageI}
                    page={pageI}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePageI}
                    onChangeRowsPerPage={handleChangeRowsPerPageI}
                  />
                </div>
              </TabPanel>
            </SwipeableViews>
          </Widget>
        </Grid>
      )}
    </>
  );
};

const component = withRouter(User);

const mapStateToProps = (state) => {
  return {
    userListData: state.userListData.data,
    userListLoading: state.userListData.loading,
    userListError: state.userListData.error,

    blockUserData: state.blockUserData.data,

    deleteUserData: state.deleteUserData.data,
    deleteUserDataLoading: state.deleteUserData.loading,
    deleteUserDataError: state.deleteUserData.error,
  };
};
export default connect(mapStateToProps, {
  fetchUserList,
  blockUser,
  deleteUser,
})(component);
