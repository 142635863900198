import axios from 'axios';
import { DELETE_BUYER } from '../../constants';

export const deleteBuyer = (bID) => (dispatch) => {
  const token = localStorage.jwtToken;
  let config = {
    headers: { Authorization: 'Bearer ' + token },
  };
  dispatch(loadDeleteBuyer());
  axios
    .delete(`admin/buyer/${bID}`, config)
    .then((res) => dispatch(setDeleteBuyer(res)))
    .catch((err) => dispatch(errorDeleteBuyer(err.response)));
};

export const loadDeleteBuyer = () => {
  return {
    type: DELETE_BUYER.LOAD,
  };
};

export const setDeleteBuyer = (data) => {
  return {
    type: DELETE_BUYER.SUCCESS,
    payload: data,
  };
};

export const errorDeleteBuyer = (error) => {
  return {
    type: DELETE_BUYER.FAIL,
    payload: error,
  };
};
